import './config/ReactotronConfig';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes';

import GlobalStyle from './styles/global';
import { ToastContainer } from 'react-toastify';

import { Theme } from './theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { store, persistor } from './store';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <MuiThemeProvider theme={Theme}>
            <Routes />
            <GlobalStyle />
            <ToastContainer autoClose={3000} />
          </MuiThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
