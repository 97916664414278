import React from 'react'
import { ContainerContent } from './styles'

export default function Container ({ children }) {
  return (
    <ContainerContent>
      {children}
    </ContainerContent>
  )
}
