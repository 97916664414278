import styled from 'styled-components';
// import Colors from '../../theme/Colors';

export const Container = styled.div``;

export const Card = styled.div`
  padding: 20px;
  display: flex;
  border-radius: 7px;
  flex-direction: column;
  min-width: 350px;
  max-width: 750px;
  background: #fff;
  box-shadow: 0px 1px 10px #CCCCCC;
  width: 100%;

  h5 {font-weight: normal;}

  p {font-size: 12px;}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Body = styled.div`
  margin: 10px 0px 0px 0px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ContainerAttr = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 0px 10px 0px;
`;

export const CententAttr = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

export const ContainerAddress = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 0px 10px 0px;
`;

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 0px 0px;
  flex-wrap: wrap;
  p{
    margin-left: 10px;
  }

  @media (max-width: 665px) {
    margin-top: 20px;
    p{
      margin-left: 0px;
      text-align: center;
      margin-top: 10px;
    }
    justify-content: center;
  }
`;