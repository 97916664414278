import axios from 'axios';

const api = axios.create({
  //  baseURL: 'http://206.81.6.55:3001/api/',
  baseURL: 'https://api.guiadoctor.com.br/api',
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  // baseURL: 'http://guiadoctor.com.br:3001/api/',
});

export const cancellToken = axios.CancelToken.source();

export default api;
