const Colors = {
    // Theme colors
    new_primary: '#1144D0',
    primary: '#264191',
    secondary: '#A3A3A3',
    
    // Shared colors
    success: '#08d576',
    warn: '#ffce00',
    error: '#ff0000',
  
    star: '#f1c40f',
  
    gray01: '#f4f4f4',
    gray02: '#dddee2',
    gray03: '#decdfd',
    gray04: '#727c8e',
    gray05: '#2c3038',
    gray06: '#1D2126',
    gray07: '#edeef2',
    
    darkblue01: '#001958',
    darkblue02: '#131f36',
    
    lightblue01: '#3100FF',
    
    black: '#1D2126',
    white: '#ffffff',
    koinzblue: '#1401a2',
    delivery: '#0242A3',

    
    background: '#edeff2',
    
  };
  
  export default Colors;