import styled from 'styled-components';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Card from '@material-ui/core/Card';
import MuiContainer from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { Colors } from '../../../theme';

export const Container = styled.div``;
export const Content = styled(MuiContainer)`
  margin-top: 10px;
  height: 72vh;

  
`;

// estilização header
export const Icon = styled(ChevronLeftIcon)`
  color: ${Colors.primary};
`;

export const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  padding-left: 20px;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BackButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  width: 5rem;
  height: 2rem;
  border-radius: 5px;
  background-color: transparent;
  color: ${Colors.primary};
  transition: all 0.2s ease-in-out;
  :hover {
    color: ${Colors.white};
    svg {
      color: ${Colors.white};
    }
    background-color: ${Colors.new_primary};
  }
`;

// estilo card profile

export const CardProfile = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 18vh;
`;

export const InfoDoctor = styled.div`
  display: flex;
  flex-direction: column;

  > h2 {
    font-weight: bold;
    font-size: 16px;
  }
  > p {
    color: ${Colors.gray04};
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }
  > p.status-info {
    color: ${(props) => (props.status ? Colors.success : Colors.error)};
  }
`;
export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
`;
export const ProfileImage = styled.img`
  background-color: #ccc;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  padding: 0;
  color: transparent;
  border: 1px solid ${Colors.new_primary};
`;
export const ActiveProfileButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  width: 7rem;
  height: 2rem;
  border-radius: 5px;
  background-color: ${(props) =>
    !props.status ? Colors.success : Colors.error};
  color: ${Colors.white};
  transition: all 0.2s ease;
  margin-right: 10%;
  :hover {
    opacity: 0.6;
  }
`;

export const InfoButtonGroup = styled(Grid)`
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const InfoButton = styled(Grid)`
  button {
    width: 15rem;
    height: 40px;
    border: none;
    border-radius: 5px;
    padding: 1px 10px;
    background-color: ${(props) =>
      props.active ? Colors.new_primary : Colors.white};
    color: ${(props) => (props.active ? Colors.white : Colors.new_primary)};
    border: ${(props) =>
      props.active ? 'none' : `1px solid ${Colors.gray03}`};
  }
`;

export const PasswordResetButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  margin-right: 20px;
  padding: 5px;

  color: ${Colors.white};
  background-color: ${Colors.error};
  border-radius: 5px;
  transition: 0.2s all;

  :hover {
    opacity: 0.5;
  }
`;

export const FormContainer = styled.div`
  margin: 20px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  & > * {
    width: 40vw;
    margin-top: 15px;
  }
`;

export const ButtonDetail = styled.button`
  height: 40px;
  width: ${(props) => {
    switch (props.size) {
      case 'large':
        return '190px';
      case 'medium':
        return '100px';
      default:
        return '30px';
    }
  }};

  border-radius: 5px;
  margin: 5px 10px;
  background-color: ${(props) =>
    props.color === 'error' ? Colors.error : Colors.success};
  color: #fff;

  transition: all 0.2s;
  :hover {
    opacity: 0.7;
  }
`;
