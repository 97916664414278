import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { Colors } from '../../../../theme';

export const Container = styled.div``;
export const Content = styled(Card)`
  padding: 10px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;

  margin: 10px 0px;
  border-bottom: 1px solid ${Colors.gray01} ;

  h3 {
    font-size: 16px;
    font-weight: bold;

    > p {
      font-weight: normal;
      margin-left: 10px;
      text-align: justify;
    }
  }
  p {
    font-weight: normal;
    margin: 0px 10px;
    text-align: justify;
  }
  
`;
