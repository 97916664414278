import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
// import history from '~/services/history';
import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { username, password, history } = payload;
    const user = {
      user: {
        username: username,
        password: password,
      },
    };
    const response = yield api.post(`users/sign_in`, user);
    const { auth_token, type_user, id, is_public=false } = response.data;
    // console.log(`response.data`, response.data);

    api.defaults.headers.Authorization = `${auth_token}`;

    yield put(signInSuccess(auth_token, type_user, id,is_public));
    history.push('/');
  } catch (err) {
    toast.error('Dados inválidos, Verifique e tente novamente');
    yield put(signFailure());
    // console.log(err);
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, password } = payload;

    yield call(api.post, 'users', {
      name,
      email,
      password,
      provider: true,
    });

    // history.push('/');
  } catch (err) {
    // toast.error('Falha no cadastro verifique seus dados!');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `${token}`;
  }
}

export function signOut({ payload }) {
  payload.history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
