import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const ContainerButtonsMenu = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  a:first-child{
    margin-bottom: 10px;
  }
`;