import styled from 'styled-components';

export const Container = styled.div`
  /* display: flex;
  flex:1; */
  width: 100%;
  /* height: 100vh; */
  /* height: 100%; */
  margin: 0px 0px;
  background-color: #fff;
`;

export const MessageContainer = styled.div`
  height: 50vh;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: normal;
  }
`;
