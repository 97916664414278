import styled from 'styled-components'
import { Colors } from '../../../theme'

export const Container = styled.div`
  /* display: flex;
  flex:1; */
  width: 100%;
  height: 100%;
  padding: 0px 80px 0px 80px;
  h1 {
    /* color: ${Colors.gray06}; */
    text-align: left;
    font-weight: normal;
    font-size: 34px;
  }
  p{
    font-size: 16px;
    color: ${Colors.secondary};
    text-align: left;
    font-weight: normal;
    margin-top: 10px;
  }
  div {
    margin: 0px 0px 20px 0px;
  }

  @media (max-width: 665px) {
    padding: 0px 20px 0px 20px;
  }
`
