import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import { getAllClinicsSuccess } from './actions';
import { signOut } from '../auth/actions';

function* getAllClinicsFromApi() {
  try {
    const response = yield call(api.get, '/users/index_clinic');

    const clinics = response.data.users.map((doc) => {
      return {
        ...doc,
        formattedDate: new Date(doc.created_at).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        }),
      };
    });

    const userPending = clinics.filter((item) => item.status === 'pending');
    const publicClinics = clinics.filter(item => item.is_public === true)
    const userPendingPayment = clinics.filter(
      (item) => item.payment_status === 'pending'
    );
    const userCancelledPayment = clinics.filter(
      (item) => item.payment_status === 'cancelled'
    );

    yield put(
      getAllClinicsSuccess({
        clinics,
        pending: userPending,
        pending_payment: userPendingPayment,
        cancelled: userCancelledPayment,
        publicClinics
      })
    );
  } catch ({ response }) {
    if (response.status === 401) {
      toast.warn('Sua sessão acabou! Faça login novamente');
      console.log(response);
      // yield put(signOut());
    }
  }
}
export default all([
  takeLatest('@clinic/GET_ALL_CLINICS_LIST_REQUEST', getAllClinicsFromApi),
]);
