import React from 'react';

import { useHistory, useParams, Link } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '../../../components/button';
import { Colors } from '../../../theme';
import logo from '../../../assets/images/logo.png';
import { toast } from 'react-toastify';

import {
  ContainerHeader,
  ContainerButton,
  ContainerPaper,
  ContainerImg,
} from './styles';

import api from '../../../services/api';

export default function RecoverPassword() {
  const history = useHistory();
  let { token } = useParams();
  token = token.replace(':', '');

  const handleSubmit = async (event) => {
    event.preventDefault();

    let password = event.target.password.value; //get value from input
    let password_confirmation = event.target.password_confirmation.value; //get value from input
    if (password === '' || password_confirmation === '') {
      toast.warn('Preencha todos os campos');
      return;
    }
    if (password !== password_confirmation) {
      toast.warn('As senhas não conferem');
      return;
    }

    try {
      const response = await api.post(
        `recoveries/change_password?token=${token}`,
        {
          password,
          password_confirmation,
        }
      );
      console.log(response);
      toast.success(response.data.msg);
      history.push('/');
    } catch (error) {
      console.log(error);
      toast.warn(error.response.data.msg);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <ContainerPaper>
        <ContainerImg>
          <Link to="/">
            <img src={logo} alt={'Logo Guia Doctor'} />
          </Link>
        </ContainerImg>
        <ContainerHeader>
          <h1>Esqueci minha senha</h1>
          <p>Preencha os campos abaixo para solicitar email de recuperação</p>
        </ContainerHeader>

        <form noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Senha"
            name="password"
            autoComplete="password"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password_confirmation"
            label="Confirmação de senha"
            name="password_confirmation"
            autoComplete="password"
            autoFocus
          />

          <ContainerButton>
            <Button type="submit">Alterar senha</Button>
          </ContainerButton>
        </form>
      </ContainerPaper>

      <Box mt={8} align="center">
        <p style={{ color: Colors.secondary }}>
          Não possui conta?{' '}
          <Link to="/cadastro">
            {/* <Link to={"/cadastro"}> */}
            <span style={{ color: Colors.primary }}>Cadastre-se</span>
          </Link>
        </p>
      </Box>
    </Container>
  );
}
