import React from 'react';
import logo from '../../assets/images/logo.png';
import { Button } from '@material-ui/core';
import {
  Container,
  MenuLinks,
  ContainerLogo,
  ContainerBtn,
  ContainerDesktop,
  ContainerMobile,
} from './styles';
import { Link, useHistory } from 'react-router-dom';
import MenuMobile from './menuMobile';

import { useDispatch } from 'react-redux'
import { getDoctorsToOpenRoutes } from '../../store/modules/doctor/actions';


export default function Header({
  goToAboutRef,
  goToPricetRef,
  goToContactRef,
  goToDoctorRate,
}) {


  const dispatch = useDispatch()
  const history = useHistory();
  /*
  const history = useHistory()
  const goToDoctorRate = () => {
    history.push('/pesquisar')
  }
  */
  const goToSearch = () => {
    const citysSelects = ' ';
    const plans = ' ';
    let personal_consultation = ' ';
    let home_consultation = ' ';



    dispatch(
      getDoctorsToOpenRoutes({
        city: citysSelects,
        home_consultation,
        personal_consultation,
        plan: plans,
        searchParam: '',
        crm: '',
      })
    );

    history.push(`/pesquisar`);
  };

  return (
    <Container>
      <ContainerLogo>
        <Link to="/">
          <img src={logo} alt="Logo Guia Doctor" />
        </Link>
        <ContainerDesktop>
          <MenuLinks>
            <Button color="secondary" onClick={goToAboutRef} to="/">
              Sobre
            </Button>
            <Button color="secondary" onClick={goToPricetRef} to="/">
              Preços
            </Button>
            <Button color="secondary" component={Link} to="/termos-de-uso">
              Termos de uso
            </Button>
            <Button
              color="secondary"
              component={Link}
              to="/normas-de-etica"
              onClick={goToPricetRef}
            >
              Normas de ética médica
            </Button>
            <Button
              color="secondary"
              component={Link}
              onClick={goToSearch}
            >
              Avalie clínicas e médicos
            </Button>

            <Button
              color="secondary"
              component={Link}
              to="/"
              onClick={goToContactRef}
            >
              Contato
            </Button>
          </MenuLinks>
        </ContainerDesktop>
        <ContainerMobile>
          <MenuMobile
            goToAboutRef={goToAboutRef}
            goToPricetRef={goToPricetRef}
            goToContactRef={goToContactRef}
          />
        </ContainerMobile>
      </ContainerLogo>

      <ContainerBtn>
        <Button
          component={Link}
          to="/cadastro"
          variant="contained"
          color="primary"
        >
          Cadastrar-se
        </Button>
        <Button component={Link} to="/login" variant="outlined" color="primary">
          Entrar
        </Button>
      </ContainerBtn>
    </Container>
  );
}
