import React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '../../../components/button';
import { Link } from 'react-router-dom';
import { Colors } from '../../../theme';
import logo from '../../../assets/images/logo.png';
import { toast } from 'react-toastify';

import {
  ContainerHeader,
  ContainerButton,
  ContainerPaper,
  ContainerImg,
} from './styles';

import api from '../../../services/api';

export default function ForgotPassword() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    let username = event.target.email.value; //get value from input
    try {
      const response = await api.post('users/reset_password', {
        email: username,
      });
      console.log(response);
      toast.success(response.data.msg);
    } catch (error) {
      toast.warn(error.response.data.msg);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <ContainerPaper>
        <ContainerImg>
          <Link to="/">
            <img src={logo} alt={'Logo Guia Doctor'} />
          </Link>
        </ContainerImg>
        <ContainerHeader>
          <h1>Esqueci minha senha</h1>
          <p>Preencha os campos abaixo para solicitar email de recuperação</p>
        </ContainerHeader>

        <form noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <ContainerButton>
            <Button type="submit">Solicitar</Button>
          </ContainerButton>
        </form>
      </ContainerPaper>

      <Box mt={8} align="center">
        <p style={{ color: Colors.secondary }}>
          Não possui conta?{' '}
          <Link to="/cadastro">
            {/* <Link to={"/cadastro"}> */}
            <span style={{ color: Colors.primary }}>Cadastre-se</span>
          </Link>
        </p>
      </Box>
    </Container>
  );
}
