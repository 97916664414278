import React, { useState } from 'react';

import Cards from 'react-credit-cards';

import {
  CreditCardContainer,
  Input,
  InputDateDiv,
  ButtonContainer,
} from './styles';
import 'react-credit-cards/es/styles-compiled.css';
import { makeStyles, Button } from '@material-ui/core';
import { toast } from 'react-toastify';

function CardData({ prev, next, handleData, data }) {
  const [card, setCard] = useState({
    cvc: data.cvc,
    expiryMonth: data.expiryMonth,
    expiryYear: data.expiryYear,
    name: data.name,
    number: data.number,
    cpf: data.cpf,
    phone: data.phone,
    birth_date: data.birth_date,
    card_brand: data.card_brand.toUpperCase(),
  });
  const [hasNotBrand, setHasNotBrand] = useState(false);
  const [focused, setFocused] = useState();

  const handleInputFocus = (e) => {
    setFocused(e.target.name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCard({ ...card, [name]: value });
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }));
  const handlecard = (brand) => {
    if (brand.issuer !== 'unknown') {
      setCard({
        ...card,
        card_brand: brand.issuer.toUpperCase(),
      });
    }

    if (card.number.length > 5 && brand.issuer === 'unknown') {
      setHasNotBrand(true);
      setCard({
        ...card,
        card_brand: '',
      });
    }
  };

  const classes = useStyles();

  const onSubmit = () => {
    if (
      card.cvc === '' ||
      card.expiryMonth === '' ||
      card.expiryYear === '' ||
      card.name === '' ||
      card.number === '' ||
      card.cpf === '' ||
      card.phone === '' ||
      card.birth_date === ''
    ) {
      toast.warn('preencha todos os dados para prosseguir');
      return;
    }
    handleData(card);
    next();
  };
  return (
    <>
      <CreditCardContainer>
        <Cards
          cvc={card.cvc}
          expiry={`${card.expiryMonth}/${card.expiryYear}`}
          focused={focused}
          name={card.name}
          number={card.number}
          placeholders={{ name: 'Seu Nome aqui' }}
          callback={handlecard}
        />
        <form>
          <label htmlFor="numero do cartão">N° do cartão</label>
          <Input
            type="text"
            name="number"
            placeholder="Numero do cartão"
            value={card.number}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          {hasNotBrand && (
            <Input
              type="text"
              name="card_brand"
              placeholder="Bandeira do cartão"
              value={card.card_brand}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          )}
          <label htmlFor="nome do proprietário">Nome</label>
          <Input
            type="text"
            name="name"
            placeholder="Nome"
            value={card.name}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <InputDateDiv>
            <label htmlFor="mes de vencimento">Mês de vencimento</label>
            <Input
              type="text"
              name="expiryMonth"
              maxLength={5}
              placeholder="Mes de expiração"
              value={card.expiryMonth}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              style={{ marginRight: '5px' }}
            />
            <label htmlFor="aniversario">Ano de Expiração</label>
            <Input
              type="text"
              name="expiryYear"
              value={card.expiryYear}
              maxLength={5}
              placeholder="Ano ex: 2028"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              style={{ marginRight: '5px' }}
            />
            <label htmlFor="aniversario">CVV</label>
            <Input
              type="number"
              name="cvc"
              placeholder="CVV"
              value={card.cvc}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </InputDateDiv>
          <label htmlFor="aniversario">CPF</label>
          <Input
            type="text"
            name="cpf"
            placeholder="CPF"
            value={card.cpf}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <label htmlFor="aniversario">Telefone</label>
          <Input
            value={card.phone}
            type="phone"
            name="phone"
            placeholder="Telefone para contato"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <label htmlFor="data de nascimento">
            Data de nascimento do proprietário
          </label>
          <Input
            type="date"
            name="birth_date"
            value={card.birth_date}
            placeholder="Data de aniversário"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </form>
      </CreditCardContainer>
      <ButtonContainer className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          onClick={prev}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          onClick={onSubmit}
        >
          Próximo
        </Button>
      </ButtonContainer>
    </>
  );
}

export default CardData;
