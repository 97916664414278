import styled from 'styled-components';
import { Card as MCard } from '@material-ui/core';
import { Colors } from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Content = styled.div`
  height: 70vh;

  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.h2``;
export const PlanTitle = styled.h3`
  font-weight: bold;
  font-size: 25px;
  align-self: flex-start;
  margin-left: 10px;
  color: ${Colors.gray03};
`;

export const CardPlan = styled(MCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 30vw;

  background: rgb(38, 65, 145);
  background: linear-gradient(
    90deg,
    rgba(38, 65, 145, 0.9) 0%,
    rgba(49, 0, 255, 0.9) 100%
  );
  margin: 10px;
`;

export const ValuePlan = styled.h3`
  font-weight: bold;
  font-size: 40px;
  color: ${Colors.white};
`;

export const SignatureData = styled(MCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  width: 30vw;
  margin: 10px;
  padding: 10px;
`;
export const SignatureTitle = styled.h3`
  font-weight: bold;
  font-size: 18px;
  margin: 10px;
`;
export const SignatureCode = styled.h3`
  font-weight: bold;
  font-size: 25px;
  color: ${Colors.delivery};
`;

export const StatusContainer = styled(MCard)`
  margin: 10px;
  padding: 10px;
`;

export const StatusText = styled.h3`
  font-weight: bold;
  font-size: 25px;
  color: ${(props) => (props.signatureStatus ? Colors.error : Colors.success)};
`;

export const ButtonGroup = styled(MCard)`
  margin: 10px;
  padding: 10px;
`;

export const Button = styled.button`
  background-color: ${(props) =>
    props.color === 'error' ? Colors.error : Colors.success};

  color: #fff;

  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  transition: all 0.5s ease;
  :hover {
    opacity: 0.7;
  }
`;
