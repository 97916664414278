import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import { getAllDoctorsSuccess } from './actions';
import { signOut } from '../auth/actions';

function* getAllDoctorsFromApi({ payload }) {
  try {
    const response = yield call(api.get, '/users/index_doctor');
    //  console.log(response);

    const doctors = response.data.users.map((doc) => {
      return {
        ...doc,
        formattedDate: new Date(doc.created_at).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        }),
      };
    });

    /**
     * status:pending
        payment_status:pending
     */
    const userPending = doctors.filter((item) => item.status === 'pending');
    const userPendingPayment = doctors.filter(
      (item) => item.payment_status === 'pending'
    );
    const userCancelledPayment = doctors.filter(
      (item) => item.payment_status === 'cancelled'
    );

    yield put(
      getAllDoctorsSuccess({
        doctors,
        pending: userPending,
        pending_payment: userPendingPayment,
        cancelled: userCancelledPayment,
      })
    );
  } catch ({ response }) {
    if (response.status === 401) {
      toast.warn('Sua sessão acabou! Faça login novamente');
      // console.log(response);
      yield put(signOut(payload.history));
    } else {
      toast.warn('Aconteceu um erro!');
    }
  }
}

function* getDoctorsToShowInOpenList({ payload }) {
  const {
    city,
    searchParam,
    personal_consultation,
    home_consultation,
    plan,
    crm,
  } = payload;

  try {
    // criando a url de busca
    const getCitySelect = `city_id=${city}`;
    const getSearchParam = `search_param=${searchParam}`;
    const getPersonalConsultation = `personal_consultation=${personal_consultation}`;
    const getHomeConsultation = `home_consultation=${home_consultation}`;
    const getHelthPlanId = `helth_plan_id=${plan}`;
    const getCrm = `crm=${crm}`;

    const url = `users/search?${getCitySelect}&${getSearchParam}&${getPersonalConsultation}&${getHomeConsultation}&${getHelthPlanId}&${getCrm}`;
    // const newUrl = encodeURI(url).replace(/\s/g, '');
    // console.log(newUrl);
    const response = yield call(api.get, encodeURI(url).replace(/\s/g, ''));
    // const response = yield call(api.get, url);
    const doctors = response.data.users;
    console.log(doctors);

    yield put(getAllDoctorsSuccess({ doctors }));
  } catch (error) {
    console.tron.log(error);
  }
}
export default all([
  takeLatest('@doctor/GET_ALL_DOCTORS_LIST_REQUEST', getAllDoctorsFromApi),
  takeLatest('@doctor/GET_DOCTORS_TO_OPEN_ROUTES', getDoctorsToShowInOpenList),
]);

/**
 * , {
      headers: {
        Authorization:
          'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkZW1hcmlvY2NAZ21haWwuY29tIiwiZXhwIjoxNjE1ODM2Njg4fQ.sBNHQwTNQ6Qq1V_P8YXwNFhpMvGA5bpi5_8B1LuxYbk',
      },
    }
 */
