import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import MuiTable from '@material-ui/core/TableContainer';

export const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
export const Title = styled.h2`
  margin-top: 10px;
`;

export const TableContainer = styled(MuiTable)`
  width: 95%;
  margin: 20px 0px;
`;

export const TextHeaderTable = styled.p`
  font-weight: bold;
`;
