import styled from 'styled-components';
import Colors from '../../../theme/Colors';

export const Container = styled.div`
  
`;

export const ContainerCard = styled.div`
  width: 100%;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderCard = styled.div`
  
`;

export const BodyCard = styled.div`
  margin: 10px 0px 0px 0px;
`;

export const TitleCard = styled.p`
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
`;

export const SubTitleCard = styled.p`
    /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: ${Colors.gray05}
`;