import styled from 'styled-components'
import Card from '../../../components/card'

export const Container = styled.div``

export const CardStyle = styled(Card)`
  max-width: 20000px;
  display: flex;
  margin-bottom: 40px;
  img {
    align-self: center;
  }
  h5 {
    text-align: center;
  }
`

export const CardStyleButton = styled(Card)`
  padding: 0px;
  display: flex;
  flex: 1;
  margin: 10px;
  max-width: 48%;
  button {
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    span{
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }
  h5 {
    text-align: left;
  }
  h4 {
    font-weight: 400;
    text-align: left;
  }
`

export const ImgUp = styled.img`
  width: 100px;
  height: 100px;
`
export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`

export const ContainerCardForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
`
