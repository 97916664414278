import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
  CardContainer,
  Title,
  TableContainer,
  TextHeaderTable,
} from './styles';

export default function Information({ title, tableTitles, data }) {
  return (
    <CardContainer>
      <Title>{title}</Title>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableTitles.map((title) => (
                <TableCell key={title} align="center">
                  <TextHeaderTable>{title}</TextHeaderTable>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, i) => (
              <TableRow key={i} hover>
                <TableCell align="left">{item.value1}</TableCell>
                <TableCell align="center"> {item.value2}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContainer>
  );
}
