import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export default function ExaminationClinics() {
  const classes = useStyles();
  const userDataRedux = useSelector((state) => state.auth);
  const [userInf, setuserInf] = useState('');

  console.log('userDataRedux22', userDataRedux);

  const getUserData = async () => {
    try {
      const response = await api.get(`users?id=${userDataRedux.id}`);
      console.log('=========users', response);
      const { user } = response.data;
      setuserInf(user);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper
          style={{ height: '100%', maxHeight: 600, overflowY: 'scroll' }}
          className={classes.paper}
        >
          <>
            {userInf && !userInf.complete_data ? (
              <p>
                Olá <strong>{userInf?.member?.name}</strong>, seu perfil ainda
                não está completo, vá em perfil e complete seus dados
              </p>
            ) : (
              <p>
                Olá <strong>{userInf?.member?.name}</strong>, seu perfil está
                completo
              </p>
            )}
          </>
          <br />

          {userInf && userInf.addresses.length <= 0 && (
            <p>
              Você ainda não cadastrou nenhum endereço! clique{' '}
              <Link to="profile-address">
                <strong>Aqui</strong>
              </Link>{' '}
              para cadastrar
            </p>
          )}
          {userInf &&
            userDataRedux.type_user === 'clinic' &&
            userInf.medical_clinic_exams.length <= 0 && (
              <p>
                Ainda não foi cadastrado nenhum exame realizado por vocês!
                clique{' '}
                <Link to="profile">
                  <strong>Aqui</strong>
                </Link>{' '}
                e adicione ao seu perfil
              </p>
            )}
          {userInf &&
            userDataRedux.type_user !== 'clinic' &&
            userInf.specialties.length <= 0 && (
              <p>
                Ainda não foi cadastrado nenhuma especialidade! clique{' '}
                <Link to="profile">
                  <strong>Aqui</strong>
                </Link>{' '}
                e adicione ao seu perfil
              </p>
            )}
        </Paper>
      </main>
    </>
  );
}

/**
 *
 {userInf &&
   !userInf.complete_data &&
   userDataRedux.type_user === 'clinic' && <FinalizeClinicalRecord />}
 
 {userInf &&
   !userInf.complete_data &&
   userDataRedux.type_user !== 'clinic' && <FinalizeMedicRecord />}
 */
