import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Card from '../../../components/cardInfo';
import ContainerContent from '../../../components/container';
import { Container, MessageContainer } from './styles';
//import api from '../../../services/api';

import Header from '../../../components/header';
import Footer from '../../../components/footer';

export default function Search() {
  const users = useSelector((store) => store.doctor.doctors);

  return (
    <>
      <Header />
      <Container>
        <ContainerContent>
          {users &&
            users.map((user) => (
              <Fragment key={user?.id.toString()}>
                <Card
                  link={`/profile/${user?.member?.fantasy_name}/${user?.id}`}
                  user={user}
                  style={{ marginBottom: 20 }}
                />
              </Fragment>
            ))}

          {users.length < 1 && (
            <MessageContainer>
              <h2>AINDA NÃO EXISTEM MÉDICOS CADASTRADOS COM ESTES ITENS</h2>
            </MessageContainer>
          )}
        </ContainerContent>
      </Container>
      <Footer />
    </>
  );
}
