import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import EditAttributesOutlinedIcon from '@material-ui/icons/EditOutlined'
import { toast } from 'react-toastify';

import {
  Typography,
  Grid,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { Card, ButtonDetail } from './styles';
import api from '../../../../services/api';
import { formatPlans, formatExams } from '../../../../util/formatPlansData';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function TechnicalInformation({ user }) {
  const { type_user } = useSelector((state) => state.auth);
  const [uneditable, setUneditable] = useState(true);
  const [technicalData, setTechnicalData] = useState({
    id: user?.id,
    member_id: user?.member?.id,
    socialName: user?.member?.name,
    cnpj: user.member.cnpj,
    fantasyName: user.member.fantasy_name,
    crm: user.member.crm,
    youtube_link: user?.member?.youtube_link,
    home_consultation: user?.member?.home_consultation,
    responsibleMedic: user.member?.responsible_medic || user.member.name,
    responsibleCrm: user.member.registration_number_crm,
    description: user.member.professional_experience,
    medicalHealthPlansAttributes: user.medical_health_plans.map((item) => {
      return {
        id: item.health_plan.id,
        name: item.health_plan.name,
      };
    }),
    medicalExamsCheck: user.member.personal_consultation,
    medicalEspecialtiesAttributes: user.specialties.map((item) => {
      return {
        id: item.medical_specialty.id,
        name: item.medical_specialty.name,
      };
    }),
    medicalClinicExamsAttributes: user.medical_clinic_exams
      ? user?.medical_clinic_exams.map((item) => {
          return {
            id: item.medical_exam.id,
            name: item.medical_exam.name,
          };
        })
      : [],
  });
  const [state, setState] = useState(user.member.crm_uf);
  const [stateList, setstateList] = useState([]);

  const [checkMedicallHealthCheck, setCheckMedicallHealthCheck] = useState(
    user.member.health_plan
  );
  const [medicalHealthPlansList, setMedicalHealthPlansList] = useState([]);
  const [medicalExams, setmedicalExams] = useState([]);

  const classes = useStyles();

  const handleChangeState = (event) => {
    const value = parseInt(event.target.value);
    const id = event.target.id;
    const name = event.target.name;
    console.log('event.target.uf', event.target);
    const getStateArray = stateList.filter((state) => state?.id === value);
    console.log('getStateArray', getStateArray);
    setState({
      [id]: getStateArray[0].id,
      [name]: getStateArray[0].name,
      uf: getStateArray[0].uf,
    });
  };

  const handleStateTechnicalData = (name, value) => {
    setTechnicalData({
      ...technicalData,
      [name]: value,
    });
  };

  const getOptionLabelMedical = (props) => {
    console.log('props', props);
    handleStateTechnicalData('medicalClinicExamsAttributes', props);
  };

  const getOptionLabelHealth = (props) => {
    console.log('props', props);

    handleStateTechnicalData('medicalHealthPlansAttributes', props);
  };

  // pega planos de saude
  const getMedicalHealthPlans = async () => {
    try {
      const response = await api.get('medical_health_plans');
      // console.log('response', response);
      setMedicalHealthPlansList(response.data.health_plans);
    } catch (error) {
      console.log('error', error);
    }
  };
  // pega lista de exames
  const getMedicalExams = async () => {
    try {
      const response = await api.get('medical_exams');
      // console.log('response1', response)

      setmedicalExams(response.data.medical_exams);
    } catch (error) {
      console.log('error', error);
    }
  };

  // pega lista de estados
  const getUf = async () => {
    try {
      const response = await api.get('states?state_id=&filter_by_state=');
      //  console.log('response111', response);
      const dataUf = response.data.states;
      setstateList(dataUf);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSubmit = async (data) => {
    try {
      const type = type_user === 'clinic' ? 'clinic' : 'doctor';

      const response = await api.patch(`users/${type}?id=${user.id}`, data);

      console.log(response);
      toast.success('Atualizado com sucesso');
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg);
    }
  };

  const formatDataExam = () => {
    const medicalClinicExamsAttributesFormating = technicalData.medicalClinicExamsAttributes.reduce(
      (acum, res) => {
        acum.push({ medical_exam_id: res.id });
        return acum;
      },
      []
    );

    const medicalHealthPlansAttributesFormating = technicalData.medicalHealthPlansAttributes.reduce(
      (acum, res) => {
        acum.push({ health_plan_id: res.id });
        return acum;
      },
      []
    );

    // array vazio de planos que será enviado para a api
    // console.log(validPlansData, medicalHealthPlansAttributesFormating);
    const plans = formatPlans(
      user.medical_health_plans,
      medicalHealthPlansAttributesFormating
    );

    const exams = formatExams(
      user.medical_clinic_exams,
      medicalClinicExamsAttributesFormating
    );

    const data = {
      user: {
        member_attributes: {
          id: technicalData.member_id,
          name: technicalData.socialName,
          cnpj: technicalData.cnpj,
          fantasy_name: technicalData.fantasyName,
          registration_number_crm: technicalData.responsibleCrm,
          youtube_link: technicalData.youtube_link,
          crm: technicalData.crm,
          crm_uf_id: state.id,
          home_consultation: technicalData.home_consultation,
          professional_experience: technicalData.description,
          personal_consultation: technicalData.medicalExamsCheck,
          health_plan: checkMedicallHealthCheck,
        },
        medical_specialties_attributes:
          technicalData.medicalEspecialtiesAttributes,
        medical_health_plans_attributes: plans,
        medical_clinic_exams_attributes: exams,
      },
    };
    console.log(data);
    handleSubmit(data);
  };

  useEffect(() => {
    getUf();
    getMedicalHealthPlans();
    getMedicalExams();
  }, []);
  return (
    <Card>
      <Typography variant="h5" gutterBottom>
        Informações Tecnicas
      </Typography>
      <ButtonDetail size="large" onClick={() => setUneditable(!uneditable)}>
        {uneditable ? 'Editar Dados' : 'Bloquear Campos'}
      </ButtonDetail>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="name"
            name="name"
            disabled={uneditable}
            value={technicalData.socialName}
            onChange={(event) =>
              handleStateTechnicalData('socialName', event.target.value)
            }
            label="“Nome ou Razão Social"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="youtube_link"
            name="youtube_link"
            disabled={uneditable}
            value={technicalData.youtube_link}
            onChange={(event) =>
              handleStateTechnicalData('youtube_link', event.target.value)
            }
            label="Envie o link de um vídeo do youtube sobre seu trabalho - até 1min30s"
            fullWidth
          />
        </Grid>
        {type_user === 'clinic' && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                disabled={uneditable}
                value={technicalData.cnpj}
                onChange={(event) =>
                  handleStateTechnicalData('cnpj', event.target.value)
                }
                id="CNPJ"
                name="CNPJ"
                label="CNPJ"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                disabled={uneditable}
                value={technicalData.fantasyName}
                onChange={(event) =>
                  handleStateTechnicalData('fantasyName', event.target.value)
                }
                id="fantasy_name"
                name="fantasy_name"
                label="Nome Fantasia"
                fullWidth
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            disabled={uneditable}
            value={technicalData.crm}
            onChange={(event) =>
              handleStateTechnicalData('crm', event.target.value)
            }
            id="crm"
            name="crm"
            label="N˚ de Registro no CRM"
            fullWidth
          />
        </Grid>
        {type_user === 'clinic' && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                disabled={uneditable}
                value={technicalData.responsibleMedic}
                onChange={(event) =>
                  handleStateTechnicalData(
                    'responsibleMedic',
                    event.target.value
                  )
                }
                id="responsible_medic"
                name="responsible_medic"
                label="Responsável Técnico Médico:"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                required
                disabled={uneditable}
                value={technicalData.responsibleCrm}
                onChange={(event) =>
                  handleStateTechnicalData('responsibleCrm', event.target.value)
                }
                id="responsible_crm"
                name="responsible_crm"
                label="CRM "
                fullWidth
              />
            </Grid>
          </>
        )}
        <Grid item xs={6} sm={3}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">UF</InputLabel>
            <Select
              native
              disabled={uneditable}
              value={stateList?.name}
              onChange={handleChangeState}
              inputProps={{
                id: 'id',
                name: 'name',
              }}
            >
              <option value={state?.id}>{state?.uf}</option>
              {stateList &&
                stateList.map((state) => (
                  <option key={state.name + 1} value={state?.id}>
                    {state?.uf}
                  </option>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={uneditable}
            value={technicalData.description}
            onChange={(event) =>
              handleStateTechnicalData('description', event.target.value)
            }
            id="description"
            name="description"
            label="Descreva a qualificação..."
            placeholder="Descreva a qualificação profissional e experiência e prêmios da clínica (1000 caracteres)"
            fullWidth
            multiline
            // rows={5}
            rowsMax="5"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={uneditable}
                checked={technicalData.medicalExamsCheck}
                onClick={(event) =>
                  handleStateTechnicalData(
                    'medicalExamsCheck',
                    event.target.checked
                  )
                }
                color="secondary"
                name="saveAddress"
                value="yes"
              />
            }
            label="Consulta Particular"
          />
        </Grid>
        {type_user !== 'clinic' && (
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={uneditable}
                  checked={technicalData.home_consultation}
                  onClick={(event) =>
                    handleStateTechnicalData(
                      'home_consultation',
                      event.target.checked
                    )
                  }
                  color="secondary"
                  name="saveAddress"
                  value="yes"
                />
              }
              label="Consulta Domiciliar"
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={uneditable}
                checked={checkMedicallHealthCheck}
                onClick={(event) =>
                  setCheckMedicallHealthCheck(event.target.checked)
                }
                color="secondary"
                name="medicalHealthCheck"
                value="yes"
              />
            }
            label="Plano de Saúde"
          />
        </Grid>
        {medicalHealthPlansList.length !== 0 && checkMedicallHealthCheck && (
          <Grid item xs={12} sm={12}>
            <Autocomplete
              multiple
              disabled={uneditable}
              id="tags-standard2"
              value={technicalData.medicalHealthPlansAttributes}
              options={medicalHealthPlansList}
              getOptionLabel={(option) => option?.name}
              onChange={(event, value) => getOptionLabelHealth(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Planos de Saúde"
                  placeholder="Selecione"
                  disabled={uneditable}
                />
              )}
            />
          </Grid>
        )}

        {type_user === 'clinic' && (
          <Grid item xs={12} sm={12}>
            <Autocomplete
              multiple
              disabled={uneditable}
              value={technicalData.medicalClinicExamsAttributes}
              id="tags-standard"
              options={medicalExams}
              getOptionLabel={(option) => option?.name}
              onChange={(event, value) => getOptionLabelMedical(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="exames"
                  placeholder="Selecione"
                  disabled={uneditable}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      {!uneditable && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={formatDataExam}
            type="submit"
            className={classes.button}
          >
            Atualizar Informaçoes Técnicas
          </Button>
        </div>
      )}
    </Card>
  );
}

export default TechnicalInformation;
