import React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '../../../components/button';
import { Link, useHistory } from 'react-router-dom';
import { Colors } from '../../../theme';
import logo from '../../../assets/images/logo.png';
import { useDispatch } from 'react-redux';
import { signInRequest } from '../../../store/modules/auth/actions';

import {
  ContainerHeader,
  ContainerRecovery,
  ContainerButton,
  ContainerPaper,
  ContainerImg,
} from './styles';

export default function Login() {
  let history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    let username = event.target.email.value; //get value from input
    let password = event.target.password.value; //get value from input
    dispatch(signInRequest(username, password, history));
  };

  return (
    <Container component="main" maxWidth="xs">
      <ContainerPaper>
        <ContainerImg>
          <Link to="/">
            <img src={logo} alt={'Logo Guia Doctor'} />
          </Link>
        </ContainerImg>
        <ContainerHeader>
          <h1>Entrar</h1>
          <p>Preencha os campos abaixo para acessar sua conta</p>
        </ContainerHeader>

        <form noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <ContainerRecovery>
            <Link to="/forgot-password">
              <p>Esqueci minha senha</p>
            </Link>
          </ContainerRecovery>
          <ContainerButton>
            <Button type="submit">Entrar</Button>
          </ContainerButton>
        </form>
      </ContainerPaper>

      <Box mt={8} align="center">
        <p style={{ color: Colors.secondary }}>
          Não possui conta?{' '}
          <Link to="/cadastro">
            {/* <Link to={"/cadastro"}> */}
            <span style={{ color: Colors.primary }}>Cadastre-se</span>
          </Link>
        </p>
      </Box>
    </Container>
  );
}
