import React from 'react';
import { Switch, Route } from 'react-router-dom';


import Main from '../pages/openPages/main';
import ProfileDetails from '../pages/openPages/profileDetails';
import Search from '../pages/openPages/search';
import Layout from '../pages/_layouts/default';
import LayoutRegister from '../pages/_layouts/register';
import Register from '../pages/openPages/register';
import ExaminationClinics from '../pages/openPages/register/examinationClinics';

import PublicClinics from '../pages/openPages/register/publicClinic';
import Medic from '../pages/openPages/register/medic';

import Login from '../pages/openPages/login';
import TermsOfUse from '../pages/openPages/termsOfUse';
import EthicalStandards from '../pages/openPages/ethicalStandards';
import ForgotPassword from '../pages/openPages/forgotPassword';
import RecoverPassword from '../pages/openPages/recoverPassword';

// ------------- rate doctor ---
import DoctorRate from '../pages/openPages/doctorRate';
import ClinicRate from '../pages/openPages/clinicRate';

export default function clientRoutes() {
  return (
    <>
      <Route path="/" exact component={Main} />
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route
        path="/recoveries/:token"
        component={RecoverPassword}
      />

      <Route
        path="/avaliar-medico/:id"
        component={() => (
          <Layout>
            <DoctorRate />
          </Layout>
        )}
      />
      <Route
        path="/avaliar-clinica/:id"
        component={() => (
          <Layout>
            <ClinicRate />
          </Layout>
        )}
      />
      <Route
        path="/termos-de-uso"
        component={() => (
          <Layout>
            <TermsOfUse />
          </Layout>
        )}
      />
      <Route
        path="/normas-de-etica"
        component={() => (
          <Layout>
            <EthicalStandards />
          </Layout>
        )}
      />
      <Route
        path="/pesquisar"
        // path="/pesquisar/:citySelect/:searchParam/:plan/:personal_consultation/:home_consultation"
        component={Search}
      />
      <Route
        path="/profile/:name/:id"
        component={() => (
          <Layout>
            <ProfileDetails />
          </Layout>
        )}
      />

      <Switch>
        <Route
          path="/cadastro"
          component={() => (
            <LayoutRegister>
              <Register />
            </LayoutRegister>
          )}
        />
        <Route
          path="/examination-clinics"
          component={() => (
            <LayoutRegister>
              <ExaminationClinics />
            </LayoutRegister>
          )}
        />
        <Route
          path="/public-clinics"
          component={() => (
            <LayoutRegister>
              <PublicClinics />
            </LayoutRegister>
          )}
        />
        <Route
          path="/medic"
          component={() => (
            <LayoutRegister>
              <Medic />
            </LayoutRegister>
          )}
        />
      </Switch>
    </>
  );
}
