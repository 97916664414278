import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../services/api';

import { CardStyle } from '../styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ChangePassword() {
  const [password, setpassword] = useState('');
  const [passwordAnt, setPasswordAnt] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');

  const [passwordValidate, setpasswordValidate] = useState(false);
  const [confirmPasswordValidate, setconfirmPasswordValidate] = useState(false);
  const [passwordAntValidate, setpasswordAntValidate] = useState(false);
  const classes = useStyles();

  const nextSteep = async () => {
    try {
      const response = await api.put('users/change_password', {
        password: passwordAnt,
        new_password: password,
        new_password_confirmation: confirmPassword,
      });
      // console.log('response', response);
      // history.push('/')
      setpassword('');
      setPasswordAnt('');
      setconfirmPassword('');
      toast.success('Senha alterada com sucesso');
    } catch (error) {
      console.log('error', error.data);
      console.log('error', error);
    }
  };

  const nextForm = () => {
    if (password === '') {
      setpasswordValidate(true);
    } else {
      if (password.length < 6)
        toast.error('A senha precisa ter 6 ou mais caracteres');
      else setpasswordValidate(false);
    }
    confirmPassword === ''
      ? setconfirmPasswordValidate(true)
      : setconfirmPasswordValidate(false);
    passwordAnt === ''
      ? setpasswordAntValidate(true)
      : setpasswordAntValidate(false);

    if (password !== confirmPassword)
      toast.error('Verifique se a senha está correta');
    if (
      passwordAnt !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      password === confirmPassword &&
      password.length >= 6 &&
      confirmPassword.length >= 6
    ) {
      nextSteep();
    }
  };

  return (
    <div>
      <CardStyle>
        <Typography variant="h5" gutterBottom>
          Alterar Senha
        </Typography>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            value={passwordAnt}
            onChange={(event) => setPasswordAnt(event.target.value)}
            id="password"
            name="password"
            label="Senha anterior"
            fullWidth
            type="password"
            autoComplete="current-password"
            helperText={passwordAntValidate && 'Campo Obrigatório.'}
            error={passwordAntValidate}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            value={password}
            onChange={(event) => setpassword(event.target.value)}
            id="password"
            name="password"
            label="Nova senha"
            fullWidth
            type="password"
            autoComplete="current-password"
            helperText={passwordValidate && 'Campo Obrigatório.'}
            error={passwordValidate}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            required
            value={confirmPassword}
            onChange={(event) => setconfirmPassword(event.target.value)}
            id="confirmPassword"
            name="confirmPassword"
            label="Confirmar nova senha"
            fullWidth
            type="password"
            autoComplete="current-password"
            helperText={confirmPasswordValidate && 'Campo Obrigatório.'}
            error={confirmPasswordValidate}
          />
        </Grid>
        <>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={nextForm}
              className={classes.button}
            >
              Alterar
            </Button>
          </div>
        </>
      </CardStyle>
    </div>
  );
}
