import styled from 'styled-components';
import { Colors } from '../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
`;

export const Button = styled.button`
  background-color: transparent;
  padding: 5px;

  svg {
    width: 30px;
    height: 30px;
  }
`;
export const ButtonSave = styled.button`
  width: auto;
  padding: 5px;
  background-color: ${Colors.success};
  color: white;
  border-radius: 5px;
  transition: all 0.5s ease;

  :hover {
    opacity: 0.7;
  }
`;
