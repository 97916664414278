import React from 'react';

import { Card, Image, ImageContent, Title } from './styles';

function Document({ user }) {
  return (
    <div>
      {user?.member?.specialist_certificate_image ? (
        <Card>
          <Title>Diploma de médico</Title>
          <ImageContent>
            <Image src={user.member.specialist_certificate_image} />
          </ImageContent>
        </Card>
      ) : (
        <Card>
          <Title>Diploma de médico não enviado</Title>
        </Card>
      )}
      {user?.member?.crm_card_image ? (
        <Card>
          <Title>CRM</Title>
          <ImageContent>
            <Image src={user.member.crm_card_image} />
          </ImageContent>
        </Card>
      ) : (
        <Card>
          <Title>CRM não enviado</Title>
        </Card>
      )}
      {user?.member.crm_regularity_image ? (
        <Card>
          <Title>
            Certificado de registro de RQE no Conselho Regional de Medicina
          </Title>
          <ImageContent>
            <Image src={user.member.crm_regularity_image} />
          </ImageContent>
        </Card>
      ) : (
        <Card>
          <Title>
            Certificado de registro de RQE no Conselho Regional de Medicina não
            enviado
          </Title>
        </Card>
      )}
      {user?.member.proof_address_image ? (
        <Card>
          <Title>Comprovante de endereço do local de atendimento</Title>
          <ImageContent>
            <Image src={user.member.proof_address_image} />
          </ImageContent>
        </Card>
      ) : (
        <Card>
          <Title>
            Comprovante de endereço do local de atendimento não enviado
          </Title>
        </Card>
      )}
    </div>
  );
}

export default Document;
