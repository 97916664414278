import React, { Fragment, useEffect, useState } from 'react';
import { CardStyleButton, ContainerCardForm } from './styles';
import { makeStyles, Button } from '@material-ui/core';
import api from '../../../../services/api';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useHistory } from 'react-router';

export default function FormAddress() {
  const userDataRedux = useSelector((state) => state.auth);
  const history = useHistory();

  const [state, setState] = useState('');
  const [stateList, setstateList] = useState([]);

  // Endereco
  const [streetValidate, setstreetValidate] = useState(false);
  const [zipcodeValidate, setzipcodeValidate] = useState(false);
  const [street, setstreet] = useState('');
  const [city, setcity] = useState({
    id: '',
    name: '',
  });
  const [zipcode, setzipcode] = useState('');
  const [district, setdistrict] = useState('');
  const [number, setnumber] = useState('');
  const [numberValidate, setnumberValidate] = useState(false);
  const [complement, setcomplement] = useState('');
  const [complementValidate, setcomplementValidate] = useState(false);
  const [districtValidate, setdistrictValidate] = useState(false);
  const [cityList, setcityList] = useState([]);
  const [phone, setphone] = useState('');
  const [phoneValidate, setphoneValidate] = useState(false);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const getUf = async () => {
    try {
      const response = await api.get('states?state_id=&filter_by_state=');
      // console.log('response', response)
      const dataUf = response.data.states;
      setstateList(dataUf);
    } catch (error) {
      // console.log('error', error)
    }
  };

  const handleChangeState = (event) => {
    const value = parseInt(event.target.value);
    const id = event.target.id;
    const name = event.target.name;
    const uf = event.target.uf;
    // console.log('event.target.uf', event.target)
    const getStateArray = stateList.filter((state) => state?.id === value);
    // console.log('getStateArray', getStateArray)
    setState({
      [id]: getStateArray[0].id,
      [name]: getStateArray[0].name,
      uf: getStateArray[0].uf,
    });
  };

  const getZipCode = async () => {
    try {
      const response = await api.get(`cities/search_cep?cep=${zipcode}`);
      const dataCitie = response.data;
      // console.log('response', dataCitie)
      setstreet(dataCitie?.street);
      setdistrict(dataCitie?.district);

      setState({
        id: dataCitie?.state?.id,
        name: dataCitie?.state?.name,
        uf: dataCitie?.state?.uf,
      });

      setcity({
        id: dataCitie?.city?.id,
        name: dataCitie?.city?.name,
      });
    } catch (error) {
      // console.log('error', error)
    }
  };

  const getCity = async () => {
    try {
      const response = await api.get(
        `cities?part_text=&city_id=&state_id=${state?.id}`
      );
      const dataCity = response.data.cities;
      // console.log('response', dataCity)
      setcityList(dataCity);
    } catch (error) {
      // console.log('error', error)
    }
  };

  const handleChangeCity = (event) => {
    const value = parseInt(event.target.value);
    const id = event.target.id;
    const name = event.target.name;
    const getStateArray = cityList.filter((state) => state.id === value);
    setcity({
      [id]: getStateArray[0].id,
      [name]: getStateArray[0].name,
    });
  };

  const nextSteep = async () => {
    const params = {
      user: {
        addresses_attributes: [
          {
            street: street,
            number: number,
            complement: complement,
            zipcode: zipcode,
            district: district,
            city_id: city.id,
            state_id: state.id,
            phone: phone,
          },
        ],
      },
    };

    console.log('params', params);
    // console.log('data', userDataRedux.type_user);
    try {
      //  const response =
      if (userDataRedux.type_user === 'clinic') {
        await api.patch(`users/clinic?id=${userDataRedux.id}`, params);
      } else {
        await api.patch(`users/doctor?id=${userDataRedux.id}`, params);
      }

      //console.log('response', response);
      history.push('profile');
    } catch (error) {
      // console.log('error', error.data)
      console.log('error', error);
    }
  };

  const validateForm = (event) => {
    event.preventDefault();

    nextSteep();
  };

  useEffect(() => {
    getUf();
  }, []);

  return (
    <ContainerCardForm style={{ display: 'flex', flexDirection: 'column' }}>
      <CardStyleButton
        style={{ padding: 20, maxWidth: 'none', marginRight: 10 }}
      >
        <Typography variant="h5" gutterBottom>
          Endereço
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              value={zipcode}
              onChange={(event) => setzipcode(event.target.value)}
              required
              id="zip"
              name="zip"
              label="CEP"
              onBlur={getZipCode}
              fullWidth
              helperText={zipcodeValidate && 'Campo Obrigatório.'}
              error={zipcodeValidate}
              // autoComplete="shipping postal-code"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              required
              value={street}
              onBlur={() => console.log(city, state)}
              onChange={(event) => setstreet(event.target.value)}
              id="street-address"
              name="street-address"
              label="Rua"
              fullWidth
              helperText={streetValidate && 'Campo Obrigatório.'}
              error={streetValidate}
              // autoComplete="shipping street-address"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextField
              required
              value={number}
              onChange={(event) => setnumber(event.target.value)}
              id="number"
              name="number"
              label="Número"
              fullWidth
              helperText={numberValidate && 'Campo Obrigatório.'}
              error={numberValidate}
              // autoComplete="shipping"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextField
              required
              value={complement}
              onChange={(event) => setcomplement(event.target.value)}
              id="complement"
              name="complement"
              label="Complemento"
              fullWidth
              helperText={complementValidate && 'Campo Obrigatório.'}
              error={complementValidate}
              // autoComplete="shipping"
            />
          </Grid>

          <Grid item xs={6} sm={12}>
            <TextField
              required
              value={district}
              onChange={(event) => setdistrict(event.target.value)}
              id="district"
              name="district"
              label="Bairro"
              fullWidth
              helperText={districtValidate && 'Campo Obrigatório.'}
              error={districtValidate}
              // autoComplete="shipping address-level4"
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">UF</InputLabel>
              <Select
                native
                value={stateList?.name}
                onChange={handleChangeState}
                onBlur={getCity}
                inputProps={{
                  id: 'id',
                  name: 'name',
                  uf: 'uf',
                }}
              >
                <option aria-label="None" value="" />
                {stateList &&
                  stateList.map((state) => (
                    <option key={state.name + 1} value={state?.id}>
                      {state?.uf}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={3}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">Cidade</InputLabel>
              <Select
                native
                value={cityList?.name}
                onChange={handleChangeCity}
                inputProps={{
                  id: 'id',
                  name: 'name',
                }}
              >
                <option aria-label="None" value="" />
                {cityList &&
                  cityList.map((city) => (
                    <option key={city.name + 1} value={city?.id}>
                      {city?.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={phone}
              onChange={(event) => setphone(event.target.value)}
              id="phone"
              name="phone"
              label="Telefone Para Agendamento"
              fullWidth
              autoComplete="shipping tel-national"
              helperText={phoneValidate && 'Campo Obrigatório.'}
              error={phoneValidate}
            />
          </Grid>
        </Grid>
      </CardStyleButton>
      <>
        <form noValidate onSubmit={validateForm}>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
            >
              Finalizar
            </Button>
          </div>
        </form>
      </>
    </ContainerCardForm>
  );
}
