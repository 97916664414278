import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 665px) {
    justify-content: space-between;
    align-items: center;
    
  }
  
`

export const MenuLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 0px 20px;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const ContainerLogo = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  
  @media (max-width: 1024px) {
    justify-content: space-between;
  }

`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 0px 0px 20px;
  a:first-child {
    margin-right: 20px;
  }

  @media (max-width: 1024px) {
    display: none;
  }

`

export const ContainerDesktop = styled.div`
  align-items: center;
  display: flex;
  @media (max-width: 1024px) {
    display: none;
  }
`

export const ContainerMobile = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`
