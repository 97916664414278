import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import Footer from '../../../components/footer';

export default function DefaultLayout ({ children }) {
  return (
    <Wrapper>
      {children}
      <Footer />
    </Wrapper>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
}
