import React from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import ExitToApp from '@material-ui/icons/ExitToApp'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Logo from '../../../assets/images/logo-full.png'
import { Colors } from '../../../theme/index'
import { LogoImg, Icon, StyledAppBar as AppBar } from './styles'
import { useDispatch } from 'react-redux'
import { signOut } from '../../../store/modules/auth/actions'

const drawerWidth = 240

export default function MiniDrawer ({ children, menuItems }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(true)
  const [openNavigate, setOpenNavigate] = React.useState(false)
  const history = useHistory()

  const handleDrawerOpenClose = () => {
    setOpen(!open)
    open && setOpenNavigate(false)
  }
  const handleNavigate = (route) => {
    history.push(route)
  }

  const handleClick = () => {
    setOpenNavigate(!openNavigate)
  }

  const logout = () => {
    dispatch(signOut(history))
  }

  const TypeItemMenu = ({ item }) => {
    console.log('!item?.SubItems', item?.subItems)
    if (!item?.subItems) {
      return (
        <ListItem
          button
          key={item.route}
          onClick={() => {
            handleNavigate(item.route)
          }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />

        </ListItem>
      )
    } else {
      return (
        <>
          <ListItem button key={item.route} onClick={handleClick}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary='Dados' />
            {openNavigate ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openNavigate} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {item?.subItems && item?.subItems.map((subItems, i) =>
                <ListItem key={i} button onClick={() => { handleNavigate(subItems.route) }} className={classes.nested}>
                  <ListItemText inset primary={subItems.name} />
                </ListItem>
              )}
            </List>
          </Collapse>
        </>
      )
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpenClose}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap>
            Bem vindo
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        {/**
         * conteudo do topo do header
         */}
        <div className={classes.toolbar}>
          <LogoImg src={Logo} alt='logo guia doctor' />
          <IconButton onClick={handleDrawerOpenClose}>
            <Icon color='default' />
          </IconButton>
        </div>

        <Divider />

        {/**
         * conteudo do menu
         */}

        <List>
          {menuItems.map((item, index) =>
            <TypeItemMenu key={item.route} item={item} />
          )}
          <ListItem
            button
            onClick={() => {
              logout()
            }}
          >
            <ListItemIcon><ExitToApp /></ListItemIcon>
            <ListItemText primary='Sair' />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div style={{ marginTop: '10vh' }}>
          {/**
           * conteudo da pagina
           */}
          {children}
        </div>
      </main>
    </div>
  )
}

/**
 * estilização do componente
 */

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > .fa': {
      margin: theme.spacing(2)
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: Colors.new_primary,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}))
