import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: ${({colorButton}) => colorButton ? colorButton : '#264191'};
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: ${({colorText}) => colorText ? colorText : '#fff'};
  border-radius: 5px;
  align-self: center;
  margin-top: 1rem;
  cursor: pointer;

  img{
    margin-right: 5px;
  }

`;  

export const ButtonContainerBorder = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#fff'};
  font-size: 18px;
  font-weight: bold;
  color: ${({colorText}) => colorText ? colorText : '#fff'};
  align-self: center;
  margin-top: 1rem;
  cursor: pointer;

  img{
    margin-right: 5px;
  }

`;  

