/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import api from '../../../../services/api'

import Rating from '@material-ui/lab/Rating'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import {
  Container,
  Content,
  List,
  Item,
  Text,
  CommentContainer,
  TitleComment
} from './styles'

function Feedback () {
  const { id, type_user, is_public } = useSelector((state) => state.auth)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getRateFromApi = async () => {
    try {
      setLoading(true)
      const response = await api.get(`ratings?user_id=${id}`)
      const ratings = response.data.ratings.map((item) => {
        return {
          formattedDate: new Date(item.created_at).toLocaleDateString('pt-BR', {
            timeZone: 'UTC'
          }),
          ...item
        }
      })
      setData(ratings)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getRateFromApi()
  }, [])

  return (
    <Container>
      <Content>
        {data.map((item) => (
          <List key={item.id}>
            <Item>
              <p
                style={{
                  padding: '5px 0px',
                  color: 'lightgray'
                }}
              >
                enviado em {item.formattedDate}
              </p>
              <Text>{is_public?'PAGAMENTO EM DIA: ':'PONTUALIDADE NO ATENDIMENTO:'}</Text>
              <Rating
                name='customized-empty'
                defaultValue={item.punctuality_service}
                precision={0.5}
                readOnly
                emptyIcon={<StarBorderIcon fontSize='inherit' />}
              />
            </Item>
            <Item>
              <Text>{is_public?'ADEQUADA OFERTA DE MEDICAMENTOS':'ESTRUTURA DO CONSULTÓRIO'}:</Text>
              <Rating
                name='customized-empty'
                defaultValue={item.structure_office}
                precision={0.5}
                readOnly
                emptyIcon={<StarBorderIcon fontSize='inherit' />}
              />
            </Item>
            <Item>
              <Text>{is_public?'ESTRUTURA DA UNIDADE':'CONDUTA E ATENÇÃO AO PACIENTE:'}</Text>
              <Rating
                name='customized-empty'
                defaultValue={item.conduct_attention_patient}
                precision={0.5}
                readOnly
                emptyIcon={<StarBorderIcon fontSize='inherit' />}
              />
            </Item>
            <Item>
              <Text>{is_public?'SEGURANÇA PESSOAL':'HABILIDADE TÉCNICA:'}</Text>
              <Rating
                name='customized-empty'
                defaultValue={item.technical_ability}
                precision={0.5}
                readOnly
                emptyIcon={<StarBorderIcon fontSize='inherit' />}
              />
            </Item>
            <Item>
              <Text>{is_public?'NÚMERO DE ATENDIMENTOS COMPATIVEL COM A ESTRUTURA':'RELAÇÃO ENTRE O QUE FOI DITO E O RESULTADO OBTIDO:'}</Text>
              <Rating
                name='customized-empty'
                defaultValue={item.result_obtained}
                precision={0.5}
                readOnly
                emptyIcon={<StarBorderIcon fontSize='inherit' />}
              />
            </Item>

            {type_user === 'clinic' && !is_public && (
              <>
                <Item>
                  <Text>RAPIDEZ NA ENTREGA DO EXAME:</Text>
                  <Rating
                    name='customized-empty'
                    defaultValue={item.fast_delivery}
                    precision={0.5}
                    readOnly
                    emptyIcon={<StarBorderIcon fontSize='inherit' />}
                  />
                </Item>
                <Item>
                  <Text>QUALIDADE DO EXAME:</Text>
                  <Rating
                    name='customized-empty'
                    defaultValue={item.exam_quality}
                    precision={0.5}
                    readOnly
                    emptyIcon={<StarBorderIcon fontSize='inherit' />}
                  />
                </Item>
              </>
            )}

            {item.description && (
              <CommentContainer>
                <TitleComment>Comentário</TitleComment>
                <Text>{item.description}</Text>
              </CommentContainer>
            )}
          </List>
        ))}
        {!loading && data.length <= 0 && (
          <List>
            <Text>Ainda não existem avaliações</Text>
          </List>
        )}
        {loading && (
          <List>
            <Text>Carregando avaliações</Text>
          </List>
        )}
      </Content>
    </Container>
  )
}

export default Feedback
