import React, { useState } from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import AddressPage from './addressPage';
import CardPage from './cardData';
import ConfirmPayment from './confirmPayment';

import { Card, Title, StepperContainer } from './styles';

function getSteps() {
  return ['Dados de Endereço', 'Dados de Cartão', 'Finalizar adesão'];
}

function PaymentPage() {
  const [address, setAddress] = useState({
    street: '',
    number: '',
    complement: '',
    zipcode: '',
    district: '',
    city: '',
    state: '',
  });
  const [creditCard, setCreditCard] = useState({
    cvc: '',
    expiryMonth: '',
    expiryYear: '',
    name: '',
    number: '',
    cpf: '',
    phone: '',
    birth_date: '',
    card_brand: '',
  });

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNextStep = () => {
    if (activeStep < 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handlePrevStep = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <Card>
      <Title>Página de Adesão de plano</Title>

      <StepperContainer>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </StepperContainer>

      {activeStep === 0 && (
        <AddressPage
          next={handleNextStep}
          handleData={setAddress}
          data={address}
        />
      )}
      {activeStep === 1 && (
        <CardPage
          next={handleNextStep}
          prev={handlePrevStep}
          handleData={setCreditCard}
          data={creditCard}
        />
      )}
      {activeStep === 2 && (
        <>
          <ConfirmPayment
            prev={handlePrevStep}
            handleData={() => {}}
            card={creditCard}
            address={address}
          />
        </>
      )}
    </Card>
  );
}

export default PaymentPage;
