import styled from 'styled-components';
import { Card as MCard, Avatar as MAvatar } from '@material-ui/core';
import { Colors } from '../../../theme';

import Grid from '@material-ui/core/Grid';

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 82vh;
`;

export const Content = styled(MCard)`
  overflow-y: scroll;
  width: 100%;
  height: 90vh;
  padding: 2vw 5vw;
`;

export const Card = styled(MCard)`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 10px;
  margin-top: 30px;
`;

export const Title = styled.h3``;

export const Avatar = styled(MAvatar)`
  width: 100px;
  height: 100px;
`;

export const Button = styled.button`
  width: 30%;
  padding: 10px 5px;
  margin: 10px;
  background-color: ${Colors.lightblue01};
  color: ${Colors.white};
  border: 1px solid ${Colors.lightblue01};
  border-radius: 5px;

  transition: all 0.3s ease;

  :hover {
    background-color: ${Colors.white};
    color: ${Colors.lightblue01};
    border: 1px solid ${Colors.lightblue01};
  }
`;

export const Separator = styled.div`
  margin: 10px 0px;
  border-top: 1px solid ${Colors.gray02};
  width: 95%;
  padding-top: 10px;
`;

export const InfoButtonGroup = styled(Grid)`
  margin: 20px 23px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InfoButton = styled(Grid)`
  button {
    width: 15rem;
    height: 40px;
    border: none;
    border-radius: 5px;
    padding: 1px 10px;
    background-color: ${(props) =>
      props.active ? Colors.new_primary : Colors.white};
    color: ${(props) => (props.active ? Colors.white : Colors.new_primary)};
    border: ${(props) =>
      props.active ? 'none' : `1px solid ${Colors.gray03}`};
  }
`;

export const CardStyleButton = styled(Card)`
  padding: 0px;
  display: flex;
  flex: 1;
  margin: 10px;
  max-width: 48%;
  button {
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    span {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }
  h5 {
    text-align: left;
  }
  h4 {
    font-weight: 400;
    text-align: left;
  }
`;


export const ContainerCardForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
`
export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`
