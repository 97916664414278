import React, { useEffect, useState } from 'react';
import CardInfo from '../../../components/cardInfo';
import GeneralInformation from './GeneralInformation';
import MedicalInsurance from './MedicalInsurance';
import AddressInformation from './AddressInformation';
import {
  BodyCard,
  Container,
  ContainerCard,
  HeaderCard,
  SubTitleCard,
  TitleCard,
} from './styles';
import MenuTab from './menuTab';
import ContainerContent from '../../../components/container';
import api from '../../../services/api';
import { useParams } from 'react-router';

import VideoPlayer from '../../../components/videoPlayer';
function ProfileDetails() {
  let { id, name } = useParams();

  const [user, setuser] = useState(``);
  const [select, setselect] = useState(0);

  const getUserDetail = async () => {
    try {
      const response = await api.get(`users?id=${id}`);
      console.log(`response`, response);
      const { user: userData } = response.data;
      setuser(userData);
    } catch (error) {
      console.log(`error`, error);
    }
  };

  const RenderPage = () => {
    if (select === 0) {
      return <GeneralInformation user={user} />;
    } else if (select === 1) {
      return <MedicalInsurance user={user} />;
    } else if (select === 2) {
      return <AddressInformation user={user} />;
    } else if (select === 3) {
      return <VideoPlayer link={user?.member?.youtube_link} />;
    } else {
      return <></>;
    }
    // if (select == 1) {
    //   return <GeneralInformation user={user}/>;
    // }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <Container>
      <ContainerContent>
        <CardInfo user={user} />

        <ContainerCard style={{ marginBottom: 0 }}>
          <MenuTab select={select} setselect={setselect} />
        </ContainerCard>

        <RenderPage />
      </ContainerContent>
    </Container>
  );
}

export default ProfileDetails;
