import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Modal from '../../../components/modal';
import Edit from '@material-ui/icons/VpnKey';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';

import { getAllClinicsRequest } from '../../../store/modules/clinic/actions';

import {
  Container,
  Content,
  Title,
  Header,
  Icon,
  BackButton,
  CardProfile,
  InfoDoctor,
  ActiveProfileButton,
  ProfileContainer,
  InfoButtonGroup,
  InfoButton,
  PasswordResetButton,
  FormContainer,
  ButtonDetail,
} from './styles';

import api from '../../../services/api';

import InformationWrapper from './informationWrapper';
import About from './about';
import Feedback from './feedback';
import Document from './document';

function DetailDoctor() {
  const [activeButton, setActiveButton] = useState('info');
  const [user, setUser] = useState();
  const [specialties, setSpecialties] = useState([]);
  const [crm, setCrm] = useState([]);
  const [plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);

  const [passData, setPassData] = useState({
    password: '',
    password_confirmation: '',
  });

  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBack = () => {
    history.push('/list-clinics');
  };
  const handleInfoButton = (text) => {
    setActiveButton(text);
  };
  const handleStatus = (status) => {
    let result;
    switch (status) {
      case 'pending':
        result = 'PENDENTE';
        break;
      case 'pending_payment':
        result = 'PAGAMENTO PENDENTE';
        break;
      case 'approved':
        result = 'APROVADO';
        break;
      default:
        break;
    }
    return result;
  };

  const getDoctorData = async () => {
    const response = await api.get(`users?id=${id}`);
    setUser(response.data.user);
    console.log(response);
    handleSpecialties(response.data.user.specialties);
    handleCrmData(response.data.user.member);
    handlePlans(response.data.user.medical_health_plans);
  };

  const handleDisableDoctor = async () => {
    try {
      await api.put(`users/${id}/change_status`);
      getDoctorData();
      // console.tron.log(response);
      toast.success('Status de clinica alterado com sucesso!');
      dispatch(getAllClinicsRequest());
    } catch (error) {
      toast.error('Aconteceu algo inesperado!');
    }
  };

  const handleCrmData = (member) => {
    const tableContent = [
      {
        value1: member?.crm,
        value2: member?.crm_uf?.name,
      },
    ];

    setCrm(tableContent);
  };

  const handleSpecialties = (specialtiesData) => {
    const specialties = specialtiesData.map((item) => {
      return {
        value1: item?.medical_specialty?.name,
        value2: item?.rqe,
      };
    });

    setSpecialties(specialties);
  };
  const handlePlans = (plansData) => {
    const newPlans = plansData.map((item) => {
      return {
        value1: item.id,
        value2: item?.health_plan.name,
      };
    });

    setPlans(newPlans);
  };

  const handleTextPass = (name, value) => {
    setPassData({
      ...passData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      if (passData.password_confirmation === '' || passData.password === '') {
        toast.warn('Preencha todos os campos');
        return;
      }
      if (passData.password_confirmation !== passData.password) {
        toast.warn('Os valores não batem');
        return;
      }
      await api.put(`users/${user.id}/change_password_admin`, passData);
      toast.success('Senha alterada com sucesso');
      setPassData({ password: '', password_confirmation: '' });
      setOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg);
    }
  };

  useEffect(() => {
    getDoctorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Header>
        <BackButton onClick={handleBack}>
          <Icon />
          <p>Voltar</p>
        </BackButton>
        <Title>Dados de {user?.member?.name}</Title>
        <PasswordResetButton onClick={() => setOpen(true)}>
          <Edit />
          Alterar senha
        </PasswordResetButton>
      </Header>
      {/** informaçoes */}
      <Content maxWidth="lg">
        {/**card de perfil */}
        <CardProfile>
          <ProfileContainer>
            <Avatar
              style={{ width: 80, height: 80, marginRight: '20px' }}
              alt="Foto do médico"
              src={`${user?.member?.profile_image}`}
            />
            <InfoDoctor status={user?.status === 'approved'}>
              <h2>{user?.member?.name}</h2>
              <p>{user?.email}</p>
              <p>{user?.member?.personal_phone}</p>
              <p className="status-info">{handleStatus(user?.status)}</p>
            </InfoDoctor>
          </ProfileContainer>
          <ActiveProfileButton
            status={user?.status === 'approved'}
            onClick={handleDisableDoctor}
          >
            {user?.status === 'approved' ? 'Reprovar Perfil' : 'Aprovar Perfil'}
          </ActiveProfileButton>
        </CardProfile>

        <InfoButtonGroup container spacing={1} style={{ paddingTop: '10px' }}>
          {/**botoes de navegação de informaçao */}
          <InfoButton active={activeButton === 'info'} item xg={4}>
            <button onClick={() => handleInfoButton('info')}>
              Informaçoes Gerais
            </button>
          </InfoButton>
          <InfoButton item xg={4} active={activeButton === 'about'}>
            <button onClick={() => handleInfoButton('about')}>Sobre</button>
          </InfoButton>
          <InfoButton item xg={4} active={activeButton === 'docs'}>
            <button onClick={() => handleInfoButton('docs')}>Documentos</button>
          </InfoButton>
          <InfoButton item xg={4} active={activeButton === 'feedback'}>
            <button onClick={() => handleInfoButton('feedback')}>
              Avaliações
            </button>
          </InfoButton>
        </InfoButtonGroup>
        {activeButton === 'info' && (
          <>
            <InformationWrapper
              title="CRM"
              tableTitles={['CRM', 'Estado']}
              data={crm}
            />
            {specialties.length > 0 && (
              <InformationWrapper
                title="Especialidades"
                tableTitles={['Especialidade', 'RQE']}
                data={specialties}
              />
            )}
            {plans.length > 0 && (
              <InformationWrapper
                title="Planos de Saúde"
                tableTitles={['id', 'Plano']}
                data={plans}
              />
            )}
          </>
        )}
        {activeButton === 'about' && <About member={user.member} />}
        {activeButton === 'feedback' && <Feedback id={id} />}
        {activeButton === 'docs' && <Document user={user} />}
      </Content>
      {open && (
        <Modal
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          title="Alteração de senha"
        >
          <FormContainer>
            <TextField
              id="outlined-basic"
              label="Nova senha"
              variant="outlined"
              value={passData.password}
              onChange={(e) => handleTextPass('password', e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Confirme a Nova senha"
              variant="outlined"
              value={passData.password_confirmation}
              onChange={(e) =>
                handleTextPass('password_confirmation', e.target.value)
              }
            />

            <ButtonDetail size="large" color="error" onClick={handleSubmit}>
              Alterar senha de clinica
            </ButtonDetail>
          </FormContainer>
        </Modal>
      )}
    </Container>
  );
}

export default DetailDoctor;
