import styled from 'styled-components';

export const Container = styled.div``;

export const SpanValidation = styled.span``;

export const ButtonShowPass = styled.button`
  margin-left: 95%;
  background-color: transparent;
`;
