import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import Modal from '../../../components/modal';

import EditAttributesOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import PlusOne from '@material-ui/icons/Add';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import {
  Container,
  Content,
  Title,
  TextName,
  ButtonDetail,
  FormContainer,
} from './styles';

function ListDoctors() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [especialties, setEspecialties] = useState([]);
  const [action, setAction] = useState({
    title: '',
    screen: '',
    id: 0,
  });

  const [especialty, setEspecialty] = useState('');

  // seta qual modal vai abrir e quais dados serao usados
  const handleModal = (title, screen, id = 0, content = '') => {
    setEspecialty(content);
    setOpen(true);
    setAction({
      title,
      screen,
      content,
      id,
    });
  };
  // fechar modal
  const handleClose = () => setOpen(false);

  // busca de lista na api
  const getEspecialties = async () => {
    setLoading(true);
    const response = await api.get('medical_specialtys');
    setEspecialties(response.data.medical_specialties);
    setLoading(false);
    // console.log('especialties', response);
  };

  // função de criação de especialidade
  const handleSubmit = async () => {
    try {
      if (!especialty) {
        toast.warn('Digite o nome da especialidade');
        return;
      }
      const response = await api.post('medical_specialtys', {
        medical_specialty: {
          name: especialty,
        },
      });
      if (response.status === 201) {
        toast.success('Cadastrado com sucesso');
        setEspecialty('');
        setOpen(false);
        getEspecialties();
      }
    } catch (error) {}
  };

  // função de atualização de especialidade
  const handleUpdate = async () => {
    try {
      if (!especialty) {
        toast.warn('Digite o nome da especialidade');
        return;
      }
      const response = await api.put(`medical_specialtys/${action.id}`, {
        medical_specialty: {
          name: especialty,
        },
      });
      //    console.log(response);
      if (response.status === 200) {
        toast.success('Atualizado com sucesso');
        setAction({ title: '', screen: '', id: 0, content: '' });
        setEspecialty('');
        setOpen(false);
        getEspecialties();
      }
    } catch (error) {}
  };
  // função de atualização de especialidade
  const handleDelete = async () => {
    try {
      const response = await api.delete(`medical_specialtys/${action.id}`);
      // console.log(response);

      if (response.status === 204) {
        toast.success('Excluido com sucesso');
        setAction({ title: '', screen: '', id: 0, content: '' });
        setEspecialty('');
        setOpen(false);
        getEspecialties();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getEspecialties();
  }, []);

  return (
    <Container>
      {/** cadastrar nova especialidade */}
      {open && action.screen === 'create' && (
        <Modal open={true} handleClose={handleClose} title={action.title}>
          <FormContainer>
            <TextField
              id="outlined-basic"
              label="Nome da especialidade"
              variant="outlined"
              value={especialty}
              onChange={(e) => setEspecialty(e.target.value)}
            />

            <ButtonDetail onClick={handleSubmit}>
              Cadastrar Especialidade
            </ButtonDetail>
          </FormContainer>
        </Modal>
      )}

      {/** atualizar especialidade */}
      {open && action.screen === 'edit' && (
        <Modal open={true} handleClose={handleClose} title={action.title}>
          <FormContainer>
            <TextField
              id="outlined-basic"
              label="Nome da especialidade"
              variant="outlined"
              value={especialty}
              onChange={(e) => setEspecialty(e.target.value)}
            />

            <ButtonDetail onClick={handleUpdate}>
              Atualizar Especialidade
            </ButtonDetail>
          </FormContainer>
        </Modal>
      )}
      {/** excluir especialidade */}
      {open && action.screen === 'delete' && (
        <Modal open={true} handleClose={handleClose} title={action.title}>
          <FormContainer>
            <TextField
              id="outlined-basic"
              label="Nome da especialidade"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              value={especialty}
              onChange={(e) => setEspecialty(e.target.value)}
            />

            <ButtonDetail onClick={handleDelete} color="error">
              Excluir Especialidade
            </ButtonDetail>
          </FormContainer>
        </Modal>
      )}

      {/** listagem de medicos */}
      <Title>Especialidades</Title>
      <ButtonDetail
        onClick={() => handleModal('Adicionar nova especialidade', 'create')}
        size="large"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <PlusOne />
        Adicionar Especialidade
      </ButtonDetail>

      <TableContainer component={Paper}>
        <Table className={'table'} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>

              <TableCell align="center">Açoes</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <Content>
        <TableContainer component={Paper}>
          <Table className={'table'} aria-label="simple table">
            <TableBody>
              {loading ? (
                <TableRow key={1} hover>
                  <TableCell component="th" scope="row" align="center">
                    <TextName>Carregando</TextName>
                  </TableCell>
                </TableRow>
              ) : (
                especialties?.map((item) => (
                  <TableRow key={item.id} hover>
                    <TableCell component="th" scope="row" align="center">
                      <TextName>{item.name}</TextName>
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        padding: 0,
                        margin: 0,
                        flexDirection: 'row',
                      }}
                    >
                      <ButtonDetail
                        onClick={() =>
                          handleModal(
                            'Atualizar Especialidade',
                            'edit',
                            item.id,
                            item.name
                          )
                        }
                      >
                        <EditAttributesOutlinedIcon />
                      </ButtonDetail>
                      <ButtonDetail
                        onClick={() =>
                          handleModal(
                            'Excluir Especialidade',
                            'delete',
                            item.id,
                            item.name
                          )
                        }
                        color="error"
                      >
                        <DeleteForeverOutlined />
                      </ButtonDetail>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Content>
    </Container>
  );
}

export default ListDoctors;

/**
 * 
 * 
 *  
 * 
 <TextField id="outlined-basic" label="RQE" variant="outlined" />
 <TableCell align="center">RQE</TableCell> 
<TableCell align="center">
                      <TextName>35246</TextName>
                    </TableCell>

 */
