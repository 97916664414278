import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ContainerContent from '../../../components/container';
import Header from '../../../components/header';
import { Container } from './styles';
import { getDoctorsToOpenRoutes } from '../../../store/modules/doctor/actions';

export default function TermsOfUse() {
  const history = useHistory();
  const dispatch = useDispatch();

  const backToMain = () => history.push('/');

  const goToSearch = () => {
    dispatch(
      getDoctorsToOpenRoutes({
        city: null,
        home_consultation: null,
        personal_consultation: null,
        plan: null,
        searchParam: null,
      })
      /*`/pesquisar${citysSelects}/searchParam=${
        searchParam == '' ? ' ' : searchParam
      }${plans}${personal_consultation}${home_consultation}`
      */
    );

    history.push(`/pesquisar`);
  };

  return (
    <>
      <Header
        goToAboutRef={backToMain}
        goToPricetRef={backToMain}
        goToContactRef={backToMain}
        goToDoctorRate={backToMain}
        goToSearch={goToSearch}
      />
      <ContainerContent>
        <Container>
          <div>
            <h1>Política de privacidade para</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              lacinia lacus lacus. Pellentesque consectetur dignissim arcu, eget
              tincidunt ex eleifend nec. Proin tempus metus vitae finibus
              interdum. Nulla porta orci vel nibh rutrum dignissim. Maecenas vel
              ultrices ligula. Curabitur nibh tellus, sagittis quis erat et,
              tincidunt cursus purus. Nunc at quam ut eros euismod condimentum.
              Donec eget enim placerat, imperdiet quam sed, facilisis erat.
              Donec sed tellus sapien. Nulla vulputate quam a tortor
              consectetur, non consectetur nulla auctor. Donec fringilla diam
              lectus, vitae malesuada nunc dapibus at. Mauris porttitor urna
              dui.
            </p>
          </div>

          <div>
            <h1>Os anúncios</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              lacinia lacus lacus. Pellentesque consectetur dignissim arcu, eget
              tincidunt ex eleifend nec. Proin tempus metus vitae finibus
              interdum. Nulla porta orci vel nibh rutrum dignissim. Maecenas vel
              ultrices ligula. Curabitur nibh tellus, sagittis quis erat et,
              tincidunt cursus purus. Nunc at quam ut eros euismod condimentum.
              Donec eget enim placerat, imperdiet quam sed, facilisis erat.
              Donec sed tellus sapien. Nulla vulputate quam a tortor
              consectetur, non consectetur nulla auctor. Donec fringilla diam
              lectus, vitae malesuada nunc dapibus at. Mauris porttitor urna
              dui.
            </p>
          </div>

          <div>
            <h1>Os Cookies e Web Beacons</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              lacinia lacus lacus. Pellentesque consectetur dignissim arcu, eget
              tincidunt ex eleifend nec. Proin tempus metus vitae finibus
              interdum. Nulla porta orci vel nibh rutrum dignissim. Maecenas vel
              ultrices ligula. Curabitur nibh tellus, sagittis quis erat et,
              tincidunt cursus purus. Nunc at quam ut eros euismod condimentum.
              Donec eget enim placerat, imperdiet quam sed, facilisis erat.
              Donec sed tellus sapien. Nulla vulputate quam a tortor
              consectetur, non consectetur nulla auctor. Donec fringilla diam
              lectus, vitae malesuada nunc dapibus at. Mauris porttitor urna
              dui.
            </p>
          </div>

          <div>
            <h1>Ligações a Sites de terceiros</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              lacinia lacus lacus. Pellentesque consectetur dignissim arcu, eget
              tincidunt ex eleifend nec. Proin tempus metus vitae finibus
              interdum. Nulla porta orci vel nibh rutrum dignissim. Maecenas vel
              ultrices ligula. Curabitur nibh tellus, sagittis quis erat et,
              tincidunt cursus purus. Nunc at quam ut eros euismod condimentum.
              Donec eget enim placerat, imperdiet quam sed, facilisis erat.
              Donec sed tellus sapien. Nulla vulputate quam a tortor
              consectetur, non consectetur nulla auctor. Donec fringilla diam
              lectus, vitae malesuada nunc dapibus at. Mauris porttitor urna
              dui.
            </p>
          </div>
        </Container>
      </ContainerContent>
    </>
  );
}
