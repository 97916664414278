import React from 'react'
import Card from '../../../../components/card';
import { BodyCard, ContainerCard, HeaderCard, SubTitleCard, TitleCard } from '../styles';

export default function MedicalInsurance({user}) {
  return (
    <>
      {user?.medical_health_plans && 
        <ContainerCard>
          <Card>
            <HeaderCard>
              <TitleCard>Convênios</TitleCard>
            </HeaderCard>
            <BodyCard>
              {user?.medical_health_plans.map((med, index) => 
                <SubTitleCard key={index}>
                  {med?.health_plan?.name}
                </SubTitleCard>
              )}
            </BodyCard>
          </Card>
        </ContainerCard>
      }
    </>
  )
}
