export function getAllDoctorsRequest({ history }) {
  return {
    type: '@doctor/GET_ALL_DOCTORS_LIST_REQUEST',
    payload: { history },
  };
}
export function getAllDoctorsSuccess({
  doctors,
  pending_payment,
  pending,
  cancelled,
}) {
  return {
    type: '@doctor/GET_ALL_DOCTORS_LIST_SUCCESS',
    payload: { doctors, pending_payment, pending, cancelled },
  };
}

export function getDoctorsToOpenRoutes({
  city,
  searchParam,
  personal_consultation,
  home_consultation,
  plan,
  crm,
}) {
  return {
    type: '@doctor/GET_DOCTORS_TO_OPEN_ROUTES',
    payload: {
      city,
      searchParam,
      personal_consultation,
      home_consultation,
      plan,
      crm,
    },
  };
}
