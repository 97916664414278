import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
// import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import api from '../../../../services/api';

import Modal from '../../../../components/modal';

import EditAttributesOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import PlusOne from '@material-ui/icons/Add';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import {
  Container,
  Content,
  Title,
  TextName,
  ButtonDetail,
  FormContainer,
} from './styles';

function EspecialtiesForm({ user }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exams, setExams] = useState([]);

  const [types, setTypes] = useState({});
  const [selected, setSelected] = useState(0);

  const [action, setAction] = useState({
    title: '',
    screen: '',
    id: 0,
  });

  const [exam, setExam] = useState('');
  const history = useHistory();

  // seta qual modal vai abrir e quais dados serao usados
  const handleModal = (title, screen, id = 0, content = '') => {
    setExam(content);
    setOpen(true);
    setAction({
      title,
      screen,
      content,
      id,
    });
  };
  // fechar modal
  const handleClose = () => setOpen(false);

  // busca de lista na api
  const getExams = async () => {
    setLoading(true);
    const [response2] = await Promise.all([api.get('medical_specialtys')]);

    setExams(user.specialties);
    setTypes(response2.data.medical_specialties);

    //  console.log(user.specialties);

    setLoading(false);
    // console.log('especialties', response);
  };

  // controle do seletor
  const handleSelect = ({ target }) => {
    setSelected(target.value);
  };

  // função de criação de plano de saude
  const handleSubmit = async () => {
    try {
      if (!exam) {
        toast.warn('Digite o RQE');
        return;
      }
      const type = user.role === 'clinic' ? 'clinic' : 'doctor';

      const response = await api.patch(`users/${type}?id=${user.id}`, {
        user: {
          specialties_attributes: [
            {
              rqe: exam,
              medical_specialty_id: selected,
            },
          ],
        },
      });

      //  console.log(response, '-----------------');

      toast.success('Cadastrado com sucesso');
      history.push('/profile');
    } catch (error) {
      toast.warn('Algo inesperado aconteceu');
    }
  };

  // função de deleçao de especialidade
  const handleDelete = async () => {
    try {
      if (!exam) {
        toast.warn('Digite o RQE');
        return;
      }
      const type = user.role === 'clinic' ? 'clinic' : 'doctor';

      const response = await api.patch(`users/${type}?id=${user.id}`, {
        user: {
          specialties_attributes: [
            {
              id: action.id,
              _destroy: true,
            },
          ],
        },
      });

      //  console.log(response, '-----------------');

      toast.success('Deletado com sucesso');
      history.push('/profile');
    } catch (error) {
      toast.warn('Algo inesperado aconteceu');
    }
  };

  useEffect(() => {
    getExams();
  }, []);

  return (
    <Container>
      {/** cadastrar nova especialidade */}
      {open && action.screen === 'create' && (
        <Modal open={true} handleClose={handleClose} title={action.title}>
          <FormContainer>
            <TextField
              id="outlined-basic"
              label="RQE"
              variant="outlined"
              value={exam}
              type="number"
              onChange={(e) => setExam(e.target.value)}
            />
            <InputLabel id="demo-simple-select-label">Especialidade</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected}
              onChange={handleSelect}
            >
              {types.map((item) => (
                <MenuItem value={item.id} name={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>

            <ButtonDetail onClick={handleSubmit}>
              Cadastrar Especialidade
            </ButtonDetail>
          </FormContainer>
        </Modal>
      )}

      {/** excluir plano de saude */}
      {open && action.screen === 'delete' && (
        <Modal open={true} handleClose={handleClose} title={action.title}>
          <FormContainer>
            <TextField
              id="outlined-basic"
              label="Nome do Exame"
              variant="outlined"
              value={exam}
            />
            <ButtonDetail onClick={handleDelete} color="error">
              Excluir Especialidade
            </ButtonDetail>
          </FormContainer>
        </Modal>
      )}

      {/** listagem de medicos */}
      {exams.length > 0 && <Title>Especialidades</Title>}
      {/** botao criar exame */}
      <ButtonDetail
        onClick={() => handleModal('Adicionar nova especialidade', 'create')}
        size="large"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PlusOne />
        Adicionar especialidade
      </ButtonDetail>

      {exams.length <= 0 && (
        <TextName>Nenhuma especialidade cadastrada</TextName>
      )}

      {exams.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={'table'} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">nome</TableCell>
                <TableCell align="center">RQE</TableCell>

                <TableCell align="center">Açoes</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}

      {exams.length > 0 && (
        <Content>
          <TableContainer component={Paper}>
            <Table className={'table'} aria-label="simple table">
              <TableBody>
                {exams?.map((item) => (
                  <TableRow key={item.id} hover>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{
                        width: 300,
                      }}
                    >
                      <TextName>{item.medical_specialty.name}</TextName>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <TextName>{item.rqe}</TextName>
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        padding: 0,
                        margin: 0,
                        flexDirection: 'row',
                        width: 150,
                      }}
                    >
                      <ButtonDetail
                        onClick={() =>
                          handleModal(
                            'Excluir especialidade',
                            'delete',
                            item.id,
                            item.medical_specialty.name
                          )
                        }
                        color="error"
                      >
                        <DeleteForeverOutlined />
                      </ButtonDetail>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Content>
      )}
    </Container>
  );
}

export default EspecialtiesForm;

/**
 *  <TableRow key={1} hover>
            <TableCell component="th" scope="row" align="center">
             
            </TableCell>
          </TableRow>
 */
