import styled from 'styled-components'

export const Wrapper = styled.div`
  /* height: 100%; */
  /* background: linear-gradient(-90deg, #7159c1, #ab59c1); */
  display: flex;
  flex-direction: column;
  flex: 1;
  /* height: 100%; */
`
