import React from 'react';
import { useHistory } from 'react-router-dom';

import Cards from 'react-credit-cards';
import { toast } from 'react-toastify';
import { makeStyles, Button } from '@material-ui/core';

import api from '../../../services/api';
import { Card, Title, Input, InputDateDiv, ButtonContainer } from './styles';

function ConfirmPayment({ card, address, prev }) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    confirmButton: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      backgroundColor: '#27ae60',
      color: '#fff',
    },
  }));
  const history = useHistory();
  const classes = useStyles();

  const handleAsignService = async () => {
    toast.info('Iniciando solicitação de assinatura');
    const data = {
      card_number: card.number,
      card_brand: card.card_brand.toUpperCase(),
      card_cvv: card.cvc,
      card_expiration_month: card.expiryMonth,
      card_expiration_year: card.expiryYear,
      cpf: card.cpf,
      responsible_card: card.name,
      birth_date: card.birth_date,
      street: address.street,
      number: address.number,
      complement: address.complement,
      district: address.district,
      city: address.city.id,
      state: address.state.id,
      zipcode: address.zipcode,
    };
    console.log(data);
    try {
      const response = await api.post('pagseguro/signature', data);
      toast.success('Seu pagamento está sendo processado pela operadora');
      history.push('/');
    } catch (error) {
      console.log(
        '-------------------------error ----------------------------'
      );
      console.log(error);
      toast.error('Aconteceu um erro na solicitação');
    }
  };

  return (
    <Card>
      <Title>Confirme seus dados</Title>

      <section
        style={{
          marginBottom: '20px',
        }}
      >
        <Title>Dados de endereço</Title>
        <label htmlFor="">CEP</label>
        <Input value={address.zipcode} readOnly type="text" />
        <label htmlFor="">Rua</label>
        <Input value={address.street} readOnly type="text" />
        <label htmlFor="">N°</label>
        <Input value={address.number} readOnly type="text" />
        <label htmlFor="">COMPLEMENTO</label>
        <Input value={address.complement} readOnly type="text" />
        <label htmlFor="">BAIRRO</label>
        <Input value={address.district} readOnly type="text" />
        <label htmlFor="">ESTADO</label>
        <Input value={address.state.name} readOnly type="text" />
        <label htmlFor="">CIDADE</label>
        <Input value={address.city.name} readOnly type="text" />
      </section>

      <section style={{ width: '100%' }}>
        <Title>Dados de Cartão</Title>
      </section>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <section>
          <Cards
            cvc={card.cvc}
            expiry={`${card.expiryMonth}/${card.expiryYear}`}
            name={card.name}
            number={card.number}
            placeholders={{ name: 'Seu Nome aqui' }}
          />
        </section>
        <section>
          <Input value={card.number} readOnly type="text" name="number" />
          <Input value={card.card_brand} readOnly type="text" name="brand" />
          <Input value={card.name} readOnly type="text" name="name" />
          <InputDateDiv>
            <Input
              value={card.expiryMonth}
              readOnly
              type="text"
              name="expiryMonth"
            />
            <Input
              value={card.expiryYear}
              readOnly
              type="text"
              name="expiryYear"
            />
            <Input value={card.cvc} readOnly type="number" name="cvc" />
          </InputDateDiv>
          <Input value={card.cpf} readOnly type="text" name="cpf" />
          <Input value={card.phone} readOnly type="phone" name="phone" />
          <label htmlFor="">Data de nascimento do proprietário</label>
          <Input
            value={card.birth_date}
            readOnly
            type="date"
            name="birth_date"
          />
        </section>
      </div>

      <ButtonContainer className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={prev}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          className={classes.confirmButton}
          onClick={handleAsignService}
        >
          Assinar plano
        </Button>
      </ButtonContainer>
    </Card>
  );
}

export default ConfirmPayment;
