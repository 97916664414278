import styled from 'styled-components'

import FaStar from '@material-ui/icons/Star'

export const Container = styled.div`
  input[type='radio'] {
    display: none;
  }
`

export const Star = styled(FaStar)`
  color: ${(props) => props.color};
`
