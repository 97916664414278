import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import VideocamIcon from '@material-ui/icons/Videocam';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';

import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';

import {
  Card,
  Header,
  Body,
  ContainerAttr,
  CententAttr,
  ContainerAddress,
  ContainerBtn,
} from './styles';
import TextLink from '../textLink';

export default function MediaCard({ link = '', user, ...rest }) {
  // console.log('user', user);
  const history = useHistory();

  const handleRateDoctor = (role) => {
    if (role === 'clinic') {
      history.push(`/avaliar-clinica/:${user.id}`, { user });
    }else {
      history.push(`/avaliar-medico/:${user.id}`, { user });
    }
  };
  const formatPhone = (number = '') => {
    number = number.replace(/\D/g, ''); //Remove tudo o que não é dígito
    number = number.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    number = number.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
    return number;
  };

  useEffect(() => {
    formatPhone();
  });

  return (
    <>
      <Card {...rest}>
        <Header>
          <Link to={link}>
            <Avatar
              style={{ width: 80, height: 80 }}
              alt="Foto do médico"
              src={`${user?.member?.profile_image}`}
            />
          </Link>
          <div style={{ marginLeft: 10 }}>
            <TextLink to={link}>
              <h3>
                {user?.member?.fantasy_name
                  ? user?.member?.fantasy_name
                  : user?.member?.name}
              </h3>
            </TextLink>
            {user?.specialties &&
              user?.specialties.map((specialties, index) => (
                <h5>{specialties?.medical_specialty?.name}</h5>
              ))}
          </div>
        </Header>

        <Body>
          <ContainerAttr>
            {user?.member?.health_plan && (
              <CententAttr>
                <CheckCircleOutlineIcon
                  style={{ marginRight: 5 }}
                  color="primary"
                  fontSize="small"
                />
                <p>Aceita novos pacientes por plano</p>
              </CententAttr>
            )}

            {user?.member?.personal_consultation && (
              <CententAttr>
                <CheckCircleOutlineIcon
                  style={{ marginRight: 5 }}
                  color="primary"
                  fontSize="small"
                />
                <p>Aceita novos pacientes particular</p>
              </CententAttr>
            )}

            {/* <CententAttr>
              <VideocamIcon style={{marginRight: 5}} color="primary" fontSize="small" />          
              <p>Realiza atendimento online</p>
            </CententAttr> */}
          </ContainerAttr>

          {user?.addresses &&
            user?.addresses.map((address, index) => (
              <div key={index}>
                <ContainerAddress>
                  <PhoneIcon
                    style={{ marginRight: 5 }}
                    color="primary"
                    fontSize="small"
                  />
                  <div>
                    {/* <p>Centro médico hospital guia doctor</p> */}
                    <p>{address?.phone && formatPhone(address.phone)}</p>
                  </div>
                </ContainerAddress>
                <ContainerAddress>
                  <LocationOnIcon
                    style={{ marginRight: 5 }}
                    color="primary"
                    fontSize="small"
                  />
                  <div>
                    {/* <p>Centro médico hospital guia doctor</p> */}
                    <p>
                      {address?.street && `${address?.street}`}
                      {address?.number && `, N ${address?.number}`}
                      {address?.zipcode && `, ${address?.zipcode}`}
                      {address?.district && `, ${address?.district}`}
                      {address?.city?.name && `, ${address?.city?.name}`}
                      {address?.state?.uf && `, ${address?.state?.uf}`}
                    </p>
                  </div>
                </ContainerAddress>
              </div>
            ))}

          <ContainerBtn>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleRateDoctor(user?.role)}
            >
              {user?.role === 'clinic' ? 'Avaliar Cínica' : 'Avaliar Médico'}
            </Button>
            <p>Realize uma avaliação. A avaliação nao se torna pública.</p>
          </ContainerBtn>
        </Body>
      </Card>
    </>
  );
}
