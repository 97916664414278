import React from 'react'

import logo from "../../assets/images/logo-full.png";
import { Container, ContainerText, ContainerImagem } from "./styles";

export default function Footer() {
  return (
    <Container>
      <ContainerImagem>
        <img src={logo} alt={"Logo Guia Doctor"}/>
      </ContainerImagem>
      <ContainerText>
        <p>
          Medico responsável:<br/>
          Dr. Hugo Cabral Tenório – CRM AL 5348<br/>
          CEO e Responsável Técnico Médico<br/>
          Especialista Medicina do Trabalho – RQE 3266<br/>
          Especialista Medicina Legal e Perícia Médica – RQE 3541<br/>
          Pós Graduado em Direito Médico – Universidade de Coimbra
        </p>
      </ContainerText> 
    </Container>
  )
}
