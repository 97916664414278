import styled from 'styled-components';

import { Colors } from '../../../../theme';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
export const Content = styled.div`
  overflow: scroll;
  overflow-x: hidden;
  height: 58vh;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  font-weight: bold;
`;

export const ListItem = styled.div`
  background-color: red;
  height: 10px;
  width: auto;
`;

export const ProfileImage = styled.div`
  background-color: #ddd;

  height: 50px;
  width: 50px;
  border-radius: 50%;
`;

export const TextName = styled.h3``;
export const TextStatus = styled.h3`
  color: ${Colors.success};
`;

export const ButtonDetail = styled.button`
  height: 40px;
  width: ${(props) => {
    switch (props.size) {
      case 'large':
        return '190px';
      case 'medium':
        return '100px';
      default:
        return '30px';
    }
  }};

  border-radius: 5px;
  margin: 5px 10px;
  background-color: ${(props) =>
    props.color === 'error' ? Colors.error : Colors.success};
  color: #fff;

  transition: all 0.2s;
  :hover {
    opacity: 0.7;
  }
`;

export const FormContainer = styled.div`
  margin: 20px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  & > * {
    width: 40vw;
    margin-top: 15px;
  }
`;
