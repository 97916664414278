import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { ZoomIn, ZoomOut } from '@material-ui/icons';

import { Container, Button, ButtonSave } from './styles';

const MyEditor = ({ onFileUploaded, img }) => {
  const [editor, setEditor] = useState();
  const [zoom, setZoom] = useState(1);

  const setEditorRef = (edit) => setEditor(edit);
  const saveImage = () => {
    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
    //   const canvas = editor.getImage();
    const url = editor.getImageScaledToCanvas().toDataURL();
    // console.log(url)
    onFileUploaded(url);
  };

  return (
    <>
      <AvatarEditor
        ref={setEditorRef}
        width={260}
        height={260}
        image={img}
        border={50}
        borderRadius={130}
        scale={zoom}
      />

      <Container>
        <Button type="button" onClick={() => setZoom(zoom + 0.1)}>
          <ZoomIn />
        </Button>
        <Button type="button" onClick={() => setZoom(zoom - 0.1)}>
          <ZoomOut />
        </Button>
      </Container>
      <ButtonSave type="button" onClick={saveImage}>
        Salvar imagem
      </ButtonSave>
    </>
  );
};

export default MyEditor;
