import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnLeft, ColumnRight, Container, ImgLogo, ImgMan, ContainerText, ContainerButtons, Button } from './styles';
import logo from "../../../assets/images/logo-full.png";
import searchImg from "../../../assets/svg/searchImg.svg";
// import ExaminationClinics from "./examinationClinics";

function Register({ children }) {
  return (
    <Container>
      <ColumnLeft>
        <Link to="/">
          <ImgLogo src={logo} alt={"Logo Guia Doctor"}/>
        </Link>
        <h1>Você esta a alguns passos de ser encontrado por pacientes.</h1>
        <ImgMan src={searchImg} alt="React Logo" />
      </ColumnLeft>

      <ColumnRight>
        { children }
      </ColumnRight>
    </Container>
  );
}

export default Register;