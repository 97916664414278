import React,{useEffect} from 'react';
import Dashboard from '../pages/doctorPages/dashboard';
import { Route } from 'react-router-dom';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MoneyIcon from '@material-ui/icons/MonetizationOnOutlined';

// icons import
import PersonIcon from '@material-ui/icons/Person';
import Main from '../pages/doctorPages/main';
import Profile from '../pages/doctorPages/profile';
import FormAddress from '../pages/doctorPages/profile/address/formAddress';

import Rates from '../pages/doctorPages/main/Rates';
import ChangePassword from '../pages/doctorPages/main/changePassword';
import FormAddressDetails from '../pages/doctorPages/main/address/formAddressDetails';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import FaStar from '@material-ui/icons/Star';

import PaymentPage from '../pages/doctorPages/PaymentPage';
import CancelPlanPage from '../pages/doctorPages/cancelPlanPage';
import SignaturesPage from '../pages/doctorPages/signatures';

import { useSelector } from 'react-redux'

// dados de paginas
const menuItems = [
  {
    name: 'Inicio',
    route: '/',
    icon: <HomeOutlinedIcon />,
  },

  {
    name: 'Avaliações',
    route: '/avaliacoes',
    icon: <FaStar />,
  },
  
  {
    name: 'Alterar Senha',
    route: '/alterar-senha',
    icon: <SettingsOutlinedIcon />,
  },
  {
    name: 'Perfil',
    route: '/profile',
    icon: <PersonIcon />,
  },
];

export default function DoctorRoutes() {
  const { is_public } = useSelector((state) => state.auth)
  

  useEffect(()=>{
    if(!is_public){
      menuItems.push({
        name: 'Minha assinatura',
        route: '/signature',
        icon: <MoneyIcon />,
      })
    }
  },[])
  return (
    <Dashboard menuItems={menuItems}>
      <Route
        component={({ match }) => (
          <>
            <Route path="/" exact component={Main} />
            <Route path="/avaliacoes" component={Rates} />
            <Route path="/alterar-senha" component={ChangePassword} />
            <Route path="/profile" component={Profile} />
            {is_public ===false && (
              <>
                <Route path="/signature" component={SignaturesPage} />
                <Route path="/payment-page" component={PaymentPage} />
                <Route path="/cancel-plan" component={CancelPlanPage} />
              </>
            )}
            <Route path="/profile-address" component={FormAddress} />
            <Route
              path="/profile-address-detail/:id"
              component={FormAddressDetails}
            />
          </>
        )}
      />
    </Dashboard>
  );
}
