const encodeImageFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    //set the callbacks before reading the object
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
};

export { encodeImageFileToBase64 };
