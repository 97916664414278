import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnLeft, ColumnRight, Container, ImgLogo, ImgMan, ContainerText, ContainerButtons, Button } from './styles';
import logo from "../../../assets/images/logo-full.png";
import searchImg from "../../../assets/svg/searchImg.svg";
import ExaminationClinics from "./examinationClinics";


function Register() {
  return (
    <Container>
      {/* <ColumnLeft>
        <Link to="/">
          <ImgLogo src={logo} alt={"Logo Guia Doctor"}/>
        </Link>
        <h1>Você esta a alguns passos de ser encontrado por pacientes.</h1>
        <ImgMan src={searchImg} alt="React Logo" />
      </ColumnLeft> */}

      <ColumnRight>
        <ContainerText>
          <h1>Olá, você é uma clínica, estabelecimento de saúde pública ou um médico?</h1>
          <h2>Para darmos continuidade ao seu cadastro nos informe se você é uma clinica que realiza exames ou se você é um médico.</h2>
        </ContainerText>
        <ContainerButtons>
          <Link to={'/examination-clinics'}>
            <Button>Clínica de exames</Button>
          </Link>
          <Link to={'/public-clinics'}>
            <Button>Estabelecimento de saúde pública</Button>
          </Link>
          <Link to={'/medic'}>
            <Button>Médico</Button>
          </Link>
        </ContainerButtons>
      </ColumnRight>
    </Container>
  );
}

export default Register;