import styled from 'styled-components';
import bgImg from '../../../assets/images/bg.png';
import { Colors } from '../../../theme';

import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  /* background: red; */

  h1 {
    font-size: 46px;
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: normal;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 40px 0px 0px 0px;

    /* input{
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 5px;
      margin-bottom: 1em;
      padding: 10px 15px;
      box-shadow: rgb(204 204 204) 0px 1px 10px 0px
    } */

    textarea {
      border: none;
      border-radius: 5px;
      margin-bottom: 0.7em;
      padding: 10px 15px;
      box-shadow: rgb(204 204 204) 0px 1px 10px 0px;
      resize: none;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 10px 15px;
  box-shadow: rgb(204 204 204) 0px 1px 10px 0px;
`;

export const Section = styled.div`
  /* background-color: red; */
  width: 100%;
  height: 95vh;
  background-image: url(${bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index:2;

  @media (min-width: 660px) {
    justify-content: flex-end;
  }
`;

export const ContainerForm = styled.div`
  max-width: 380px;
  margin: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    div {
      padding-left: 0;
      width: 300px;
      input {
        width: 100%;
      }
      button {
        margin-left: 40%;
      }
    }
    @media (max-width: 400px) {
      margin-right: 0;
    }
    @media (max-width: 660px) {
      width: 80vw;
      max-width: 435px;

      /* padding: 20px 50px; */

      flex-flow: column wrap;

      div {
        width: 100%;
        margin-right: 0;
        padding-left: 0;
        input {
          width: 100%;
        }
        button {
          margin-left: 40%;
        }
      }
    }
  }

  h1 {
    text-align: center;
  }
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  h2,
  h3 {
    font-style: normal;
    font-weight: normal;
  }

  h3 {
    margin-top: 20px;
  }

  @media (max-width: 665px) {
    h2,
    h3 {
      font-style: normal;
      font-weight: normal;
      text-align: center;
    }
    h2 {
      margin-top: 20px;
    }
  }
`;

export const Square = styled.div`
  width: 250px;
  height: 250px;
  background-color: ${Colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerSection = styled.div`
  /* margin: 50px 0px 0px 0px; */
  padding: 100px 50px 50px 50px;

  @media (max-width: 665px) {
    padding: 50px 50px 50px 50px;
  }
  div:first-child {
    margin: 0px 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px 0px;
`;

export const ContainerPrice = styled.div`
  padding: 50px 50px 50px 50px;

  h2,
  h3 {
    font-style: normal;
    font-weight: normal;
    text-align: center;
  }
`;

export const ContainerTextPrice = styled.div`
  h2,
  h3 {
    margin-bottom: 20px;
  }
`;

export const CardPrice = styled.div`
  width: 381px;
  height: 400px;
  padding: 50px;
  border-radius: 10px;
  align-self: center;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: space-between;
  box-shadow: rgb(204 204 204) 0px 1px 10px 0px;
  margin: 2rem auto;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Colors.primary};
  color: ${({ backgroundColor }) =>
    backgroundColor == Colors.primary ? '#fff' : Colors.primary};
`;

export const HeaderCardPrice = styled.div``;

export const BodyCardPrice = styled.div`
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 0px 0px;

  div {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    span {
      font-weight: 100;
      margin-right: 0px;
    }

    h1 {
      font-size: 44px;
      font-weight: normal;
    }

    p {
      font-size: 26px;
      font-weight: 100;
    }
  }
`;

export const FooterCardPrice = styled.div`
  h3 {
    font-size: 23px;
    font-weight: 100;
  }
`;

export const ContainerFreeTest = styled.div`
  padding: 50px;
  background-color: ${Colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #fff;
  }
`;

export const ContainerContact = styled.div`
  padding: 50px 50px 0px 50px;
  text-align: center;

  form {
    width: 100%;
    margin: 0px 0px 0px 50px;
    /* padding: 20px 50px; */
    max-width: 435px;

    flex-flow: column wrap;
    box-sizing: border-box;
    display: flex;
  }

  h2 {
    font-weight: 400;
  }

  h3 {
    color: ${Colors.secondary};
    font-weight: 100;
    margin-top: 20px;
  }

  button {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    min-width: 100px;
    margin: auto;
  }

  @media (max-width: 665px) {
    padding: 20px;
    form {
      width: 100%;
      margin: 0px 0px 0px 0px;
    }
    img {
      width: 100%;
      margin-bottom: 50px;
    }
  }
`;

export const MytextField = styled(TextField)`
  ::before {
    border-bottom: none;
  }
  ::after {
    border-bottom: none;
  }
`;

export const HealthPlansSelector = styled(NativeSelect)`
  padding: 10px 5px;
`;
