import styled from 'styled-components';
import Card from '@material-ui/core/Card';

import { Colors } from '../../../theme/index';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
`;

export const CardContainer = styled(Card)`
  background-color: ${(props) => (props.color ? props.color : '#fff')};
  width: 25vw;
  padding: 10px 0px;

  margin: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const TitleCard = styled.p`
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: ${Colors.white};
  font-size: 20px;
`;
export const ValueContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const ValueCard = styled.h3`
  font-weight: bold;
  font-size: 40px;
  color: ${Colors.white};
`;

export const DateInput = styled.input`
  background-color:transparent;
  border:1px solid #fff;
  padding:5px;
`;
export const FormAccess = styled.div`
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >div{
    display: flex;
  }
`;

export const ButtonSearch = styled.button`
  padding: 10px;
  color:#fff;
  background-color: ${props => props.type === 'error'?Colors.warn:Colors.success};
  border-radius: 5px;
  
  margin:5px;
`;
