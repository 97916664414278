import styled from 'styled-components';
import Card from '@material-ui/core/Card';

import { Colors } from '../../../theme/index';

const handleTextColor = (value) => {
  switch (value) {
    case 'pending':
      return Colors.warn;
    case 'cancelled':
      return Colors.error;
    case 'approved':
      return Colors.success;

    default:
      break;
  }
};

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
export const Content = styled.div`
  overflow: scroll;
  overflow-x: hidden;
  height: 65vh;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  font-weight: bold;
`;

export const ListItem = styled.div`
  background-color: red;
  height: 10px;
  width: auto;
`;

export const ProfileImage = styled.div`
  background-color: #ddd;

  height: 50px;
  width: 50px;
  border-radius: 50%;
`;

export const TextName = styled.h3``;
export const TextStatus = styled.h3`
  color: ${(props) => handleTextColor(props.status)};
`;

export const ButtonDetail = styled.button`
  height: 30px;
  width: 100px;
  margin: 0;
  border-radius: 5px;
  background-color: ${Colors.success};
  color: #fff;

  transition: all 0.2s;
  :hover {
    opacity: 0.7;
  }
`;

export const ListButtons = styled.div``;

export const BaseButton = styled.button`
  border: 1px solid ${Colors.new_primary};
`;
