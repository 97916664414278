import React, { useState } from 'react';
import { Container as Mcont, Avatar } from '@material-ui/core';
import { toast } from 'react-toastify';

import { useHistory, useParams, useLocation } from 'react-router-dom';

import Header from '../../../components/header';
import Stars from '../../../components/stars';
import api from '../../../services/api';

import {
  Container,
  Title,
  Header as StHeader,
  DoctorName,
  CrmText,
  Uf,
  Content,
  Item,
  Text,
  Button,
} from './styles';

function DoctorRate() {
  const [message, setMessage] = useState('');
  const [rate, setRate] = useState({
    punctuality_service: 1,
    structure_office: 1,
    conduct_attention_patient: 1,
    technical_ability: 1,
    result_obtained: 1,
  });
  const location = useLocation();
  let { id } = useParams();
  id = id.replace(':', '');

  const { user } = location.state;

  const history = useHistory();

  const handleRating = (rating, text) => {
    const data = rate;
    data[text] = rating + 1;
    setRate(data);
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await api.post('/ratings', {
        rating: {
          user_id: id,
          punctuality_service: rate.punctuality_service,
          structure_office: rate.structure_office,
          conduct_attention_patient: rate.conduct_attention_patient,
          technical_ability: rate.technical_ability,
          result_obtained: rate.result_obtained,
          description: message,
        },
      });

      toast.success(
        'Agradecemos sua avaliação! voltando para página principal'
      );
      history.goBack();
    } catch (error) {
      toast.warn('Algo inesperado aconteceu! tente novamente mais tarde');
    }
  };

  return (
    <>
      <Header />
      <Mcont
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center;',
          paddingBottom: '20px',
        }}
      >
        <Container>
          <Title>Avaliar Médico</Title>
          <StHeader>
            <Avatar
              style={{
                width: 80,
                height: 80,
                marginRight: '20px',
                marginBottom: '10px',
              }}
              alt="Foto do médico"
              src={`${user?.member?.profile_image}`}
            />
            <div className="doctor-info">
              <section>
                <p>Nome:</p>
                <DoctorName>{user.name}</DoctorName>
              </section>
              <section>
                <p>CRM:</p>
                <CrmText>{user?.member?.crm}</CrmText>
              </section>
              <section>
                <p>UF:</p>
                <Uf>{user?.member?.crm_uf?.uf}</Uf>
              </section>
            </div>
          </StHeader>
          <Content>
            <Item>
              <Text>PONTUALIDADE NO ATENDIMENTO:</Text>
              <Stars text="punctuality_service" returnRating={handleRating} />
            </Item>
            <Item>
              <Text>ESTRUTURA DO CONSULTÓRIO:</Text>
              <Stars text="structure_office" returnRating={handleRating} />
            </Item>
            <Item>
              <Text>CONDUTA E ATENÇÃO AO PACIENTE:</Text>
              <Stars
                text="conduct_attention_patient"
                returnRating={handleRating}
              />
            </Item>
            <Item>
              <Text>HABILIDADE TÉCNICA:</Text>
              <Stars text="technical_ability" returnRating={handleRating} />
            </Item>
            <Item>
              <Text>RELAÇÃO ENTRE O QUE FOI DITO E O RESULTADO OBTIDO:</Text>
              <Stars text="result_obtained" returnRating={handleRating} />
            </Item>
            <Item>
              <Text>Comentários adicionais</Text>
              <textarea
                maxLength="500"
                placeholder="Mais algum comentário? mande-nos um texto (500 caracteres)"
                value={message}
                onChange={(event) => handleChange(event)}
              />
            </Item>
          </Content>
          <Button type="button" onClick={handleSubmit}>
            Enviar Avaliação
          </Button>
        </Container>
      </Mcont>
    </>
  );
}

export default DoctorRate;
