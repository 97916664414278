import React, { useEffect, useState } from 'react'
import { ContainerCards, CardStyleButton } from '../styles'
import { Button } from '@material-ui/core'
import api from '../../../../services/api'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

export default function Address () {
  const history = useHistory()
  const { auth } = useSelector((store) => store)
  const [addressList, setaddressList] = useState([])

  const getUserData = async () => {
    try {
      const response = await api.get(`users?id=${auth?.id}`)
      const { addresses } = response.data.user
      setaddressList(addresses)
      console.log('response', response)
    } catch (error) {
      console.log('error', error)
    }
  }

  const goToAddAddress = () => {
    history.push('profile-address')
  }

  const goToAddAddressEdit = (id) => {
    history.push(`/profile-address-detail/${id}`)
  }

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <ContainerCards>
      <CardStyleButton style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Button
          color='secondary'
          onClick={goToAddAddress}
        >
          <h3 style={{ textAlign: 'center' }}>Adicionar novo local de atendimento</h3>
        </Button>
      </CardStyleButton>
      {addressList && addressList.map((address, i) =>
        <CardStyleButton key={i}>
          <Button
            color='secondary'
            onClick={() => goToAddAddressEdit(address.id)}
          >
            <h3>Endereço:</h3>
            <h4>
              {address?.street}
              {address?.district && ` - ${address?.district}`}
              {address?.city?.name && `, ${address?.city?.name}`}
              {address?.state?.uf && ` - ${address?.state?.uf}`}
            </h4>
            <h3 style={{ marginTop: 5 }}>Telefone:</h3>
            <h4>{address.phone}</h4>
          </Button>
        </CardStyleButton>
      )}

    </ContainerCards>
  )
}
