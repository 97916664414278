import React from 'react'
import { Switch } from 'react-router-dom'
import AdminRoutes from './adminRoutes'
import OpenRoutes from './openRoutes'
import DoctorRoutes from './doctorRoutes'
import { useSelector } from 'react-redux'

export default function Routes () {
  const { signed, type_user } = useSelector((state) => state.auth)
  const loggedUser = signed
  const userType = type_user
  let Page = null
  /**
   * userTypes: root, clinic, doctor
   */

  if (!loggedUser) {
    Page = OpenRoutes
  } else {
    Page = userType === 'root' ? AdminRoutes : DoctorRoutes
  }

  return (
    <Switch>
      <Page />
    </Switch>
  )
}
