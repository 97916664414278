import styled from 'styled-components';
import { Card as MCard } from '@material-ui/core';
import { Colors } from '../../../theme';

export const ButtonGroup = styled(MCard)`
  margin: 10px;
  padding: 10px;
`;

export const Button = styled.button`
  background-color: ${(props) =>
    props.color === 'error' ? Colors.error : Colors.success};

  color: #fff;

  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  transition: all 0.5s ease;
  :hover {
    opacity: 0.7;
  }
`;
