import React from 'react';

import ReactPlayer from 'react-player/youtube';

// import { Container } from './styles';

function VideoPlayer({ link = '' }) {
  return (
    <>
      <br />
      {link !== '' ? (
        <ReactPlayer url={link} />
      ) : (
        <>
          <p>Este médico ainda não possui um vídeo</p>
        </>
      )}
    </>
  );
}

export default VideoPlayer;
