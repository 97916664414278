import React from "react";
import {Container, Button , Icon} from './styles'
import Arrow from '../../assets/images/up-arrow.png'


const ScrollButton = ({scrollToElement}) => {

  return (
    <>
      <Container>
        <Button onClick={scrollToElement}>
          <Icon src={Arrow} alt="seta apontando para cima"/>
        </Button>      
      </Container>     
   </>
  );
}

export default ScrollButton;
