import React, { useState } from 'react'

import { Container, Star } from './styles'

function MakeStars ({ stars = 0, size = 24, returnRating, text = '', disabled = false }) {
  const [rating, setRating] = useState(stars)
  const [hover, setHover] = useState(stars)

  const handleRating = (ratingValue) => {
    setRating(ratingValue)
    // caso receba o callback executa
    returnRating && returnRating(ratingValue, text)
  }

  return (
    <Container>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index++

        return (
          <label key={index}>
            <input
              type='radio'
              name='ratingInput'
              onClick={() => !disabled && handleRating(ratingValue)}
            />
            <Star
              className='star'
              color={ratingValue <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
              size={size}
              onMouseEnter={() => !disabled && setHover(ratingValue)}
              onMouseLeave={() => !disabled && setHover(null)}
            />
          </label>
        )
      })}
    </Container>
  )
}

export default MakeStars
