export function formatPlans(
  medical_health_plans,
  medicalHealthPlansAttributesFormating
) {
  let validPlansData = [];

  // verificando quais planos ja sao cadastrados e quais foram removidos
  medical_health_plans.forEach((plan) => {
    const index = medicalHealthPlansAttributesFormating.find(
      (p) => p.health_plan_id === plan.health_plan.id
    );

    if (index) {
      validPlansData.push({
        id: plan.id,
        health_plan_id: plan.health_plan.id,
      });
    } else {
      validPlansData.push({
        id: plan.id,
        health_plan_id: plan.health_plan.id,
        _destroy: true,
      });
    }
  });

  validPlansData.forEach((plan) => {
    const index = medicalHealthPlansAttributesFormating.findIndex(
      (p) => p.health_plan_id === plan.health_plan_id
    );
    if (index >= 0) {
      medicalHealthPlansAttributesFormating.splice(index, 1);
    }
  });

  const plans = [...validPlansData, ...medicalHealthPlansAttributesFormating];
  return plans;
}

/**
 * formatação de exames
 */

export function formatExams(
  medical_clinic_exams,
  medicalClinicExamsAttributesFormating
) {
  //medical_exam_id
  let validExamsData = [];

  // verificando os que foram deletados da lista ou não
  medical_clinic_exams.forEach((exam) => {
    const index = medicalClinicExamsAttributesFormating.find(
      (p) => p.medical_exam_id === exam.medical_exam.id
    );

    if (index) {
      validExamsData.push({
        id: exam.id,
        medical_exam_id: exam.medical_exam.id,
      });
    } else {
      validExamsData.push({
        id: exam.id,
        medical_exam_id: exam.medical_exam.id,
        _destroy: true,
      });
    }
  });

  validExamsData.forEach((plan) => {
    const index = medicalClinicExamsAttributesFormating.findIndex(
      (p) => p.medical_exam_id === plan.medical_exam_id
    );
    if (index >= 0) {
      medicalClinicExamsAttributesFormating.splice(index, 1);
    }
  });

  const exam = [...validExamsData, ...medicalClinicExamsAttributesFormating];
  return exam;
}

export function formatEspecialties(
  medical_especialties,
  medicalEspecialtiesAttributesFormating
) {
  //medical_exam_id
  let validExamsData = [];

  // verificando os que foram deletados da lista ou não
  medical_especialties.forEach((exam) => {
    const index = medicalEspecialtiesAttributesFormating.find(
      (p) => p.medical_specialty_id === exam.medical_specialty.id
    );

    if (index) {
      validExamsData.push({
        id: exam.id,
        medical_specialty_id: exam.medical_specialty.id,
      });
    } else {
      validExamsData.push({
        id: exam.id,
        medical_specialty_id: exam.medical_specialty.id,
        _destroy: true,
      });
    }
  });

  validExamsData.forEach((plan) => {
    const index = medicalEspecialtiesAttributesFormating.findIndex(
      (p) => p.medical_specialty_id === plan.medical_specialty_id
    );
    if (index >= 0) {
      medicalEspecialtiesAttributesFormating.splice(index, 1);
    }
  });

  const exam = [...validExamsData, ...medicalEspecialtiesAttributesFormating];
  return exam;
}
