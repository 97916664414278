import React from 'react';

import { StyledLink } from './styles';

function TextLink({children, link, ...rest}) {
  return (
    <StyledLink  to={link}{...rest}>{children}</StyledLink>
  );
}

export default TextLink;