import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import api from '../../../services/api';

import Modal from '../../../components/modal';

import EditAttributesOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import PlusOne from '@material-ui/icons/Add';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import {
  Container,
  Content,
  Title,
  TextName,
  ButtonDetail,
  FormContainer,
} from './styles';

function ListExams() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exams, setExams] = useState([]);
  const [types, setTypes] = useState({});
  const [selected, setSelected] = useState(0);

  const [action, setAction] = useState({
    title: '',
    screen: '',
    id: 0,
  });

  const [exam, setExam] = useState('');

  // seta qual modal vai abrir e quais dados serao usados
  const handleModal = (title, screen, id = 0, content = '') => {
    setExam(content);
    setOpen(true);
    setAction({
      title,
      screen,
      content,
      id,
    });
  };
  // fechar modal
  const handleClose = () => setOpen(false);

  // busca de lista na api
  const getExams = async () => {
    setLoading(true);
    const [response1, response2] = await Promise.all([
      api.get('medical_exams'),
      api.get('medical_exams/type_exams'),
    ]);

    const examTypes = Object.entries(response2.data.exams);

    setExams(response1.data.medical_exams);
    setTypes(examTypes);

    //  console.log(examTypes);

    setLoading(false);
    // console.log('especialties', response);
  };

  // controle do seletor
  const handleSelect = ({ target }) => {
    setSelected(target.value);
  };

  // função de criação de plano de saude
  const handleSubmit = async () => {
    try {
      if (!exam) {
        toast.warn('Digite o nome do exame');
        return;
      }
      const response = await api.post('medical_exams', {
        medical_exam: {
          name: exam,
          exam: selected,
        },
      });
      if (response.status === 201) {
        toast.success('Cadastrado com sucesso');
        setExam('');
        setOpen(false);
        getExams();
      }
    } catch (error) {
      toast.warn('Algo inesperado aconteceu');
    }
  };

  // função de atualização de especialidade
  const handleUpdate = async () => {
    try {
      if (!exam) {
        toast.warn('Digite o nome da especialidade');
        return;
      }
      const response = await api.put(`medical_exams/${action.id}`, {
        medical_exam: {
          name: exam,
          exam: selected,
        },
      });
      //    console.log(response);
      if (response.status === 200) {
        toast.success('Atualizado com sucesso');
        setAction({ title: '', screen: '', id: 0, content: '' });
        setExam('');
        setOpen(false);
        getExams();
      }
    } catch (error) {
      //console.log(error)
      toast.warn('Algo inesperado aconteceu');
    }
  };
  // função de atualização de especialidade
  const handleDelete = async () => {
    try {
      const response = await api.delete(`medical_exams/${action.id}`);
      // console.log(response);

      if (response.status === 204) {
        toast.success('Excluido com sucesso');
        setAction({ title: '', screen: '', id: 0, content: '' });
        setExam('');
        setOpen(false);
        getExams();
      }
    } catch (error) {
      toast.warn('Algo inesperado aconteceu');
    }
  };

  useEffect(() => {
    getExams();
  }, []);

  return (
    <Container>
      {/** cadastrar nova especialidade */}
      {open && action.screen === 'create' && (
        <Modal open={true} handleClose={handleClose} title={action.title}>
          <FormContainer>
            <TextField
              id="outlined-basic"
              label="Nome do Exame"
              variant="outlined"
              value={exam}
              onChange={(e) => setExam(e.target.value)}
            />
            <InputLabel id="demo-simple-select-label">Tipo de Exame</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected}
              onChange={handleSelect}
            >
              {types.map((item) => (
                <MenuItem value={item[1]} name={item[0]}>
                  {item[0]}
                </MenuItem>
              ))}
            </Select>

            <ButtonDetail onClick={handleSubmit}>Cadastrar Exame</ButtonDetail>
          </FormContainer>
        </Modal>
      )}

      {/** atualizar plano de saude */}
      {open && action.screen === 'edit' && (
        <Modal open={true} handleClose={handleClose} title={action.title}>
          <FormContainer>
            <TextField
              id="outlined-basic"
              label="Nome do Exame"
              variant="outlined"
              value={exam}
              onChange={(e) => setExam(e.target.value)}
            />
            <InputLabel id="demo-simple-select-label">Tipo de Exame</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected}
              onChange={handleSelect}
            >
              {types.map((item) => (
                <MenuItem value={item[1]} name={item[0]}>
                  {item[0]}
                </MenuItem>
              ))}
            </Select>

            <ButtonDetail onClick={handleUpdate}>Atualizar Exame</ButtonDetail>
          </FormContainer>
        </Modal>
      )}
      {/** excluir plano de saude */}
      {open && action.screen === 'delete' && (
        <Modal open={true} handleClose={handleClose} title={action.title}>
          <FormContainer>
            <TextField
              id="outlined-basic"
              label="Nome do Exame"
              variant="outlined"
              value={exam}
              onChange={(e) => setExam(e.target.value)}
            />
            <ButtonDetail onClick={handleDelete} color="error">
              Excluir Exame
            </ButtonDetail>
          </FormContainer>
        </Modal>
      )}

      {/** listagem de medicos */}
      <Title>Exames</Title>
      {/** botao criar exame */}
      <ButtonDetail
        onClick={() => handleModal('Adicionar novo Exame', 'create')}
        size="large"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PlusOne />
        Adicionar novo Exame
      </ButtonDetail>

      <TableContainer component={Paper}>
        <Table className={'table'} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Tipo</TableCell>

              <TableCell align="center">Açoes</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <Content>
        <TableContainer component={Paper}>
          <Table className={'table'} aria-label="simple table">
            <TableBody>
              {loading ? (
                <TableRow key={1} hover>
                  <TableCell component="th" scope="row" align="center">
                    <TextName>Carregando</TextName>
                  </TableCell>
                </TableRow>
              ) : (
                exams?.map((item) => (
                  <TableRow key={item.id} hover>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        width: 300,
                      }}
                    >
                      <TextName>{item.name}</TextName>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <TextName>{item.exam}</TextName>
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        padding: 0,
                        margin: 0,
                        flexDirection: 'row',
                        width: 150,
                      }}
                    >
                      <ButtonDetail
                        onClick={() =>
                          handleModal(
                            'Atualizar plano de saude',
                            'edit',
                            item.id,
                            item.name
                          )
                        }
                      >
                        <EditAttributesOutlinedIcon />
                      </ButtonDetail>
                      <ButtonDetail
                        onClick={() =>
                          handleModal(
                            'Excluir plano de saude',
                            'delete',
                            item.id,
                            item.name
                          )
                        }
                        color="error"
                      >
                        <DeleteForeverOutlined />
                      </ButtonDetail>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Content>
    </Container>
  );
}

export default ListExams;
