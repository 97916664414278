import React from 'react';
import { Route } from 'react-router-dom';

// icons import
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import DetailsOutlinedIcon from '@material-ui/icons/DetailsOutlined';
import PersonIcon from '@material-ui/icons/Person';
import MessageIcon from '@material-ui/icons/Message';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

// paginas do admin
import Dashboard from '../pages/adminPages/dashboard';
import InformationPage from '../pages/adminPages/informationPage';
import DoctorsList from '../pages/adminPages/listDoctors';
import ClinicsList from '../pages/adminPages/listClinics';
import DetailClinic from '../pages/adminPages/detailClinic';
import DetailDoctor from '../pages/adminPages/detailDoctor';
import Especialties from '../pages/adminPages/especialties';
import HealthPlans from '../pages/adminPages/healthPlans';
import Exams from '../pages/adminPages/exams';
import Profile from '../pages/adminPages/profile';
import FormAddress from '../pages/adminPages/profile/address/formAddress';
import Contacts from '../pages/adminPages/contacts';
import ChangePassword from '../pages/doctorPages/main/changePassword';

import FormAddressDetails from '../pages/adminPages/profile/address/formAddressDetails';
// paginas provisorias

// dados de paginas
const menuItems = [
  {
    name: 'Inicio',
    route: '/',
    icon: <HomeOutlinedIcon />,
  },
  {
    name: 'Medicos',
    route: '/list-doctors',
    icon: <AssignmentIndOutlinedIcon />,
  },
  {
    name: 'Clinicas',
    route: '/list-clinics',
    icon: <LocalHospitalOutlinedIcon />,
  },
  {
    name: 'Mensagens',
    route: '/contacts',
    icon: <MessageIcon />,
  },
  {
    name: 'Especialidades',
    route: '/especialties',
    icon: <DetailsOutlinedIcon />,
  },
  {
    name: 'Planos de Saúde',
    route: '/healthPlans',
    icon: <DetailsOutlinedIcon />,
  },
  {
    name: 'Exames',
    route: '/exams',
    icon: <DetailsOutlinedIcon />,
  },
  {
    name: 'Alterar Senha',
    route: '/alterar-senha',
    icon: <SettingsOutlinedIcon />,
  },
  {
    name: 'Perfil',
    route: '/profile',
    icon: <PersonIcon />,
  },
];

export default function adminRoutes() {
  return (
    <Dashboard menuItems={menuItems}>
      <Route
        component={({ match }) => (
          <>
            <Route path="/" exact component={InformationPage} />
            <Route path="/list-doctors" component={DoctorsList} />
            <Route path="/list-clinics" component={ClinicsList} />
            <Route path="/doctor-details/:id" component={DetailDoctor} />
            <Route path="/clinic-details/:id" component={DetailClinic} />
            <Route path="/especialties" component={Especialties} />
            <Route path="/healthPlans" component={HealthPlans} />
            <Route path="/contacts" component={Contacts} />
            <Route path="/exams" component={Exams} />
            <Route path="/profile" component={Profile} />
            <Route path="/profile-address" component={FormAddress} />
            <Route path="/alterar-senha" component={ChangePassword} />
            <Route
              path="/profile-address-detail/:id"
              component={FormAddressDetails}
            />
          </>
        )}
      />
    </Dashboard>
  );
}
