import React from 'react'
import {  ContainerButtonsMenu } from './styles';
import { Link } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Button } from '@material-ui/core';

import { useDispatch } from 'react-redux'
import { getDoctorsToOpenRoutes } from '../../../store/modules/doctor/actions';
import { useHistory } from 'react-router-dom'


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
export default function MenuMobile({ goToAboutRef, goToPricetRef, goToContactRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch()
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handlePage = page => {
    history.push(page)
  }


  const goToSearch = () => {
    const citysSelects = ' ';
    const plans = ' ';
    let personal_consultation = ' ';
    let home_consultation = ' ';



    dispatch(
      getDoctorsToOpenRoutes({
        city: citysSelects,
        home_consultation,
        personal_consultation,
        plan: plans,
        searchParam: '',
        crm: '',
      })
    );

    history.push(`/pesquisar`);
  };



  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div >
      <CssBaseline />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
      // className={clsx(classes.menuButton, open && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer

        // className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        onClick={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div >
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem onClick={goToAboutRef} button>
            <ListItemText primary={<p>Sobre</p>} />
          </ListItem>
          <ListItem onClick={goToPricetRef} button>
            <ListItemText primary={<p>Preços</p>} />
          </ListItem>
          <ListItem onClick={() => handlePage('termos-de-uso')} button>
            <ListItemText primary={<p>Termos de uso</p>} />
          </ListItem>
          <ListItem onClick={() => handlePage('normas-de-etica')} button>
            <ListItemText primary={<p>Normas de ética médica</p>} />
          </ListItem>
          <ListItem onClick={goToSearch} button>
            <ListItemText primary={<p>Avalie clínicas e médicos</p>} />
          </ListItem>
          <ListItem onClick={goToContactRef} button>
            <ListItemText primary={<p>Contato</p>} />
          </ListItem>



        </List>
        <Divider />
        <ContainerButtonsMenu>
          <Button size="small" fullWidth component={Link} to="/cadastro" variant="contained" color="primary">
            Cadastrar-se
          </Button>
          <Button size="small" fullWidth component={Link} to="/login" variant="outlined" color="primary">
            Entrar
          </Button>
        </ContainerButtonsMenu>
        <Divider />

      </Drawer>
    </div>
  )
}
