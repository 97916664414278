// import {
//   // useTheme,
//   createMuiTheme,
//   // MuiThemeProvider
// } from "@material-ui/core/styles";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

// const Theme = createMuiTheme({
//   palette: {
//     primary: {
//       main: "#264191"
//     },
//     grey: {
//       main: "red"
//     }
//   },
//   // typography: {
//   //   body1: {
//   //     fontFamily: "Comic Sans"
//   //   }
//   // },
//   // custom: {
//   //   myOwnComponent: {
//   //     margin: "10px 10px",
//   //     backgroundColor: "lightgreen"
//   //   }
//   // }
// });

const Theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1B1B1B',
      contrastText: '#000',
    },
  },
});

export default Theme;