import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  id: null,
  signed: false,
  type_user: null,
  is_public:null,
  loading: false,
  steepRegister: {
    username: null,
    password: null,
    password_confirmation: null,
    member_attributes: {
      social_name: null,
      cnpj: null,
      fantasy_name: null,
      registration_number_crm: null,
      name: null,
      crm: null,
      crm_uf_id: null,
      professional_experience: null,
      personal_consultation: null,
      health_plan: null,
      personal_phone: null
    },
    addresses_attributes: [
      {
        street: null,
        number: null,
        complement: null,
        zipcode: null,
        district: null,
        city_id: null,
        state_id: null,
        phone: null
      }
    ],
    medical_health_plans_attributes: [
      {
        health_plan_id: null
      },
    ],
    medical_clinic_exams_attributes: [
      {
        medical_exam_id: null
      },
    ]
  }
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        console.log(`action.payload.token`, action.payload)
        draft.token = action.payload.token;
        draft.signed = true;
        draft.is_public= action.payload.is_public;
        draft.type_user = action.payload.type_user;
        draft.id = action.payload.id;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_IN_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        draft.type_user = null;
        break;
      }
      case '@auth/FINISH_FORM': {
        draft.steepRegister = {
          username: action?.payload?.data?.username,
          password: action?.payload?.data?.password,
          password_confirmation: action?.payload?.data?.password_confirmation,
          member_attributes: {
            social_name: null,
            cnpj: null,
            fantasy_name: null,
            registration_number_crm: null,
            name: null,
            crm: null,
            crm_uf_id: null,
            professional_experience: null,
            personal_consultation: null,
            health_plan: null,
            personal_phone: action?.payload?.data?.personal_phone
          },
          addresses_attributes: [
            {
              street: null,
              number: null,
              complement: null,
              zipcode: null,
              district: null,
              city_id: null,
              state_id: null,
              phone: null
            }
          ],
          medical_health_plans_attributes: null,
          medical_clinic_exams_attributes: null,
        }
        break;
      }
      case '@auth/STEEPSIGN_TECNIC_FORM': {
        console.log(`action`, action)
        draft.steepRegister.member_attributes.cnpj = action?.payload?.data?.cnpj;
        draft.steepRegister.member_attributes.crm = action?.payload?.data?.crm;
        draft.steepRegister.member_attributes.crm_uf_id = action?.payload?.data?.crm_uf_id;
        draft.steepRegister.member_attributes.professional_experience = action?.payload?.data?.description;
        draft.steepRegister.member_attributes.fantasy_name = action?.payload?.data?.fantasyName;
        draft.steepRegister.member_attributes.health_plan = action?.payload?.data?.health_plan;
        
        draft.steepRegister.medical_clinic_exams_attributes = action?.payload?.data?.medical_clinic_exams_attributes;
        draft.steepRegister.medical_health_plans_attributes = action?.payload?.data?.medical_health_plans_attributes;

        draft.steepRegister.member_attributes.name = action?.payload?.data?.name;
        draft.steepRegister.member_attributes.personal_consultation = action?.payload?.data?.personal_consultation;
        draft.steepRegister.member_attributes.professional_experience = action?.payload?.data?.professional_experience;
        draft.steepRegister.member_attributes.registration_number_crm = action?.payload?.data?.registration_number_crm;
        draft.steepRegister.member_attributes.social_name = action?.payload?.data?.socialName;
        console.log(`state`, state)
        break;
      }
      case '@auth/STEEP_ADDRESS_FORM': {
        // console.log('action', action);
        // console.log('state', state);
        draft.steepRegister.addresses_attributes[0].state_id = action?.payload?.data?.state_id?.id;
        draft.steepRegister.addresses_attributes[0].city_id = action?.payload?.data?.city_id?.id;
        draft.steepRegister.addresses_attributes[0].complement = action?.payload?.data?.complement;
        draft.steepRegister.addresses_attributes[0].district = action?.payload?.data?.district;
        draft.steepRegister.addresses_attributes[0].number = action?.payload?.data?.number;
        draft.steepRegister.addresses_attributes[0].phone = action?.payload?.data?.phone;
        draft.steepRegister.addresses_attributes[0].street = action?.payload?.data?.street;
        draft.steepRegister.addresses_attributes[0].url = action?.payload?.data?.url;
        draft.steepRegister.addresses_attributes[0].zipcode = action?.payload?.data?.zipcode;
        draft.loading = true;
        break;
      }
      case '@auth/STEEPSIGN_LOGIN_FORM': {
        if (!draft?.steepRegister?.username) {
          draft.steepRegister = {
            username: action?.payload?.data?.username,
            password: action?.payload?.data?.password,
            password_confirmation: action?.payload?.data?.password_confirmation,
            member_attributes: {
              social_name: null,
              cnpj: null,
              fantasy_name: null,
              registration_number_crm: null,
              name: null,
              crm: null,
              crm_uf_id: null,
              professional_experience: null,
              personal_consultation: null,
              health_plan: null,
              personal_phone: action?.payload?.data?.personal_phone
            },
            addresses_attributes: [
              {
                street: null,
                number: null,
                complement: null,
                zipcode: null,
                district: null,
                city_id: null,
                state_id: null,
                phone: null
              }
            ],
            medical_health_plans_attributes: [
              {
                health_plan_id: null
              },
            ],
            medical_clinic_exams_attributes: [
              {
                medical_exam_id: null
              },
            ]
          }
        } else {
          draft.steepRegister.username = action?.payload?.data?.username
          draft.steepRegister.password = action?.payload?.data?.password
          draft.steepRegister.password_confirmation = action?.payload?.data?.password_confirmation
          draft.steepRegister.member_attributes.personal_phone = action?.payload?.data?.personal_phone
        }
        break;
      }
      default:
    }
  });
}