import styled from 'styled-components';

import Card from '@material-ui/core/Card';

import { Colors } from '../../../../theme/index';

export const Container = styled.div``;
export const Button = styled.button``;
export const Content = styled.div``;
export const List = styled(Card)`
  margin: 10px 0px;
  padding: 10px;
`;

export const Item = styled.div``;
export const Text = styled.p`
  font-size: 14px;
`;
export const CommentContainer = styled.p`
  border: 1px solid ${Colors.gray03};
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
`;

export const TitleComment = styled.p`
  font-size: 12px;
  background-color: #fff;
  position: absolute;
  top: -10px;
  padding: 2px 5px;
  color: ${Colors.gray03};
`;
