import React from 'react';
import Colors from '../../../../theme/Colors';

import { Container, Buttom } from './styles';

function MenuTab({ select, setselect }) {
  return (
    <Container>
      <Buttom
        onClick={() => setselect(0)}
        style={{ background: select === 0 ? Colors.primary : '#fff' }}
      >
        <p style={{ color: select === 0 ? '#fff' : Colors.primary }}>
          1. Informações Gerais
        </p>
      </Buttom>
      <Buttom
        onClick={() => setselect(1)}
        style={{ background: select === 1 ? Colors.primary : '#fff' }}
      >
        <p style={{ color: select === 1 ? '#fff' : Colors.primary }}>
          2. Convênios Médicos
        </p>
      </Buttom>
      <Buttom
        onClick={() => setselect(2)}
        style={{ background: select === 2 ? Colors.primary : '#fff' }}
      >
        <p style={{ color: select === 2 ? '#fff' : Colors.primary }}>
          3. Locais de Atendimento
        </p>
      </Buttom>
      <Buttom
        onClick={() => setselect(3)}
        style={{ background: select === 3 ? Colors.primary : '#fff' }}
      >
        <p style={{ color: select === 3 ? '#fff' : Colors.primary }}>
          4. Video
        </p>
      </Buttom>
    </Container>
  );
}

export default MenuTab;
