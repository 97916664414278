import styled from "styled-components";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AppBar from "@material-ui/core/AppBar";
import { Colors } from "../../../theme/index";

export const LogoImg = styled.img`
  width: 50%;
  margin-right: 20%;
`;

export const Icon = styled(ChevronRightIcon)`
  color: #fff;
`;

export const BIcon = styled.i`
  font-size: 2rem;
  width:20px;
  color: cornflowerblue;
`;

export const StyledAppBar = styled(AppBar)`
  background-color: ${Colors.new_primary};
`;
