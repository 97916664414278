import styled from 'styled-components';
import MCard from '@material-ui/core/Card';

export const Card = styled(MCard)`
  padding: 10px;
  margin: 10px 0px;
`;

export const ImageContent = styled.div``;
export const Image = styled.img`
  width: 100%;
  height: auto;
`;
export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;
