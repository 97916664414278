import React from 'react';

import { ButtonContainer, ButtonContainerBorder } from './styles';

export default function Button({children, colorButton, colorText, icon, alt="Botão com Imagem", ...rest}) {
  return (
    <ButtonContainer colorButton={colorButton} colorText={colorText} {...rest}>
      {icon && <img src={icon} alt={alt}/>}
      {children}
    </ButtonContainer>
  );
}

export function ButtonBorder({children, borderColor, colorText, icon, alt="Botão com Imagem",  ...rest}) {
  return (
    <ButtonContainerBorder borderColor={borderColor} colorText={colorText} {...rest}>
      {icon && <img src={icon} alt={alt}/>}
      {children}
    </ButtonContainerBorder>
  );
}

