import React from 'react';

import { Container, Content, Item } from './styles';

function About({ member }) {
  return (
    <Container maxWidth="lg">
      <Content>
        <Item>
          <h3>
            Experiência profissional:
            <p>{member.professional_experience}</p>
          </h3>
        </Item>

        <Item>
          <h3>Consulta Pessoal: </h3>
          <p>{member.personal_consultation ? 'SIM' : 'NÃO'}</p>
        </Item>
        <Item>
          <h3>Consulta a Domicilio: </h3>
          <p>{member.home_consultation ? 'SIM' : 'NÃO'}</p>
        </Item>
        <Item>
          <h3>Aceita Plano de Saúde:</h3>
          <p>{member.health_plan ? 'SIM' : 'NÃO'}</p>
        </Item>

        <Item>
          <h3>Telefone Pessoal:</h3>
          <p>{member.personal_phone}</p>
        </Item>

        <Item>
          <h3>CNPJ:</h3>
          <p>{member.cnpj}</p>
        </Item>

        <Item>
          <h3>Nome Fantasia:</h3>
          <p>{member.fantasy_name}</p>
        </Item>
        <Item>
          <h3>Numero de Registro/CRM:</h3>
          <p>{member.registration_number_crm}</p>
        </Item>
      </Content>
    </Container>
  );
}

export default About;
