import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import createStore from './createStore';
import persistReducers from './persistReducers';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

// const middlewares = [];

// const sagaMonitor =
//   process.env.NODE_ENV === 'development'
//     ? console.tron.createSagaMonitor()
//     : null;

// const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

// middlewares.push(sagaMiddleware);

// const store = createStore(persistReducers(rootReducer), middlewares);
// const persistor = persistStore(store);

// sagaMiddleware.run(rootSaga);

// export { store, persistor };
const sagaMonitor = process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;
const sagaMiddlware = createSagaMiddleware({ sagaMonitor });

const middlewares = [sagaMiddlware];

const store = createStore(persistReducers(rootReducer), middlewares);
const persistor = persistStore(store);

sagaMiddlware.run(rootSaga);

export { store, persistor };