import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Colors } from '../../../theme';
import {format,parseISO} from 'date-fns'

import {
  Container,
  Content,
  Title,
  CardContainer as Card,
  TitleCard,
  ValueCard,
  ValueContainer,
  DateInput,
  FormAccess,
  ButtonSearch
} from './styles';

import api from '../../../services/api';

const INITIAl_STATE = { total_clinic_users: 0, total_medic_users: 0 };

export default function InformationPage() {
  const [count, setCount] = useState(INITIAl_STATE);
 
  const [start_date,setStart_date] = useState('');
  const [end_date,setEnd_date] = useState('');

  const [accessCount, setAccessCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState({
    all: 0,
    unread: 0,
  });

  const getMessagesFromApi = async () => {
    try {
      const response = await api.get(`contacts`);
      const contacts = response.data.contacts;
      const unread = contacts.filter((i) => i.status === 'Não visualizado');

      setMessagesCount({
        all: contacts.length,
        unread: unread.length,
      });
    } catch (error) {}
    return;
  };

  const getAccessFromApi = async () => {
    try {

      const response = await api.get(`impressions`);
      setStart_date('');
      setEnd_date('');
      setAccessCount(response.data.impressions)
    } catch (error) {
      console.log(error)
    }
  }

  const getCountFromApi = async () => {
    try {
      if (count === INITIAl_STATE) {
        const response = await api.get('/dashboards/admin');
        setCount(response.data);
        await getMessagesFromApi();
        await getAccessFromApi();
      }
    } catch (error) {
      console.log(error);
      toast.warn('Ocorreu um erro ao carregar as informações');
    }
  };
  const changeDate = (name, value) =>{
    try {
      const newDate = format(parseISO(value),'dd/MM/yyyy')
      if(name === 'start_date'){
        setStart_date(newDate)
      }
      if(name === 'end_date'){
        setEnd_date(newDate)
      }
     
    } catch (error) {
      console.log(error)
    }
  }
  const getFilteredAccess = async () =>{
    try {
     
      const response = await api.get(`impressions?start_date=${start_date}&end_date=${end_date}`);
      
      
       setAccessCount(response.data.impressions)
    } catch (error) {
      toast.warn(error.response.data.msg)
      console.log(error)
    }
  }

  useEffect(() => {
    getCountFromApi();
  }, []);
  return (
    <Container>
      <Title>Início</Title>
      <FormAccess>
        <h3>Filtro de acessos por período</h3>
        <div>
          <Card style={{padding:10}}>
            <label>Data Inicial</label>
            <DateInput type="date" value={start_date} onChange={e => changeDate('start_date', e.target.value)}/>
          </Card>
          <Card style={{padding:10}}>
            <label>Data Final</label>
            <DateInput type="date" value={end_date} onChange={e => changeDate('end_date', e.target.value)}/>
          </Card>
        </ div>
        <div>

          <ButtonSearch onClick={getFilteredAccess}>Buscar visitas</ButtonSearch>
          <ButtonSearch type="error" onClick={getAccessFromApi}>Limpar filtro</ButtonSearch>
        </ div>
      </FormAccess>
     
     
      <Content>
      { accessCount && (                   
           <Card color={Colors.success}>             
              <TitleCard>Quantidade de acessos</TitleCard>
              <ValueContainer>
                <ValueCard>{accessCount}</ValueCard>
              </ValueContainer>
            </Card>
        
         )}

      </Content>
      <Content>
        {[
          {
            title: 'Clínicas Cadastradas',
            count: count.total_clinic_users,
            color: Colors.lightblue01,
          },
          {
            title: 'Médicos Cadastrados',
            count: count.total_medic_users,
            color: Colors.success,
          },
        ].map((item) => (
          <Card color={item.color}>
            <TitleCard>{item.title}</TitleCard>
            <ValueContainer>
              <ValueCard>{item.count}</ValueCard>
            </ValueContainer>
          </Card>
        ))}
      </Content>
      <Content>
        {messagesCount.all > 0 && (
          <Card color={Colors.secondary}>
            <TitleCard>Mensagens recebidas</TitleCard>
            <ValueContainer>
              <ValueCard>{messagesCount.all}</ValueCard>
            </ValueContainer>
          </Card>
        )}
        {messagesCount.unread > 0 && (
          <Card color={Colors.warn}>
            <TitleCard>Mensagens não lidas</TitleCard>
            <ValueContainer>
              <ValueCard>{messagesCount.unread}</ValueCard>
            </ValueContainer>
          </Card>
        )}
         
      </Content>
     
    </Container>
  );
}
