import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Avatar } from '@material-ui/core';

import EditAttributesOutlinedIcon from '@material-ui/icons/EditOutlined';

// import './styles.css';

const Dropzone = ({ onFileUploaded, value }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      // console.log(acceptedFiles);
      const file = acceptedFiles[0];

      const fileUrl = URL.createObjectURL(file);
      setSelectedFileUrl(fileUrl);
      onFileUploaded(file);
    },
    [onFileUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });
  return (
    <div
      className="dropzone"
      {...getRootProps()}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <input {...getInputProps()} accept="image/*" />

      <>
        <Avatar
          alt="Foto do médico"
          src={selectedFileUrl ? selectedFileUrl : value}
          style={{ width: 120, height: 120, marginRight: '20px' }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: '0px',
            right: '25px',
            backgroundColor: 'lightgoldenrodyellow',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            border: '1px solid #000',
          }}
        >
          <EditAttributesOutlinedIcon />
        </div>
      </>
    </div>
  );
};

export default Dropzone;
