import styled from 'styled-components'
import Colors from '../../theme/Colors'

export const ContainerContent = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background: ${Colors.background};
`
