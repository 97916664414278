import styled from 'styled-components';
import Colors from '../../theme/Colors';

export const Container = styled.div`
  background: ${Colors.primary};
  padding: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

export const ContainerText = styled.div`
  p{
    text-align: center;
    color: #fff;
    line-height: 20px;
    font-size: 13;
    /* font-family: 'Roboto' */
  }
`;

export const ContainerImagem = styled.div`
  @media (max-width: 665px) {
    margin-bottom: 40px;
  }
`;