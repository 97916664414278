import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Container,
  Content,
  CardPlan,
  PlanTitle,
  PageTitle,
  ValuePlan,
  StatusContainer,
  StatusText,
  ButtonGroup,
  Button,
} from './styles';
import api from '../../../services/api';

function Signatures() {
  const [status, setStatus] = useState({
    id: null,
    message: '',
    payment_status: '',
  });
  const { type_user } = useSelector((state) => state.auth);
  const history = useHistory();
  const handleCreateSignature = () => {
    history.push('payment-page');
  };
  const handleCancelSignature = () => {
    history.push('cancel-plan');
  };
  const verifyStatus = async () => {
    try {
      const res = await api.get('users/status_signature');
      console.log(res);
      setStatus(res.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    verifyStatus();
  }, []);
  return (
    <>
      <PageTitle>Gerenciamento de Plano</PageTitle>
      <Container>
        <Content>
          <CardPlan>
            <PlanTitle>Plano médico</PlanTitle>
            <ValuePlan>
              {type_user === 'clinic' ? 'R$ 99,90' : 'R$ 49,90'}
            </ValuePlan>
          </CardPlan>
        </Content>
        <Content>
          <StatusContainer>
            <h3>Status:</h3>
            <StatusText signatureStatus={status.payment_status === 'cancelled'}>
              {status.message}
            </StatusText>
          </StatusContainer>
          <ButtonGroup>
            <div>
              <Button
                type="button"
                color="success"
                onClick={handleCreateSignature}
              >
                Adesão de plano
              </Button>
              {status.payment_status !== 'cancelled' && (
                <Button
                  type="button"
                  onClick={handleCancelSignature}
                  color="error"
                >
                  Cancelamento de Assinatura
                </Button>
              )}
            </div>
          </ButtonGroup>
        </Content>
      </Container>
    </>
  );
}

export default Signatures;

/**
 *
 */
