import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from '@material-ui/core';
// import { Container } from './styles';

import { TextName, TextStatus, ButtonDetail } from './styles';

function TableData({ doctors, handleStatus, handleDetail }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={'table'} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Pagamento</TableCell>
              <TableCell align="center">Registro</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer component={Paper}>
        <Table className={'table'} aria-label="simple table">
          <TableBody>
            {doctors.map((clinics) => (
              <TableRow key={clinics.id} hover>
                <TableCell align="left" component="th" scope="row">
                  <Avatar
                    style={{ width: 50, height: 50, marginRight: '20px' }}
                    alt="Foto do médico"
                    src={`${clinics?.profile_image}`}
                  />
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <TextName>{clinics?.name}</TextName>
                </TableCell>
                <TableCell align="center">
                  <TextStatus status={clinics?.status}>
                    {handleStatus(clinics?.status)}
                  </TextStatus>
                </TableCell>
                <TableCell align="center">
                  <TextStatus status={clinics?.is_public?'approved':clinics?.payment_status}>
                    {clinics?.is_public? 'Isenta de pagamento':clinics?.message}
                  </TextStatus>
                </TableCell>
                <TableCell align="right">
                  <TextName>{clinics?.formattedDate}</TextName>
                </TableCell>
                <TableCell align="center" style={{ padding: 0, margin: 0 }}>
                  <ButtonDetail onClick={() => handleDetail(clinics?.id)}>
                    Vizualizar
                  </ButtonDetail>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableData;
