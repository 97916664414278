import styled from 'styled-components';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AppBar from '@material-ui/core/AppBar';
import { Colors } from '../../../theme/index';
import { List as MList } from '@material-ui/core';

export const LogoImg = styled.img`
  width: 50%;
  margin-right: 20%;
`;

export const Icon = styled(ChevronLeftIcon)`
  color: #fff;
`;

export const BIcon = styled.i`
  font-size: 2rem;
  width: 20px;
  color: cornflowerblue;
`;

export const StyledAppBar = styled(AppBar)`
  background-color: ${Colors.new_primary};
`;


