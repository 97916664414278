import { produce } from 'immer';

const INITIAL_STATE = {
  doctors: [],
  pending_payment: [],
  pending: [],
  cancelled: [],
  loading: true,
};

export default function doctor(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@doctor/GET_ALL_DOCTORS_LIST_SUCCESS':
        draft.doctors = action?.payload?.doctors;
        draft.pending_payment = action?.payload?.pending_payment;
        draft.pending = action?.payload?.pending;
        draft.cancelled = action?.payload?.cancelled;
        draft.loading = false;
        break;

      default:
        break;
    }
  });
}
