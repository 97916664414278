import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import axios from 'axios'
import { getDoctorsToOpenRoutes } from '../../../store/modules/doctor/actions';

import {
  Body,
  Section,
  ContainerForm,
  Container,
  Column,
  Square,
  ContainerSection,
  ContainerPrice,
  ContainerTextPrice,
  CardPrice,
  HeaderCardPrice,
  BodyCardPrice,
  FooterCardPrice,
  ContainerFreeTest,
  ContainerContact,
  Input,
  MytextField,
  HealthPlansSelector,
} from './styles';
import { Colors } from '../../../theme';
import Button, { ButtonBorder } from '../../../components/button';
import LogoSearch from '../../../assets/images/logo-search.png';
import LogoSearchBig from '../../../assets/images/logo-search-big.png';
import Shild from '../../../assets/images/shild.png';
import Man from '../../../assets/images/man-user.png';
import Contact from '../../../assets/images/contact.png';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import api from '../../../services/api';
import TextField from '@material-ui/core/TextField';

import ScrollToTop from '../../../components/backToTop'


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    background: '#fff',
    border: 'none',
    borderRadius: 5,
    marginBottom: '1em',
    // padding: 10px 15px,
    // paddingTop: 10,
    // paddingBottom: 10,
    paddingLeft: 15,
    // paddingRight: 15,
    boxShadow: 'rgb(204 204 204) 0px 1px 10px 0px',
    margin: 0,
    borderWidth: 0,
  },
  select: {
    '&:before': {
      borderColor: '#fff',
    },
    '&:after': {
      borderColor: '#fff',
    },
  },
  icon: {
    fill: '#000',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Main() {
  const aboutRef = useRef(null);
  const pricetRef = useRef(null);
  const contactRef = useRef(null);
  const topRef = useRef(null)
  const classes = useStyles();
  let history = useHistory();

  const goToAboutRef = () => aboutRef.current.scrollIntoView({behavior: "smooth"});
  const goToPricetRef = () => pricetRef.current.scrollIntoView({behavior: "smooth"});
  const goToContactRef = () => contactRef.current.scrollIntoView({behavior: "smooth"});
  const goTopRef = () => topRef.current.scrollIntoView({behavior: "smooth"});

  const [age, setAge] = useState('');
  const [statePlan, setstatePlan] = useState('');
  // const [state, setstate] = useState('');
  const [medicalHealthPlans, setmedicalHealthPlans] = useState([]);
  const [cities, setcities] = useState([]);
  const [citySelect, setcitySelect] = useState('');
  const [searchParam, setsearchParam] = useState('');
  const [medicalHealthPlansAttributes, setmedicalHealthPlansAttributes] =
    useState([]);
  const [showHealthPlans, setshowHealthPlans] = useState(false);
  const [filterMoreList, setfilterMoreList] = useState([]);
  const [accessCount,setAccessCount] = useState(0);

  const [specialtys, setSpecialtys] = useState([]);
  const [crm, setCrm] = useState('');
  const [isVisible, setIsVisible] = useState(0);
  

  const dispatch = useDispatch();

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {  
    console.log('foi')
      setIsVisible(window.pageYOffset);
    
  };

  // Set the top cordinate to 0
  // make scrolling smooth
 

  const getMedicalHealthPlans = async () => {
    try {
      const response = await api.get(`medical_health_plans`);
      //   console.log('response', response);
      setmedicalHealthPlans(response.data.health_plans);
    } catch (error) {
      // console.log('error', error);
    }
  };

  const handleChange = (event) => {
    //  console.log(`event.target.value`, event.target.value);
    setstatePlan(event.target.value);
  };

  const handleChangePlans = (event) => {
    // console.log(`event`, event.target.value);
    const name = event.target.name;
    setstatePlan({
      ...statePlan,
      [name]: event.target.value,
    });
  };

  const getCities = async () => {
    try {
      const response = await api.get(`cities`);
      //console.log('response', response);
      setcities(response.data.cities);
    } catch (error) {
      // console.log('error', error);
    }
  };
  const getSpecialtys = async () => {
    try {
      const getSpacialtiesData = () => api.get(`medical_specialtys`);
      const getExamsData = () => api.get(`medical_exams`);

      const [specialtysResponse, examsResponse] = await Promise.all([
        getSpacialtiesData(),
        getExamsData(),
      ]);

      // console.log('response', specialtysResponse, examsResponse);
      setSpecialtys([
        ...specialtysResponse.data.medical_specialties,
        ...examsResponse.data.medical_exams,
      ]);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleChangeState = (event, values) => {
    setcitySelect(values);
  };

  const filterMore = [
    { name: 'Plano de saúde', value: 0 },
    { name: 'Consulta particular', value: 1 },
    { name: 'Consulta domiciliar', value: 2 },
  ];

  const getFilterMore = (value) => {
    // console.log(`value`, value);
    /*console.log(
      `value.findIndex(value => value == 0);`,
      value.findIndex((res) => res.value == 0)
    );*/
    setfilterMoreList(value);
    if (
      value.findIndex((res) => res.value == 0) == 0 ||
      value.findIndex((res) => res.value == 0) > 0
    ) {
      setshowHealthPlans(true);
    } else {
      setstatePlan('');
      setshowHealthPlans(false);
    }
  };

  const getOptionLabelHealth = (props) => {
    // console.log(`props`, props);
    setmedicalHealthPlansAttributes(props);
  };

  const goToSearch = () => {
    const citysSelects = citySelect?.id != undefined ? citySelect?.id : ' ';
    const plans = statePlan == undefined || statePlan == '' ? ' ' : statePlan;
    let personal_consultation =
      filterMoreList.some((res) => res.value == 1) == true ? true : ' ';
    let home_consultation =
      filterMoreList.some((res) => res.value == 2) == true ? true : ' ';

    console.log('searchParam', searchParam);

    dispatch(
      getDoctorsToOpenRoutes({
        city: citysSelects,
        home_consultation,
        personal_consultation,
        plan: plans,
        searchParam,
        crm,
      })
    );

    /*`/pesquisar${citysSelects}/searchParam=${
        searchParam == '' ? ' ' : searchParam
      }${plans}${personal_consultation}${home_consultation}`
      */

    history.push(`/pesquisar`);
  };
  

  const contactForm = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const name = event.target.name.value;
    const story = event.target.story.value;

    const valid = [name, email, story].findIndex((i) => i === '');
    // console.log(valid);
    if (valid >= 0) {
      toast.error('complete os dados');
      return;
    }
    try {
      await api.post('/contacts', {
        email,
        name: name,
        message: story,
      });

      toast.success('Enviado com sucesso');
      event.target.email.value = '';
      event.target.name.value = '';
      event.target.story.value = '';
    } catch (error) {
      toast.warn('Aconteceu um erro ao enviar');
      console.log(error);
    }
  };
  const handleAccessCounter = async () =>{
    try {
      const { data } = await axios.get('https://api.ipify.org?format=json');
      const {ip} = data;

      await api.post('impressions',{
          impression: {
              ip_address: ip
          }      
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAccessFromApi = async () => {
    try {

      const response = await api.get(`impressions`);
    
      setAccessCount(response.data.impressions)
    } catch (error) {
      console.log(error)
    }
  }


   
  useEffect(() => {   

    window.addEventListener('scroll',toggleVisibility)

    getMedicalHealthPlans();
    getCities();
    getSpecialtys();
    handleAccessCounter()
    getAccessFromApi();

  }, []);

  return (
   
    <div style={{ position:'relative' }}>    

       <ScrollToTop  scrollToElement={goTopRef}/>
      
      {/* <button onClick={executeScroll}> Click to scroll </button> */}
      <Header
    
        goToAboutRef={goToAboutRef}
        goToPricetRef={goToPricetRef}
        goToContactRef={goToContactRef}
        goToDoctorRate={goToSearch}
      />
      
      <Body>
      
        <Section  ref={topRef} style={{zIndex:110}}>
          <ContainerForm>
            <h1>Ache agora o seu médico ou exame.</h1>
            <form>
              <Input
                value={crm}
                onChange={(event) => setCrm(event.target.value)}
                placeholder="Busque por Crm"
                />

              <Autocomplete
                id="combo-box-demo2"
                onChange={(_, values) => setsearchParam(values.name)}
                options={specialtys}
                closeIcon
                getOptionLabel={(option) => option?.name}
                style={{
                  background: '#fff',
                  marginBottom: 10,
                }}
                renderInput={(params) => (
                  <TextField
                  {...params}
                  value={searchParam}
                  fullWidth
                  label="Especialidade ou exame"
                  variant="outlined"
                  />
                  )}
                  />
              {/* <Input placeholder="Cidade"/> */}

              <Autocomplete
                id="combo-box-demo"
                onChange={handleChangeState}
                options={cities}
                closeIcon
                getOptionLabel={(option) => option?.name}
                style={{
                  background: '#fff',
                  marginBottom: 10,
                }}
                renderInput={(params) => (
                  <TextField
                  {...params}
                  fullWidth
                  label="Cidade"
                  variant="outlined"
                  />
                  )}
                  />

              {/* <FormControl className={classes.formControl}>
                <NativeSelect
                className={classes.selectEmpty}
                value={state}
                name="age"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'age' }}
                >
                <option value="" disabled>
                Mais Itens
                </option>
                <option value={10}>Plano de saúde</option>
                <option value={20}>Consulta particular</option>
                <option value={30}>Consulta domiciliar</option>
                </NativeSelect>
              </FormControl> */}

              <FormControl className={classes.formControl}>
                <Grid item xs={12} sm={12} xm={12}>
                  <Autocomplete
                    multiple
                    id="tags-standard2"
                    options={filterMore}
                    closeIcon
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, value) => getFilterMore(value)}
                    renderInput={(params) => (
                      <MytextField
                      {...params}
                      variant="outlined"
                      label="Mais Itens"
                      placeholder="Selecione"
                      />
                      )}
                      />
                </Grid>
              </FormControl>

              {showHealthPlans && (
                <FormControl className={classes.formControl}>
                  <Grid item xs={12} sm={12}>
                    <HealthPlansSelector
                      className="tags-standard3"
                      value={statePlan.name}
                      name="age"
                      onChange={handleChange}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'age' }}
                      style={{
                        padding: 10,
                      }}
                      >
                      <option value="">Mais Itens</option>
                      {medicalHealthPlans &&
                        medicalHealthPlans.map((plan) => (
                          <option value={plan?.id}>{plan?.name}</option>
                          ))}
                      {/* <option value={10}>Plano de saúde</option>
                    <option value={20}>Consulta particular</option>
                  <option value={30}>Consulta domiciliar</option> */}
                    </HealthPlansSelector>
                  </Grid>
                </FormControl>
              )}
              <Link>
                <Button onClick={() => goToSearch()} icon={LogoSearch}>
                  Pesquisar
                </Button>
              </Link>
            </form>
          </ContainerForm>
        </Section>
     

        <ContainerSection ref={aboutRef}>
          <Container>
            <Column>
              <Square>
                <img src={LogoSearchBig} alt={'Logo Guia Doctor'} />
              </Square>
            </Column>
            <Column>
              <h2>
                O Guia Doctor está mudando a maneira de achar médicos e exames.
              </h2>
              <h3 style={{ color: Colors.secondary }}>
                Utilizamos um modelo inovador de publicidade médica com rigor
                ético e legal de forma e conteúdo.
              </h3>
            </Column>
          </Container>

          <Container>
            <Column>
              <h2>Mais segurança para os pacientes.</h2>
              <h3 style={{ color: Colors.secondary }}>
                O médico ou clínica para ter seu cadastro aprovado precisa
                passar por um rigoroso processo de cadastro com auditoria da
                veracidade das informações descritas em seu perfil profissional.
              </h3>
            </Column>
            <Column style={{ marginTop: 20 }}>
              <Square>
                <img src={Shild} alt={'Imagem de Escudo'} />
              </Square>
            </Column>
          </Container>

          {   accessCount>=0 && (

          <Container >
            
            <Column style={{ marginTop: 20 }}>
              <Square>
                <img src={Man} alt={'Imagem de Escudo'} style={{
                  width: '60%'
                }}/>
              </Square>
            </Column>
            <Column>
              <h2>Contando com mais de {accessCount} acessos! </h2>
              <h3 style={{ color: Colors.secondary }}>
                Disponibilizando para pacientes o acesso a informação sobre os especialistas
                que ele busca, assim como a avaliação de médicos por seus pacientes 
              </h3>
            </Column>
          </Container>

          )}
        </ContainerSection>
        

        <ContainerPrice ref={pricetRef}>
          <ContainerTextPrice>
            <h2>Quer estar disponível no Guia Doctor?</h2>
            <h3 style={{ color: Colors.secondary }}>
              O Guia Doctor é totalmente flexível, você pode cancelar a sua
              conta na internet com apenas dois cliques, sem a necessidade de
              pagar cancelamento.
            </h3>
          </ContainerTextPrice>

          <Container>
            <Column>
              <CardPrice backgroundColor={Colors.primary}>
                <HeaderCardPrice>
                  <h2>Médicos</h2>
                </HeaderCardPrice>

                <BodyCardPrice>
                  <div>
                    <span>R$</span>
                    <h1>49,90</h1>
                  </div>

                  <p>Por mês</p>
                </BodyCardPrice>

                <FooterCardPrice>
                  <h3>Perfil disponível na plataforma</h3>
                </FooterCardPrice>
              </CardPrice>
            </Column>

            <Column>
              <CardPrice backgroundColor={'#fff'}>
                <HeaderCardPrice>
                  <h2>Clinicas</h2>
                </HeaderCardPrice>

                <BodyCardPrice>
                  <div>
                    <span>R$</span>
                    <h1>99,90</h1>
                  </div>

                  <p>Por mês</p>
                </BodyCardPrice>

                <FooterCardPrice>
                  <h3>
                    Perfil disponível na plataforma para anúncio de exames.
                  </h3>
                </FooterCardPrice>
              </CardPrice>
            </Column>
          </Container>
        </ContainerPrice>

        <ContainerFreeTest>
          <h1>Os primeiros 2 meses gratuitos para você testar.</h1>
          <ButtonBorder style={{ fontSize: 24 }} onClick={() => history.push(`/cadastro`)}>Começar Agora</ButtonBorder>
        </ContainerFreeTest>

        <ContainerContact ref={contactRef}>
          <h2>Você possui alguma dúvida sobre o Guia Doctor?</h2>
          <h3>
            Sem problemas, utilize o formulário abaixo para entrar em contato
            com um de nossos colaboradores.
          </h3>

          <Container>
            <Column>
              <img src={Contact} alt={'Imagem Ilustrativa para contato'} />
            </Column>

            <Column>
              <form onSubmit={contactForm}>
                <Input placeholder="Nome" name="name" />
                <Input placeholder="E-mail" name="email" />
                <textarea
                  placeholder="Mensagem"
                  id="story"
                  name="story"
                  rows="5"
                  cols="33"
                />
                <Button type="submit">Enviar</Button>
              </form>
            </Column>
          </Container>
        </ContainerContact>
              
        </Body>
      <Footer />
    </div>
    
  );
}
