export function getAllClinicsRequest() {
  return {
    type: '@clinic/GET_ALL_CLINICS_LIST_REQUEST',
  };
}
export function getAllClinicsSuccess({
  clinics,
  pending_payment,
  pending,
  cancelled,
  publicClinics
}) {
  return {
    type: '@clinic/GET_ALL_CLINICS_LIST_SUCCESS',
    payload: { clinics, pending_payment, pending, cancelled,publicClinics},
  };
}
