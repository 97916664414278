import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Container, Content, Title } from './styles';

import { getAllClinicsRequest } from '../../../store/modules/clinic/actions';

import TableData from './table';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function ListClinics() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { clinics, pending_payment, pending, cancelled, loading,publicClinics } = useSelector(
    (store) => store.clinic
  );
  const [filter, setFilter] = useState("1");
  const classes = useStyles();

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  function handleDetail(id) {
    history.push(`/clinic-details/${id}`);
  }
  function handleGetData() {
    if (clinics.length === 0) {
      dispatch(getAllClinicsRequest());
    }
  }

  const handleStatus = (status) => {
    let result;
    switch (status) {
      case 'pending':
        result = 'PENDENTE';
        break;
      case 'pending_payment':
        result = 'PAGAMENTO PENDENTE';
        break;
      case 'approved':
        result = 'APROVADO';
        break;
      default:
        break;
    }
    return result;
  };

  useEffect(() => {
    handleGetData();
  });

  return (
    <Container>
      <Title>Clínicas</Title>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Filtro</InputLabel>
          <Select
            native
            value={filter}
            onChange={handleChange}
            inputProps={{
              name: 'Filtro',
              id: 'age-native-simple',
            }}
          >
            <option aria-label="Todos" value={1}>
              Todos
            </option>
            <option value={2}>Usuário Pendente</option>
            <option value={3}>Pagamento Pendente</option>
            <option value={4}>Plano Cancelado</option>
            <option value={5}>Estabelecimento Publico</option>
          </Select>
        </FormControl>
      </div>

      <Content>
        {loading && <h2>Loading...</h2>}
        {filter && filter === '1' && (
          <TableData
            doctors={clinics}
            handleDetail={handleDetail}
            handleStatus={handleStatus}
          />
        )}
        {filter && filter === '2' && (
          <TableData
            doctors={pending}
            handleDetail={handleDetail}
            handleStatus={handleStatus}
          />
        )}
        {filter && filter === '3' && (
          <TableData
            doctors={pending_payment}
            handleDetail={handleDetail}
            handleStatus={handleStatus}
          />
        )}
        {filter && filter === '4' && (
          <TableData
            doctors={cancelled}
            handleDetail={handleDetail}
            handleStatus={handleStatus}
          />
        )}
        {filter && filter === '5' && (
          <TableData
            doctors={publicClinics}
            handleDetail={handleDetail}
            handleStatus={handleStatus}
          />
        )}
      </Content>
    </Container>
  );
}

export default ListClinics;
