/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';

// import MailOutlineIcon from '@material-ui/icons/MailOutlineOutlined';
import MarkunreadIcon from '@material-ui/icons/DraftsOutlined';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
  Container,
  Content,
  List,
  Text,
  CommentContainer,
  TitleComment,
  Actions,
  FilterContainer,
} from './styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Contact() {
  const [contacts, setContacts] = useState([]);
  const [data, setData] = useState({
    all: [],
    read: [],
    unread: [],
  });
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('1');

  const classes = useStyles();

  const getMessagesFromApi = async () => {
    try {
      setLoading(true);
      const response = await api.get(`contacts`);
      const contacts = response.data.contacts;
      const unread = contacts.filter((i) => i.status === 'Não visualizado');
      const read = contacts.filter((i) => i.status === 'Visualizado');

      setData({ all: contacts, read, unread });
      setContacts(contacts);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const selected = event.target.value;
    setFilter(selected);

    if (selected === '1') {
      setContacts(data.all);
    }
    if (selected === '2') {
      setContacts(data.read);
    }
    if (selected === '3') {
      setContacts(data.unread);
    }
  };

  const markAsRead = async (id) => {
    try {
      await api.patch(`contacts/${id}/change_status`);
      // console.log(response);
      toast.success('Mensagem marcada como lida');
      await getMessagesFromApi();
    } catch (error) {
      toast.warn('aconteceu um erro ao marcar como lida');
      console.log(error);
    }
  };

  useEffect(() => {
    getMessagesFromApi();
  }, []);

  return (
    <Container>
      <FilterContainer>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Filtro</InputLabel>
          <Select
            native
            value={filter}
            onChange={handleChange}
            inputProps={{
              name: 'Filtro',
              id: 'age-native-simple',
            }}
          >
            <option aria-label="Todos" value={1}>
              Todas
            </option>
            <option value={2}>Lidas</option>
            <option value={3}>Não lidas</option>
          </Select>
        </FormControl>
      </FilterContainer>
      <Content>
        {!loading &&
          contacts.map((item) => (
            <List key={item.id}>
              <Text>
                <strong>Nome:</strong>
                {item.name}
              </Text>
              <Text>
                <strong> Email:</strong>
                {item.email}
              </Text>

              <CommentContainer>
                <TitleComment>Mensagem</TitleComment>
                {item.message}
              </CommentContainer>
              <Actions>
                {item.status === 'Não visualizado' && (
                  <button onClick={() => markAsRead(item.id)}>
                    <MarkunreadIcon />
                    Marcar como lido
                  </button>
                )}
              </Actions>
            </List>
          ))}
        {!loading && data.length <= 0 && (
          <List>
            <Text>Ainda não existem mensagens recebidas</Text>
          </List>
        )}

        {loading && (
          <List>
            <Text>Carregando mensagens recebidas</Text>
          </List>
        )}
      </Content>
    </Container>
  );
}

export default Contact;
