import React, { Fragment, useEffect, useState } from 'react';
import { CardStyleButton, ContainerCardForm } from './styles';
import { makeStyles, Button } from '@material-ui/core';
import api from '../../../services/api';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function FormAddress({ next, handleData, data }) {
  const [state, setState] = useState(data.state);
  const [stateList, setstateList] = useState([]);

  // Endereco
  const [street, setstreet] = useState(data.street);
  const [city, setcity] = useState(data.city);
  const [zipcode, setzipcode] = useState(data.zipcode);
  const [district, setdistrict] = useState(data.district);
  const [number, setnumber] = useState(data.number);
  const [complement, setcomplement] = useState(data.complement);
  const [cityList, setcityList] = useState([]);

  const [complementValidate, setcomplementValidate] = useState(false);
  const [districtValidate, setdistrictValidate] = useState(false);
  const [numberValidate, setnumberValidate] = useState(false);
  const [streetValidate, setstreetValidate] = useState(false);
  const [zipcodeValidate, setzipcodeValidate] = useState(false);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const getUf = async () => {
    try {
      const response = await api.get('states?state_id=&filter_by_state=');
      // console.log('response', response)
      const dataUf = response.data.states;
      setstateList(dataUf);
    } catch (error) {
      // console.log('error', error)
    }
  };

  const handleChangeState = (event) => {
    const value = parseInt(event.target.value);
    const id = event.target.id;
    const name = event.target.name;
    const uf = event.target.uf;
    // console.log('event.target.uf', event.target)
    const getStateArray = stateList.filter((state) => state?.id === value);
    // console.log('getStateArray', getStateArray)
    setState({
      [id]: getStateArray[0].id,
      [name]: getStateArray[0].name,
      uf: getStateArray[0].uf,
    });
  };

  const getZipCode = async () => {
    try {
      const response = await api.get(`cities/search_cep?cep=${zipcode}`);
      const dataCitie = response.data;
      console.log('response', dataCitie);
      setstreet(dataCitie?.street);
      setdistrict(dataCitie?.district);

      setState({
        id: dataCitie?.state?.id,
        name: dataCitie?.state?.name,
        uf: dataCitie?.state?.uf,
      });

      await getCity();
      setcity({
        id: dataCitie?.city?.id,
        name: dataCitie?.city?.name,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCity = async () => {
    try {
      const response = await api.get(
        `cities?part_text=&city_id=&state_id=${state?.id}`
      );
      const dataCity = response.data.cities;
      // console.log('response', dataCity)
      setcityList(dataCity);
    } catch (error) {
      // console.log('error', error)
    }
  };

  const handleChangeCity = (event) => {
    const value = parseInt(event.target.value);
    const id = event.target.id;
    const name = event.target.name;
    const getStateArray = cityList.filter((state) => state.id === value);
    setcity({
      [id]: getStateArray[0].id,
      [name]: getStateArray[0].name,
    });
  };

  const nextSteep = async () => {
    const params = {
      street: street,
      number: number,
      complement: complement,
      zipcode: zipcode,
      district: district,
      city: city,
      state: state,
    };

    handleData(params);
    next();
    console.log('params', params);
    // console.log('data', userDataRedux.type_user);
  };

  const validateForm = () => {
    if (zipcode === '') {
      setzipcodeValidate(true);
      return;
    }
    if (district === '') {
      setdistrictValidate(true);
      return;
    }
    if (number === '') {
      setnumberValidate(true);
      return;
    }
    if (street === '') {
      setstreetValidate(true);
      return;
    }
    if (complement === '') {
      setcomplementValidate(true);
      return;
    }

    setdistrictValidate(false);
    setnumberValidate(false);
    setstreetValidate(false);
    setcomplementValidate(false);

    nextSteep();
  };

  const handleCep = (value) => {
    var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;
    let cep = value;
    let formattedCep = '';
    if (re.test(cep)) {
      cep = cep.replace(re, '$1.$2-$3');
    }
    formattedCep = cep.replace('.', '');
    setzipcode(formattedCep);
    console.log(formattedCep);
  };

  useEffect(() => {
    getUf();
  }, []);

  return (
    <ContainerCardForm style={{ display: 'flex', flexDirection: 'column' }}>
      <CardStyleButton
        style={{ padding: 20, maxWidth: 'none', marginRight: 10 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              value={zipcode}
              onChange={(event) => handleCep(event.target.value)}
              required
              id="zip"
              name="zip"
              label="CEP"
              onBlur={getZipCode}
              fullWidth
              helperText={zipcodeValidate && 'Campo Obrigatório.'}
              error={zipcodeValidate}
              // autoComplete="shipping postal-code"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              required
              value={street}
              onBlur={() => console.log(city, state)}
              onChange={(event) => setstreet(event.target.value)}
              id="street-address"
              name="street-address"
              label="Rua"
              fullWidth
              helperText={streetValidate && 'Campo Obrigatório.'}
              error={streetValidate}
              // autoComplete="shipping street-address"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextField
              required
              value={number}
              onChange={(event) => setnumber(event.target.value)}
              id="number"
              name="number"
              label="Número"
              fullWidth
              helperText={numberValidate && 'Campo Obrigatório.'}
              error={numberValidate}
              // autoComplete="shipping"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextField
              required
              value={complement}
              onChange={(event) => setcomplement(event.target.value)}
              id="complement"
              name="complement"
              label="Complemento"
              fullWidth
              helperText={complementValidate && 'Campo Obrigatório.'}
              error={complementValidate}
              // autoComplete="shipping"
            />
          </Grid>

          <Grid item xs={6} sm={12}>
            <TextField
              required
              value={district}
              onChange={(event) => setdistrict(event.target.value)}
              id="district"
              name="district"
              label="Bairro"
              fullWidth
              helperText={districtValidate && 'Campo Obrigatório.'}
              error={districtValidate}
              // autoComplete="shipping address-level4"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">UF</InputLabel>
              <Select
                native
                value={stateList?.name}
                onChange={handleChangeState}
                onBlur={getCity}
                inputProps={{
                  id: 'id',
                  name: 'name',
                  uf: 'uf',
                }}
              >
                <option aria-label="None" value="" />
                {stateList &&
                  stateList.map((state) => (
                    <option key={state.name + 1} value={state?.id}>
                      {state?.uf}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">Cidade</InputLabel>
              <Select
                native
                value={cityList?.name}
                onChange={handleChangeCity}
                inputProps={{
                  id: 'id',
                  name: 'name',
                }}
              >
                <option aria-label="None" value="" />
                {cityList &&
                  cityList.map((city) => (
                    <option key={city.name + 1} value={city?.id}>
                      {city?.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardStyleButton>
      <>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={validateForm}
            className={classes.button}
          >
            Próximo
          </Button>
        </div>
      </>
    </ContainerCardForm>
  );
}
