import React from 'react';
import Card from '../../../../components/card';
import {
  BodyCard,
  Container,
  ContainerCard,
  HeaderCard,
  SubTitleCard,
  TitleCard,
} from '../styles';

export default function AddressInformation({ user }) {
  return (
    <>
      {user?.addresses && (
        <ContainerCard>
          <Card>
            <HeaderCard>
              <TitleCard>Endereços</TitleCard>
            </HeaderCard>
            <BodyCard>
              {user?.addresses.map((address, index) => (
                <SubTitleCard key={index}>
                  {address?.street && `${address?.street}`}
                  {address?.number && `, N ${address?.number}`}
                  {address?.zipcode && `, ${address?.zipcode}`}
                  {address?.district && `, ${address?.district}`}
                  {address?.city?.name && `, ${address?.city?.name}`}
                  {address?.state?.uf && `, ${address?.state?.uf}`}
                </SubTitleCard>
              ))}
            </BodyCard>
          </Card>
        </ContainerCard>
      )}
    </>
  );
}
