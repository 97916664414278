import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  border-radius: 7px;
  flex-direction: column;
  min-width: 350px;
  max-width: 750px;
  background: #fff;
  box-shadow: 0px 1px 10px #CCCCCC;
  width: 100%;

  /* h5 {font-weight: normal;}

  p {font-size: 12px;} */
`;

// export const Header = styled.div`
  
// `;
// export const Body = styled.div`
  
// `;
// export const Container = styled.div`
  
// `;
