import React, {Fragment, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './addressForm';
import PaymentForm from './paymentForm';
import Review from './review';
import LoginDataForm from './loginDataForm';
import InfoTecnicForm from './infoTecnicForm';
import api from "../../../../services/api";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ExaminationClinics() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [dataInfo, setdataInfo] = useState({
    user: {
      username: null,
      password: null,
      password_confirmation: null,
      is_public: true,
      member_attributes: {
        social_name: null,
        cnpj: null,
        fantasy_name: null,
        registration_number_crm: null,
        name: null,
        crm: null,
        crm_uf_id: null,
        professional_experience: null,
        personal_consultation: null,
        health_plan: null,
        personal_phone: null
      },
      addresses_attributes: [
        {
            street: null,
            number: null,
            complement: null,
            zipcode: null,
            district: null,
            city_id: null,
            state_id: null,
            phone: null
        }
      ],
      medical_health_plans_attributes: [
        {
            health_plan_id: null
        },
      ],
      medical_clinic_exams_attributes: [
        {
            medical_exam_id: null
        },
      ]
    }
  })

  // console.log('dataInfo', dataInfo)

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    console.log('dataInfo', dataInfo)
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const steps = ['Login', 'Técnicas', 'Endereço', 'Finalizar'];

  // function getStepContent(step) {
  //   switch (step) {
  //     case 0:
  //       return <LoginDataForm handleNext={handleNext} handleBack={handleBack} />;
  //     case 1:
  //       return <InfoTecnicForm handleNext={handleNext} handleBack={handleBack} />;
  //     case 2:
  //       return <AddressForm handleNext={handleNext} handleBack={handleBack} />;
  //     case 3:
  //       return <Review handleNext={handleNext} handleBack={handleBack} />;
  //     default:
  //       throw new Error('Unknown step');
  //   }
  // }
 

  return (
    <Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper style={{height: '100%', maxHeight: 600, overflowY: 'scroll' }} className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
          Estabelecimento de Saúde Pública
          </Typography>
          <Fragment>
            <Fragment>
              <LoginDataForm handleNext={handleNext} handleBack={handleBack} />
            </Fragment>
          </Fragment>
        </Paper>
      </main>
    </Fragment>
  );
}