import styled from 'styled-components';
import Colors from '../../../../theme/Colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: initial;
  flex-wrap: wrap;
  flex-direction: row;
  border: solid ${Colors.primary} 1px;
  border-radius: 7px;
  min-width: 350px;
  max-width: 750px;
  background: #fff;
`;

export const Buttom = styled.button`
  padding: 20px 10px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  :first-child{
    border-radius: 5px 0px 0px 5px;
  }
  :last-child{
    border-radius: 0px 5px 5px 0px;
  }
  
`;
