import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { Card, ButtonDetail } from './styles';
import api from '../../../../services/api';

import DocumentDropzone from '../../../../components/documentDropzone';
import { encodeImageFileToBase64 } from '../../../../util/imageToBase64';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function DocInformation({ user }) {
  const [uneditable, setUneditable] = useState(true);

  const [crm, setCrm] = useState(user?.member?.crm_card_image);
  const [address, setAddress] = useState(user?.member?.proof_address_image);
  const [certificate, setCertificate] = useState(
    user?.member?.specialist_certificate_image
  );
  const [validCrm, setValidCrm] = useState(user?.member?.crm_regularity_image);

  const classes = useStyles();

  const handleSubmit = async () => {
    try {
      let address_64, crm_64, certificate_64, valid_64;

      if (typeof address !== 'string') {
        address_64 = await encodeImageFileToBase64(address);
      }
      if (typeof crm !== 'string') {
        crm_64 = await encodeImageFileToBase64(crm);
      }
      if (typeof certificate !== 'string') {
        certificate_64 = await encodeImageFileToBase64(certificate);
      }
      if (typeof validCrm !== 'string') {
        valid_64 = await encodeImageFileToBase64(validCrm);
      }

      const requestData = {
        proof_address_image_data: address_64,
        crm_card_image_data: crm_64,
        specialist_certificate_image_data: certificate_64,
        crm_regularity_image_data: valid_64,
      };

      if (requestData.proof_address_image_data === null) {
        delete requestData.proof_address_image_data;
      }
      if (requestData.crm_card_image_data === null) {
        delete requestData.crm_card_image_data;
      }
      if (requestData.specialist_certificate_image_data === null) {
        delete requestData.specialist_certificate_image_data;
      }
      if (requestData.crm_regularity_image_data === null) {
        delete requestData.crm_regularity_image_data;
      }

      const res = await api.patch(`users/doctor?id=${user.id}`, {
        user: {
          member_attributes: {
            id: user.member.id,
            ...requestData,
          },
        },
      });
      //    console.log(res);

      toast.success('Atualizado com sucesso');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card>
      <Typography variant="h5" gutterBottom>
        Documentação
      </Typography>
      <ButtonDetail size="large" onClick={() => setUneditable(!uneditable)}>
        {uneditable ? 'Editar Dados' : 'Bloquear Campos'}
      </ButtonDetail>

      <Card>
        <div>
          <p>Diploma de médico </p>
        </div>
        <DocumentDropzone
          onFileUploaded={setCertificate}
          value={certificate}
          uneditable={uneditable}
        />
      </Card>
      <Card>
        <div>
          <p>CRM</p>
        </div>
        <DocumentDropzone
          onFileUploaded={setCrm}
          value={crm}
          uneditable={uneditable}
        />
      </Card>
      <Card>
        <div>
          <p>Certificado de registro de RQE no Conselho Regional de Medicina</p>
        </div>
        <DocumentDropzone
          onFileUploaded={setValidCrm}
          value={validCrm}
          uneditable={uneditable}
        />
      </Card>
      <Card>
        <div>
          <p>Comprovante de endereço do local de atendimento</p>
        </div>
        <DocumentDropzone
          onFileUploaded={setAddress}
          value={address}
          uneditable={uneditable}
        />
      </Card>
      {!uneditable && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            type="submit"
            className={classes.button}
          >
            Atualizar Documentos
          </Button>
        </div>
      )}
    </Card>
  );
}

export default DocInformation;
