import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ButtonShowPass } from './styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function LoginDataForm({ handleNext }) {
  const [showPass, setShowPass] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const userDataRedux = useSelector((state) => state.auth.steepRegister);
  console.log('userDataRedux', userDataRedux);
  const [state, setState] = useState({
    age: '',
    name: 'hai',
  });

  // const [username, setusername] = useState('');
  const [email, setemail] = useState(userDataRedux?.username);
  const [emailValidate, setemailValidate] = useState(false);
  const [confirmEmail, setconfirmEmail] = useState('');
  const [confirmEmailValidate, setconfirmEmailValidate] = useState(false);
  const [password, setpassword] = useState(userDataRedux?.password);
  const [passwordValidate, setpasswordValidate] = useState(false);
  const [confirmPassword, setconfirmPassword] = useState(
    userDataRedux?.password_confirmation
  );
  const [confirmPasswordValidate, setconfirmPasswordValidate] = useState(false);
  const [phone, setphone] = useState(
    userDataRedux?.member_attributes?.personal_phone
  );

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const nextSteep = async () => {
    const userData = {
      user: {
        username: email,
        password: password,
        password_confirmation: confirmPassword,
        member_attributes: {
          personal_phone: phone,
        },
      },
    };
    console.log('---', userData);
    try {
      const response = await api.post(`users/sign_up_doctor`, userData);
      console.log('response', response);
      // dispatch(finishsForm());
      history.push('/');
    } catch (error) {
      console.log('error', error.data);
      console.log('error', error);
     toast.warn(error.response.data[0]);
    }
  };

  const nextForm = () => {
    email == '' ? setemailValidate(true) : setemailValidate(false);
    confirmEmail == ''
      ? setconfirmEmailValidate(true)
      : setconfirmEmailValidate(false);

    if (password == '') {
      setpasswordValidate(true);
    } else {
      if (password.length < 6)
        toast.error('A senha precisa ter 6 ou mais caracteres');
      else setpasswordValidate(false);
    }
    confirmPassword == ''
      ? setconfirmPasswordValidate(true)
      : setconfirmPasswordValidate(false);

    if (email !== confirmEmail) {
      toast.error('Verifique se o E-mail está correto');
    }
    if (password !== confirmPassword)
      toast.error('Verifique se a senha está correta');
    if (
      email != '' &&
      confirmEmail != '' &&
      password != '' &&
      confirmPassword != '' &&
      password === confirmPassword &&
      password.length >= 6 &&
      confirmPassword.length >= 6
    ) {
      nextSteep();
    }
  };

  useEffect(() => {
    console.log('Entrou');
    return () => {
      console.log('Saiu');
    };
  }, []);

  return (
    <React.Fragment>
      <ToastContainer
        // position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Typography variant="h6" gutterBottom>
        Informações para cadastro
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            value={email}
            onChange={(event) => setemail(event.target.value)}
            id="email"
            name="email"
            label="E-mail"
            fullWidth
            autoComplete="shipping email"
            helperText={emailValidate && 'Campo Obrigatório.'}
            error={emailValidate}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            required
            value={confirmEmail}
            onChange={(event) => setconfirmEmail(event.target.value)}
            id="email"
            name="email"
            label="Confirmar E-mail"
            fullWidth
            autoComplete="shipping email"
            helperText={confirmEmailValidate && 'Campo Obrigatório.'}
            error={confirmEmailValidate}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            required
            value={password}
            onChange={(event) => setpassword(event.target.value)}
            id="password"
            name="password"
            label="Senha"
            fullWidth
            type={showPass ? 'text' : 'password'}
            autoComplete="current-password"
            helperText={passwordValidate && 'Campo Obrigatório.'}
            error={passwordValidate}
          />

          <TextField
            required
            value={confirmPassword}
            onChange={(event) => setconfirmPassword(event.target.value)}
            id="confirmPassword"
            name="confirmPassword"
            label="Confirmar Senha"
            fullWidth
            type={showPass ? 'text' : 'password'}
            autoComplete="current-password"
            helperText={confirmPasswordValidate && 'Campo Obrigatório.'}
            error={confirmPasswordValidate}
          />
          <ButtonShowPass onClick={() => setShowPass(!showPass)}>
            {showPass ? <VisibilityOff /> : <Visibility />}
          </ButtonShowPass>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            required
            value={phone}
            onChange={(event) => setphone(event.target.value)}
            id="phone"
            name="phone"
            label="Telefone (WHATSAPP)"
            fullWidth
            // type="number"
            autoComplete="tel"
          />
        </Grid>
      </Grid>

      <Fragment>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={nextForm}
            className={classes.button}
          >
            Cadastrar
          </Button>
        </div>
      </Fragment>
    </React.Fragment>
  );
}
