import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import api from '../../../services/api';
import {
  Container,
  Card,
  Content,
  InfoButton,
  InfoButtonGroup,
} from './styles';

import { Grid } from '@material-ui/core';

import ProfileDropzone from '../../../components/profileDropzone';
import AvatarEditor from '../../../components/avatarEditor';

import TechnicalInformation from './technicalInformation';
import DocInformation from './docInformation';
import AddressInformation from './address';
import EspecialtiesForm from './especialtiesForm';

function Profile() {
  const { id, type_user } = useSelector((store) => store.auth);

  const [user, setUser] = useState(null);
  const [activeButton, setActiveButton] = useState('info');
  const [activeEditor, setActiveEditor] = useState(false);
  const [image, setImage] = useState();

  const handleInfoButton = (text) => {
    setActiveButton(text);
  };

  const getData = async () => {
    try {
      const response = await api.get(`users?id=${id}`);
      setUser(response.data.user);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleImage = async (url) => {
    try {
      //   const data = await encodeImageFileToBase64(file);
      //   console.log('meu arquivo', data);
      const type = type_user === 'clinic' ? 'clinic' : 'doctor';

      const res = await api.patch(`users/${type}?id=${id}`, {
        user: {
          member_attributes: {
            id: user.member.id,
            profile_image_data: url,
          },
        },
      });
      //  console.log(res);

      await getData();
      setActiveEditor(false);
      toast.success('Foto atualizada com sucesso!');
    } catch (error) {
      console.log(error);
    }
  };

  const editImage = (file) => {
    setImage(file);
    setActiveEditor(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Content size="large">
        <Card size="medium">
          {!activeEditor ? (
            <ProfileDropzone
              onFileUploaded={editImage}
              value={user?.member.profile_image}
            />
          ) : (
            <AvatarEditor img={image} onFileUploaded={handleImage} />
          )}
        </Card>

        <Card size="medium" style={{ boxShadow: 'none' }}>
          <InfoButtonGroup container spacing={1}>
            <InfoButton active={activeButton === 'info'} item xg={4}>
              <button onClick={() => handleInfoButton('info')}>
                Informaçoes Tecnicas
              </button>
            </InfoButton>
            <InfoButton active={activeButton === 'doc'} item xg={4}>
              <button onClick={() => handleInfoButton('doc')}>
                Documentos
              </button>
            </InfoButton>
            <InfoButton active={activeButton === 'address'} item xg={4}>
              <button onClick={() => handleInfoButton('address')}>
                Endereço(s)
              </button>
            </InfoButton>

            {type_user !== 'clinic' && (
              <InfoButton active={activeButton === 'especialtie'} item xg={4}>
                <button onClick={() => handleInfoButton('especialtie')}>
                  Especialidades
                </button>
              </InfoButton>
            )}
          </InfoButtonGroup>
        </Card>
        {/**
         *
         *
         *  perfil tecnico
         */}
        {activeButton === 'info' && user != null && (
          <TechnicalInformation user={user} />
        )}
        {activeButton === 'doc' && user != null && (
          <DocInformation user={user} />
        )}
        {activeButton === 'address' && user != null && <AddressInformation />}
        {activeButton === 'especialtie' && user != null && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <EspecialtiesForm user={user} />
            </Grid>
          </Grid>
        )}
      </Content>
    </Container>
  );
}

export default Profile;

// ${doctor?.proof_address_image}
