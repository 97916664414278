import styled from 'styled-components';

import Card from '@material-ui/core/Card';
import { Colors } from '../../../theme/index';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 95%;
  padding: 20px;
  margin-top: 50px;
`;

export const Content = styled.div`
  width: 100%;
`;
export const Title = styled.h2`
  font-weight: normal;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colors.new_primary};

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 20px;

  > div {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }

    > section {
      display: flex;
      flex-direction: row;
    }

    p {
      font-size: 16px;
      font-weight: bold;
      margin: 0px 5px;
    }
  }
`;

export const Image = styled.img`
  height: 65px;
  width: 65px;
  border-radius: 50%;
  background-color: #fff;
  padding: 2px;
  border: 1px solid ${Colors.new_primary};
  margin: 10px;
`;

export const Item = styled.div`
  margin: 6px 0px;
  textarea {
    width: 90%;
    height: 100px;
    border: 1px solid ${Colors.gray03};
    margin-top: 5px;
    border-radius: 5px;
    padding: 10px;
  }
`;

export const Text = styled.p`
  > strong {
    margin-right: 5px;
  }
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  background-color: ${Colors.new_primary};
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  transition: all 0.5s ease;

  :hover {
    opacity: 0.8;
  }
`;

export const CommentContainer = styled.p`
  border: 1px solid ${Colors.gray03};
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
`;
export const List = styled(Card)`
  margin: 10px 0px;
  padding: 10px;
`;
export const TitleComment = styled.p`
  font-size: 12px;
  background-color: #fff;
  position: absolute;
  top: -10px;
  padding: 2px 5px;
  color: ${Colors.gray03};
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  > button {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 5px;

    border: 1px solid;
    border-radius: 5px;
    padding: 5px;

    background-color: transparent;
    margin-left: 10px;
    transition: all 0.5s ease;

    :hover {
      border: 1px solid ${Colors.success};
      color: ${Colors.success};
      > svg {
        color: ${Colors.success};
      }
    }
  }
`;
