import React from 'react';

import { useHistory } from 'react-router-dom';
import ContainerContent from '../../../components/container';
import { Container } from './styles';
import Header from '../../../components/header';

export default function EthicalStandards() {
  const history = useHistory();
  const backToMain = () => history.push('/');
  return (
    <>
      <Header
        goToAboutRef={backToMain}
        goToPricetRef={backToMain}
        goToContactRef={backToMain}
        goToDoctorRate={backToMain}
      />
      <ContainerContent>
        <Container>
          <div>
            <h1>Normas de ética médica</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              lacinia lacus lacus. Pellentesque consectetur dignissim arcu, eget
              tincidunt ex eleifend nec. Proin tempus metus vitae finibus
              interdum. Nulla porta orci vel nibh rutrum dignissim. Maecenas vel
              ultrices ligula. Curabitur nibh tellus, sagittis quis erat et,
              tincidunt cursus purus. Nunc at quam ut eros euismod condimentum.
              Donec eget enim placerat, imperdiet quam sed, facilisis erat.
              Donec sed tellus sapien. Nulla vulputate quam a tortor
              consectetur, non consectetur nulla auctor. Donec fringilla diam
              lectus, vitae malesuada nunc dapibus at. Mauris porttitor urna
              dui.
            </p>
          </div>
        </Container>
      </ContainerContent>
    </>
  );
}
