import styled from 'styled-components';
import Colors from '../../../theme/Colors';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  background-color: ${Colors.primary};
  height: 100%;
  flex: 1;

  h1{
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }

  @media (max-width: 1024px) {
    display: none;
  }

`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
  height: 100%;
  padding: 20px 50px;

  @media (max-width: 1024px) {
    padding: 20px 20px;
    flex: 1;
  }
`;

export const ImgLogo = styled.img`
  width: 150px;
  margin-bottom: 40px;
`;

export const ImgMan = styled.img`
  max-height: 200px;
  margin-top: 40px;
`;

export const ContainerText = styled.div`
  h1{
    font-size: 36px;
    padding-bottom: 10px;
  }
  h2{
    color: ${Colors.secondary};
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: normal;
  }
`;

export const ContainerButtons = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const Button = styled.button`
  height: 120px;
  width: 80%;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: rgb(204 204 204) 0px 1px 10px 0px;
  border-radius: 5px;
  font-size: 32px !important;
  font-weight: bold !important;
  text-align: left !important;
  padding-left: 20px;
  margin-top: 20px;
  background: #fff;
  transition: all 300ms ease 0s;
  &&:hover{
    transform: scale(1.05);
  }
`;