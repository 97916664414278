import styled from 'styled-components';
import { Colors } from '../../../theme';

export const ContainerRecovery = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  a{
    color: ${Colors.primary};
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;


  h1{
    color: ${Colors.gray06};
    font-size: 28px;
    margin: 50px 0px 10px 0px;
  }

  p{
    color: ${Colors.secondary};
    font-size: 16px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  button{
    width: 100%;
    max-width: 265px;
    padding: 10px 20px;
    margin-top: 30px;
    color: #fff;
    font-size: 16px !important;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    background-color: ${Colors.primary};
    align-self: center;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerPaper = styled.div`
  margin-top: 80px;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';

  form{
    width: '100%';
    margin-top: 10px;
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;