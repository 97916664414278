import styled from 'styled-components';
import { Card as MCard, Avatar as MAvatar } from '@material-ui/core';
import { Colors } from '../../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

export const Content = styled(MCard)`
  overflow-y: scroll;
`;

export const Card = styled(MCard)`
  align-self: center;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 10px;
  margin-top: 30px;

  div {
    width: 100%;

    p {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

export const Title = styled.h3``;

export const Avatar = styled(MAvatar)`
  width: 100px;
  height: 100px;
`;

export const Button = styled.button`
  width: 30%;
  padding: 10px 5px;
  margin: 10px;
  background-color: ${Colors.lightblue01};
  color: ${Colors.white};
  border: 1px solid ${Colors.lightblue01};
  border-radius: 5px;

  transition: all 0.3s ease;

  :hover {
    background-color: ${Colors.white};
    color: ${Colors.lightblue01};
    border: 1px solid ${Colors.lightblue01};
  }
`;

export const Separator = styled.div`
  margin: 10px 0px;
  border-top: 1px solid ${Colors.gray02};
  width: 95%;
  padding-top: 10px;
`;

export const ButtonDetail = styled.button`
  height: 40px;
  width: ${(props) => {
    switch (props.size) {
      case 'large':
        return '190px';
      case 'medium':
        return '100px';
      default:
        return '30px';
    }
  }};

  border-radius: 5px;
  margin: 5px 10px;
  background-color: ${(props) =>
    props.color === 'error' ? Colors.error : Colors.success};
  color: #fff;

  transition: all 0.2s;
  :hover {
    opacity: 0.7;
  }
`;
