import React from 'react';
import { toast } from 'react-toastify';

import { Button, ButtonGroup } from './styles';
import api from '../../../services/api';

function cancelPlanPage() {
  const handleCancel = async () => {
    try {
      const response = await api.put(`pagseguro/unsubscribe`);
      console.log(response);
    } catch (error) {
      toast.warn(error.response.data.msg);
      console.log(error);
    }
  };
  return (
    <div>
      <h3>Cancelamento</h3>
      <ButtonGroup>
        <div>
          <Button type="button" onClick={handleCancel} color="error">
            Cancelar
          </Button>
        </div>
      </ButtonGroup>
    </div>
  );
}

export default cancelPlanPage;
