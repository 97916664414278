import { produce } from 'immer';

const INITIAL_STATE = {
  clinics: [],
  publicClinics:[],
  pending_payment: [],
  pending: [],
  cancelled: [],
  loading: true,
};

export default function clinic(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@clinic/GET_ALL_CLINICS_LIST_SUCCESS':
        draft.clinics = action?.payload?.clinics;
        draft.pending_payment = action?.payload?.pending_payment;
        draft.pending = action?.payload?.pending;
        draft.cancelled = action?.payload?.cancelled;
        draft.publicClinics= action?.payload?.publicClinics
        draft.loading = false;
        break;

      default:
        break;
    }
  });
}
