import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Avatar } from '@material-ui/core';

import EditAttributesOutlinedIcon from '@material-ui/icons/CloudUpload';

import { Dropzone } from './styles';

const DocumentDropzone = ({ onFileUploaded, value, uneditable }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      // console.log(acceptedFiles);
      const file = acceptedFiles[0];

      const fileUrl = URL.createObjectURL(file);
      setSelectedFileUrl(fileUrl);
      onFileUploaded(file);
    },
    [onFileUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });
  return (
    <Dropzone {...getRootProps()}>
      <input {...getInputProps()} accept="image/*" />

      {!selectedFileUrl && !uneditable ? (
        <p>
          <EditAttributesOutlinedIcon />
          Clique aqui para adicionar imagem, ou arraste o arquivo para este
          local...
        </p>
      ) : (
        <img src={selectedFileUrl ? selectedFileUrl : value} alt="" />
      )}
    </Dropzone>
  );
};

export default DocumentDropzone;
