import React from 'react'
import Card from '../../../../components/card';
import { BodyCard, Container, ContainerCard, HeaderCard, SubTitleCard, TitleCard } from '../styles';

export default function GeneralInformation({user}) {
  return (
    <>
      {user?.member?.professional_experience && 
          <ContainerCard>
            <Card>
              <HeaderCard>
                {user?.role && user.role != "clinic" ? 
                <TitleCard>Sobre o Médico</TitleCard>
                :
                <TitleCard>Sobre a Clínica</TitleCard>
                }
              </HeaderCard>

              <BodyCard>
                <SubTitleCard>
                  {user?.member?.professional_experience}
                </SubTitleCard>
              </BodyCard>
            </Card>
          </ContainerCard>
        }

        {user?.specialties && 
          <ContainerCard>
            <Card>
              <HeaderCard>
                <TitleCard>Especialidades</TitleCard>
              </HeaderCard>
              <BodyCard>
                {user?.specialties.map((espec, index) => 
                  <SubTitleCard key={index}>
                    {espec?.medical_specialty?.name} - RQE {espec?.rqe}
                  </SubTitleCard>
                )}
              </BodyCard>
            </Card>
          </ContainerCard>
        }

        {/* <ContainerCard>
          <Card>
            <HeaderCard>
              <TitleCard>Área de Atuação</TitleCard>
            </HeaderCard>

            <BodyCard>
              <SubTitleCard>
                Ecocardiografia - RQE 0000
              </SubTitleCard>
            </BodyCard>
          </Card>
        </ContainerCard> */}

        {/* <ContainerCard>
          <Card>
            <HeaderCard>
              <TitleCard>Formação Acadêmica</TitleCard>
            </HeaderCard>

            <BodyCard>
              <SubTitleCard>
                UNIVESIDADE GUIA DOCTOR
              </SubTitleCard>
              <SubTitleCard>
                Medicina
              </SubTitleCard>
              <SubTitleCard>
                Graduado em 2010
              </SubTitleCard>
            </BodyCard>
          </Card>
        </ContainerCard> */}
    </>
  )
}
