import styled from 'styled-components';
import { Colors } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  right: 3%;
  bottom: 5%;
  

  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color:${Colors.primary} ;

transition: all 0.5s ease;
  :hover{
      opacity: 0.7;
  }
`;
export const Button = styled.button`
    border:none;
    background: transparent;
   
`;

export const Icon = styled.img`
    width: 40px;
    height: 40px;
    background-color:transparent;
`;