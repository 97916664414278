import styled from 'styled-components';
import { Card as MCard } from '@material-ui/core';
import { Colors } from '../../../theme';

export const Card = styled(MCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  padding: 10px;
  margin-top: 10px;
  width: 90%;
`;
export const StepperContainer = styled(MCard)`
  margin-top: 20px;
  width: 70%;
`;
export const Title = styled.h3``;

export const CardStyleButton = styled(MCard)`
  padding: 0px;
  display: flex;
  flex: 1;
  margin: 10px;
  max-width: 48%;
  button {
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    span {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }
  h5 {
    text-align: left;
  }
  h4 {
    font-weight: 400;
    text-align: left;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerCardForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
  width: 70%;
`;

export const CreditCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 30px;

  > form {
    margin: 10px;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

export const Input = styled.input`
  border-radius: 5px;
  border: 1px solid gray;
  padding: 10px;
  width: 100%;
  margin: 5px 0px;

  :focus {
    border-color: ${Colors.new_primary};
  }

  :read-only {
    background-color: #eeee;
    border: none;
    margin-left: 10px;
  }
`;

export const InputDateDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
